import {
  companySizes,
  currencies,
  eligibilityOptions,
  jobSeekingStatusConstant,
  noticeOptions,
  yearOptions
} from '../constants';

export const isAdministrator = authenticatedUser => {
  if (!authenticatedUser || authenticatedUser.signInUserSession) {
    return false;
  }
  const { accessToken } = authenticatedUser.signInUserSession;
  const groups = accessToken.payload['cognito:groups'];
  return !!groups.find((grpName) => grpName === 'administrators');
}

export const deleteUnderscoreFields = model => {
  const temp = { ...model };
  delete temp._version;
  delete temp._deleted;
  delete temp._lastChangedAt;
  return temp;
}

export const bootstrapUser = ( email, username ) => {
  return {
    active: false,
    reviewed: false,
    owner: username,
    name: '',
    email: email,
    phone: '',
    picture: '',
  };
}

export const bootstrapEmployer = () => {
  return {
    companyName: '',
    companySize: companySizes[0].value,
    countries: [],
    workCategories: [],
    workType: [],
  }
}

export const bootstrapCandidate = () => {
  return {
    candidateTypes: [],
    yearsExperience: yearOptions[0].value,
    workCategories: [],
    availability: noticeOptions[0].value,
    salary: 0,
    salaryCurrency: currencies[0].abr,
    desiredSalary: 0,
    desiredCurrency: currencies[0].abr,
    eligibility: [],
    workType: [],
    jobSeekingStatus: jobSeekingStatusConstant[0].value,
    programmingLanguages: [],
    countries: ['Australia'],
    cities: [],
  }
}

export const getYearText = (year) => {
  const option = yearOptions.find(({ value }) => value === year);
  return option?.text || '';
}

export const getNoticeText = (val) => {
  const option = noticeOptions.find(({ value }) => value === val);
  return option?.text || '';
}

export const eligibilityString = (val) => {
  eligibilityOptions.find(({ value }) => value === val);
}

