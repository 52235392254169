import React, { useContext, useEffect, useRef, useState } from 'react';

import {
  Button,
  FormGroup,
  Form,
  InputGroupText,
  InputGroup,
  Container,
} from "reactstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { useHistory, useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';

import bgImg from '../assets/img/ill/pattern_pricing1.svg';
import googleImg from '../assets/img/icons/common/google.svg'
import AppContext from '../contexts/AppContext';
import { getLoginErrorAlert, getVerifiedAlert } from '../utils/AlertUtils';
import {
  Box,
  Checkbox, CircularProgress,
  Link,
  TextField,
  Typography
} from '@mui/material';
import { useFormik } from 'formik';
import clsx from 'clsx';
import { signInSchema, signUpSchema, verifySchema } from '../utils/SchemaUtils';

export const LoginPage = () => {
  const history = useHistory();
  const location = useLocation();
  const {
      saving: [, setIsSaving],
      loading: [, setIsLoading],
      loadingMessage: [, setLoadingMessage],
      oauthErr: [OAuthError]
  } = useContext(AppContext);
  const [activeContainer, setActiveContainer] = useState('');
  const [signupEmailFocus, setSignupEmailFocus] = useState('');
  const [signupPasswordFocus, setSignupPasswordFocus] = useState('');
  const [signinEmailFocus, setSigninEmailFocus] = useState('');
  const [signinPasswordFocus, setSigninPasswordFocus] = useState('');
  const [verifyCodeFocus, setVerifyCodeFocus] = useState('');
  const [emailFocus, setEmailFocus] = useState('');
  const [loggingIn, setLoggingIn] = useState(false);
  const [creatingAccount, setCreatingAccount] = useState(false);
  const [accCreated, setAccountCreated] = useState(false);
  const [verifyingAcc, setVerifyingAcc] = useState(false);
  const [verified, setVerified] = useState(false);
  const recaptchaRef = useRef();

  const [errLoggingIn, setErrLoggingIn] = useState(undefined);
  const [errSigningUp, setErrSigningUp] = useState(undefined);
  const [errVerifying, setErrVerifying] = useState(undefined);

  const formikSignIn = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: signInSchema,
    onSubmit: async (values) => {
      const { email, password } = values;
      setLoggingIn(true);
      setIsSaving(true);
      setErrLoggingIn();
      try {
        await Auth.signIn({
          username: email.toLowerCase(),
          password,
        }).then(() => history.push('/'));
      } catch (err) {
        setErrLoggingIn(err);
      } finally {
        setLoggingIn(false);
        setIsSaving(false);
      }
    },
  })
  const formikSignup = useFormik({
    initialValues: {
      email: '',
      password: '',
      termsAccepted: false,
    },
    validationSchema: signUpSchema,
    onSubmit: async (values) => {
      if (!creatingAccount) {
        setCreatingAccount(true);
      }
      const { email, password } = values;
      try {
        if (!recaptchaRef.current.getValue()) {
          await recaptchaRef.current.executeAsync();
        }
        await Auth.signUp({
          username: email.toLowerCase(),
          password,
          attributes: {
            email,
          },
        });
        setAccountCreated(true);
      } catch (err) {
        setErrSigningUp(err);
      } finally {
        setCreatingAccount(false);
      }
    },
  });

  const formikVerify = useFormik({
    initialValues: {
      email: formikSignup.values.email || '',
      verificationCode: '',
    },
    validationSchema: verifySchema,
    onSubmit: async (values) => {
      const { email, verificationCode } = values;
      setVerifyingAcc(true);
      try {
        await Auth.confirmSignUp(email.toLowerCase(), verificationCode);
        setVerified(true);
        const signupValues = formikSignup.values;
        if (signupValues.email && signupValues.password !== '') {
          await Auth.signIn({
            username: signupValues.email.toLowerCase(),
            password: signupValues.password
          }).then(() => history.push('/'))
        } else {
          setVerifyingAcc(false);
        }
      } catch (err) {
        setErrVerifying(err);
        setVerified(false);
      } finally {
        setVerifyingAcc(false);
      }
    },
  });

  useEffect(() => {
    recaptchaRef.current.onErrored = (e) => console.log('Failed to recaptcha', e);
    document.body.classList.add('register-page');
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    if (location.search.indexOf('create') !== -1) {
      setTimeout(() => {
        setActiveContainer('right-panel-active');
      }, 250);
    }
    return function cleanup() {
      document.body.classList.remove('register-page');
    };
  }, []);

  const rdrToForgotPW = () => {
    history.push('/reset');
  }

  const signIn = () => {
    setErrLoggingIn();
    formikSignIn.handleSubmit();
  }
  const signUp = () => {
    setErrSigningUp();
    formikSignup.handleSubmit()
  }
  const verifyAccount = () => {
    setErrVerifying();
    formikVerify.handleSubmit();
  }

  const toggleCreated = () => setAccountCreated(!accCreated);

  const googleSSO = async () => {
    setIsSaving(true);
    setErrLoggingIn();
    setIsLoading(true);
    setLoadingMessage('Logging in via Google');
    try {
      await Auth.federatedSignIn({ provider: 'Google' });
    } catch (err) {
      setErrLoggingIn(err);
    } finally {
      setIsSaving(false);
    }
  }

  return (
      <>
        <div className="wrapper">
          <div className="page-header bg-default">
            <ReCAPTCHA
                sitekey="6Ld9iOEcAAAAAGZEJhzqmIMoFzVAJYzFy3O9GP-i"
                size="invisible"
                ref={recaptchaRef}
            />
            <div
                className="page-header-image"
                style={{
                  backgroundImage:
                      `url("${bgImg}")`,
                }}
            ></div>
            <Container className={activeContainer}>
              <div className="form-container sign-up-container">
                {accCreated ?
                    <Form role="form">
                      <div
                        onClick={toggleCreated}
                        className="clickable"
                        style={{
                          position: 'absolute',
                          top: '1rem',
                          left: '1rem',
                        }}>
                        <i className="fa fa-angle-left"></i>
                      </div>
                      <h2>Verify Account</h2>
                      <span className="text-default mb-4">
                        Check your email for your verification code
                      </span>
                      <FormGroup className={"mb-3 " + emailFocus}>
                        <InputGroup className="input-group-alternative">
                          <TextField
                              variant="standard"
                              placeholder="Email"
                              type="email"
                              name="email"
                              id="verify-email"
                              fullWidth
                              value={formikVerify.values.email}
                              onChange={formikVerify.handleChange}
                              error={formikVerify.touched.email && Boolean(formikVerify.errors.email)}
                              helperText={formikVerify.touched.email && formikVerify.errors.email}
                              onFocus={() => setEmailFocus("focused")}
                              onBlur={() => setEmailFocus("")}
                              InputProps={{
                                startAdornment:
                                    <InputGroupText>
                                      <i className={clsx("ni ni-email-83", Boolean(formikVerify.touched.email && formikVerify.errors.email) && "text-red")}></i>
                                    </InputGroupText>,
                              }}
                          ></TextField>
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className={"mb-3 " + verifyCodeFocus}>
                        <InputGroup className="input-group-alternative">
                          <TextField
                              variant="standard"
                              placeholder="Verification code"
                              type="text"
                              name="verificationCode"
                              id="verify-authcode"
                              fullWidth
                              value={formikVerify.values.verificationCode}
                              onChange={formikVerify.handleChange}
                              error={formikVerify.touched.verificationCode && Boolean(formikVerify.errors.verificationCode)}
                              helperText={formikVerify.touched.verificationCode && formikVerify.errors.verificationCode}
                              onFocus={() => setVerifyCodeFocus("focused")}
                              onBlur={() => setVerifyCodeFocus("")}
                              InputProps={{
                                startAdornment:
                                  <InputGroupText>
                                    <i className={clsx("ni ni-key-25", Boolean(formikVerify.touched.verificationCode && formikVerify.errors.verificationCode) && "text-red")}></i>
                                  </InputGroupText>,
                              }}
                          ></TextField>
                        </InputGroup>
                      </FormGroup>
                      {errVerifying && getLoginErrorAlert(errVerifying)}
                      {verified && getVerifiedAlert('Successfully verified')}

                      <div className="text-center">
                        {verifyingAcc ?
                            <CircularProgress className="mt-3" />
                            :
                            <>
                              <Link component="button"
                                    onClick={() => history.push('/reset')}>
                                {'Stuck? Reset your password'}
                              </Link>
                              <Button className="my-4"
                                      onClick={() => verifyAccount()}
                                      color="primary" type="button">
                                Verify
                              </Button>
                            </>
                        }
                      </div>
                    </Form>
                    :
                    <Box component="form">
                      <h2>Create Account</h2>
                      <FormGroup className={"mb-3 " + signupEmailFocus}>
                        <InputGroup className="input-group-alternative">
                          <TextField
                              variant="standard"
                              placeholder="Email"
                              type="email"
                              name="email"
                              id="signup-email"
                              fullWidth
                              value={formikSignup.values.email}
                              onChange={formikSignup.handleChange}
                              error={formikSignup.touched.email && Boolean(formikSignup.errors.email)}
                              helperText={formikSignup.touched.email && formikSignup.errors.email}
                              onFocus={() => setSignupEmailFocus("focused")}
                              onBlur={() => setSignupEmailFocus("")}
                              InputProps={{
                                startAdornment:
                                <InputGroupText>
                                  <i className={clsx("ni ni-email-83", Boolean(formikSignup.touched.email && formikSignup.errors.email) && "text-red")}></i>
                                </InputGroupText>,
                              }}
                          ></TextField>
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className={signupPasswordFocus}>
                        <InputGroup className="input-group-alternative">
                          <TextField
                              variant="standard"
                              placeholder="Password"
                              type="password"
                              name="password"
                              id="signup-password"
                              fullWidth
                              value={formikSignup.values.password}
                              onChange={formikSignup.handleChange}
                              error={formikSignup.touched.password && Boolean(formikSignup.errors.password)}
                              helperText={formikSignup.touched.password && formikSignup.errors.password}
                              onFocus={() => setSignupPasswordFocus("focused")}
                              onBlur={() => setSignupPasswordFocus("")}
                              InputProps={{
                                startAdornment:
                                  <InputGroupText>
                                    <i className={clsx("ni ni-lock-circle-open", Boolean(formikSignup.touched.password && formikSignup.errors.password) && "text-red")}></i>
                                  </InputGroupText>,
                              }}
                          ></TextField>
                        </InputGroup>
                        {errSigningUp &&
                            <div className="pt-2">
                              {getLoginErrorAlert(errSigningUp)}
                            </div>
                        }
                      </FormGroup>
                      {creatingAccount ?
                          <CircularProgress className="mt-3" />
                          :
                          <div className="d-flex flex-column w-100">
                            <div className="d-flex flex-row justify-content-center">
                              <Checkbox
                                  className={'pl-0'}
                                  color={'primary'}
                                  name="termsAccepted"
                                  value={formikSignup.values.termsAccepted || false}
                                  checked={formikSignup.values.termsAccepted || false}
                                  onChange={formikSignup.handleChange}
                              />
                              <Link className={Boolean(formikSignup.touched.termsAccepted && formikSignup.errors.termsAccepted) ? 'text-red text-underline-red' : 'text-underline'} component="button"
                                  onClick={() => history.push('/terms')}>
                                {'Terms & Conditions'}
                              </Link>
                            </div>
                            {Boolean(formikSignup.touched.termsAccepted && formikSignup.errors.termsAccepted) &&
                            <div className="mt--2 text-red">
                              <Typography variant="caption" component="p">
                                Accepting the terms is required
                              </Typography>
                            </div>}
                            <Button
                                onClick={() => signUp()}
                                className="mt-1"
                                color="primary">Sign Up</Button>
                            <div className="pt-4 d-flex flex-column">
                              Got a verification code?
                              <Button
                                  onClick={toggleCreated}
                                  className="mt-1"
                                  color="secondary">Verify</Button>
                            </div>
                          </div>
                      }
                    </Box>
                }
              </div>
              <div className="form-container sign-in-container">
                <Form action="#" role="form">
                  <h2>Sign in</h2>
                  <div className="social-container">
                    <Button
                        disabled={loggingIn}
                        className="btn-neutral btn-icon"
                        color="default"
                        onClick={googleSSO}
                    >
                      <span className="btn-inner--icon">
                        <img
                            alt="..."
                            src={googleImg}
                        ></img>
                      </span>
                      <span className="btn-inner--text">Google</span>
                    </Button>
                  </div>
                  {(OAuthError && OAuthError.indexOf('sign up') >= 0) &&
                    <span className="text-red mb-4">
                      Please <span
                          onClick={() => setActiveContainer("right-panel-active")}
                          className="clickable text-underline"> sign up</span> before using Google to sign in
                    </span>
                  }
                  {(OAuthError && OAuthError.indexOf('found an entry') >= 0)
                      && <span className="text-success mb-4">Account linked, you can now log-in via Google</span>
                  }
                  {!OAuthError && <span className="text-default mb-4">or use your account</span>}
                  <FormGroup className={"mb-3 " + signinEmailFocus}>
                    <InputGroup className="input-group-alternative">
                      <TextField
                          variant="standard"
                          placeholder="Email"
                          type="email"
                          name="email"
                          id="signin-email"
                          fullWidth
                          value={formikSignIn.values.email}
                          onChange={formikSignIn.handleChange}
                          error={formikSignIn.touched.email && Boolean(formikSignIn.errors.email)}
                          helperText={formikSignIn.touched.email && formikSignIn.errors.email}
                          onFocus={() => setSigninEmailFocus('focused')}
                          onBlur={() => setSigninEmailFocus('')}
                          InputProps={{
                            startAdornment:
                             <InputGroupText>
                               <i className={clsx("ni ni-email-83", Boolean(formikSignIn.touched.email && formikSignIn.errors.email) && "text-red")}></i>
                             </InputGroupText>
                          }}
                      ></TextField>
                    </InputGroup>
                  </FormGroup>
                  <FormGroup className={signinPasswordFocus}>
                    <InputGroup className="input-group-alternative">
                      <TextField
                          variant="standard"
                          placeholder="Password"
                          type="password"
                          name="password"
                          id="signin-password"
                          fullWidth
                          value={formikSignIn.values.password}
                          onChange={formikSignIn.handleChange}
                          error={formikSignIn.touched.password && Boolean(formikSignIn.errors.password)}
                          helperText={formikSignIn.touched.password && formikSignIn.errors.password}
                          onFocus={() => setSigninPasswordFocus('focused')}
                          onBlur={() => setSigninPasswordFocus('')}
                          InputProps={{
                            startAdornment:
                                <InputGroupText>
                                  <i className={clsx("ni ni-lock-circle-open", Boolean(formikSignIn.touched.password && formikSignIn.errors.password) && "text-red")}></i>
                                </InputGroupText>,
                          }}
                      ></TextField>
                    </InputGroup>
                  </FormGroup>
                  <Button color="link" onClick={(e) =>
                      rdrToForgotPW()}>
                    Forgot your password?
                  </Button>
                  <div className="mt-2">
                    {errLoggingIn && getLoginErrorAlert(errLoggingIn)}
                  </div>
                  {loggingIn ?
                      <CircularProgress className="mt-3" />
                      :
                      <Button
                          onClick={signIn}
                          className="my-1" color="primary">
                        Sign In
                      </Button>
                  }
                </Form>
              </div>
              <div className="overlay-container">
                <div className="overlay">
                  <div className="overlay-panel overlay-left">
                    <h1 className="text-white">Already got an account?</h1>
                    <p>
                      Then let's head over to the sign in page
                    </p>
                    <Button
                        className="btn-neutral"
                        color="default"
                        id="signIn"
                        size="sm"
                        onClick={() => {
                          setActiveContainer("");
                        }}
                    >
                      Sign In
                    </Button>
                  </div>
                  <div className="overlay-panel overlay-right">
                    <h1 className="text-white">Welcome</h1>
                    <p>Haven't got an account yet? No problem</p>
                    <Button
                        className="btn-neutral"
                        color="default"
                        id="signUp"
                        size="sm"
                        onClick={() => {
                          setActiveContainer("right-panel-active");
                        }}
                    >
                      Sign Up
                    </Button>
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </div>
      </>
  );
}
