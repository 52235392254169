import React, { useEffect, useState } from "react";
import { makeStyles } from '@mui/styles';
import { Box, Tab, Tabs } from '@mui/material';
import { TabPanel } from '../components/TabPanel';
import { UsersAdmin } from '../containers/admin/UsersAdmin';
import { MessagesAdmin } from '../containers/admin/MessagesAdmin';
import { Analytics, Auth } from 'aws-amplify';

const styles = makeStyles({
  outer: {
    maxHeight: '80vh',
    minWidth: '95vw',
    position: 'relative',
  },
  main: {
    display: 'grid',
    marginTop: '10px',
  },
});

export const AdminPage = () => {
  const classes = styles();
  const [tabIndex, setTabIndex] = useState(0);

  const tabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  useEffect(() => {
    const visitAdminPage = async () => {
      const { username } = await Auth.currentUserInfo();
      await Analytics.record({
        name: 'adminPageVisit',
        attributes: {
          username
        }
      });
    }
    visitAdminPage().finally(() => {});
  }, []);

  return (
      <div className={classes.outer}>
        <div className={classes.main}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tabIndex} onChange={tabChange} aria-label="basic tabs example">
              <Tab label="Users" />
              <Tab label="Messages" />
            </Tabs>
          </Box>
          <TabPanel tabIndex={tabIndex} index={0}>
            <UsersAdmin />
          </TabPanel>
          <TabPanel tabIndex={tabIndex} index={1}>
            <MessagesAdmin />
          </TabPanel>
        </div>
      </div>
  )
}
