import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  TextField,
} from '@mui/material';
import { Col, Row } from 'reactstrap';
import { CloseOutlined } from '@mui/icons-material';
import { useFormik } from 'formik';
import { resetPasswordSchema } from '../../utils/SchemaUtils';

export const AmplifyPasswordReset = () => {
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [user, setUser] = useState();
  const [passwordReset, setPasswordReset] = useState(false);
  const [resetting, setResetting] = useState(false);
  const [errResetting, setErrResetting] = useState();
  const [canReset, setCanReset] = useState();

  const formikResetPassword = useFormik({
    initialValues: {
      oldPassword: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema: resetPasswordSchema,
    onSubmit: async (values) => {
      const { oldPassword, password } = values;
      setResetting(true);
      Auth.changePassword(user, oldPassword, password)
        .then(() => {
          setErrResetting();
          setPasswordReset(true);
          setTimeout(() => setPasswordReset(false), 2500);
        })
        .catch((e) => setErrResetting(`Failed to reset ${'- ' + e?.message}`))
        .finally(() => {
          setResetting(false);
        });
    },
  })

  useEffect(() => {
    Auth.currentAuthenticatedUser().then((u) => {
      setUser(u)
      setCanReset(!u.attributes.identities);
    });
  }, [])

  const resetPassword = async () => {
    formikResetPassword.handleSubmit();
  }

  if (!canReset) {
    return (
          <Box style={{ zIndex: 5 }}>
            <Button disabled>
              <i className={"ni ni-lock-circle-open pr-2"}></i>
              Reset password
            </Button>
            <Chip color="error"
              label="You've signed in via another service and can't reset your password">
            </Chip>
          </Box>
    );
  }

  return (
      <>
        {!showResetPassword ?
            <Button
                onClick={() => setShowResetPassword(!showResetPassword)}
                variant="outlined">
              <i className={"ni ni-lock-circle-open pr-2"}></i>
              Reset Password
            </Button>
            :
            <>
              <TextField
                  className="w-100 mt-1"
                  type="password"
                  name="oldPassword"
                  onChange={formikResetPassword.handleChange}
                  value={formikResetPassword.values.oldPassword}
                  error={formikResetPassword.touched.oldPassword && Boolean(formikResetPassword.errors.oldPassword)}
                  helperText={formikResetPassword.touched.oldPassword && formikResetPassword.errors.oldPassword}
                  id="old-password" label="Old password" variant="standard"/>
              <TextField
                  className="w-100 mt-1"
                  type="password"
                  name="password"
                  onChange={formikResetPassword.handleChange}
                  value={formikResetPassword.values.password}
                  error={formikResetPassword.touched.password && Boolean(formikResetPassword.errors.password)}
                  helperText={formikResetPassword.touched.password && formikResetPassword.errors.password}
                  id="new-password" label="New password" variant="standard"/>
              <TextField
                  type="password"
                  name="confirmPassword"
                  onChange={formikResetPassword.handleChange}
                  value={formikResetPassword.values.confirmPassword}
                  error={formikResetPassword.touched.confirmPassword && Boolean(formikResetPassword.errors.confirmPassword)}
                  helperText={formikResetPassword.touched.confirmPassword && formikResetPassword.errors.confirmPassword}
                  className="w-100 mt-1"
                  id="confirm-password" label="Confirm password"
                  variant="standard"/>
              <Row>
                <Col xs={12}>
                  <div className="w-100 mt-2">
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={resetPassword}>
                      {resetting ?
                          <>
                            <CircularProgress size={20} color="inherit"/>
                            <span className="pl-2">Saving password</span>
                          </>
                          :
                          passwordReset ?
                              <div className='h-100 ml-2'>Password reset
                                ✔️</div>
                              : 'Save Password'
                      }
                    </Button>
                    {errResetting &&
                    <div>
                      <Chip
                          deleteIcon={<CloseOutlined/>}
                          onDelete={() => setErrResetting()}
                          className="mt-2"
                          label={errResetting} color="warning"/>
                    </div>
                    }

                  </div>
                </Col>
              </Row>
            </>
        }
      </>
  )
}
