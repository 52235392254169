import { Col, Row } from 'reactstrap';
import { getCompanySizeText } from '../../constants';
import MDEditor from '@uiw/react-md-editor';
import React from 'react';

export const EmployerPreview = ({ user }) => {
  return (
      <div>
        <Row>
          <Col xs={12} md={12}>
            <h4>
              {user.name}
            </h4>
            <h5>
              Company Details
            </h5>
            <b>
              Name
            </b>
            <h6>
              {user.employer.companyName}
            </h6>
            <div className="pt-2">
              <b>
                Number of employees
              </b>
            </div>
            <div>
              {getCompanySizeText(user.employer.companySize)}
            </div>
            {user.employer.description &&
              <>
                <div className="pt-2">
                  <b>Company Description</b>
                </div>
                <div>
                  <MDEditor.Markdown source={user.employer.description} />
                </div>
              </>
            }
            {user.description &&
              <>
                <div className="pt-2">
                  <b>User Description</b>
                </div>
                <div>
                  <MDEditor.Markdown source={user.description} />
                </div>
              </>
            }
          </Col>
        </Row>
      </div>
  )
}
