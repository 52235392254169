import React, { useContext, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Avatar, Button, Chip, Container } from '@mui/material';
import { Card, Col, Row } from 'reactstrap';
import formatter from 'currency-formatter';
import MDEditor from '@uiw/react-md-editor';
import { useHistory } from 'react-router-dom';
import { Analytics, API, DataStore } from 'aws-amplify';

import AppContext from '../../contexts/AppContext';
import { getNoticeText, getYearText } from '../../utils/UserUtils';
import bgImg from '../../assets/img/ill/bg_contactus3.svg';
import RedirectButton from '../../components/buttons/RedirectButton';
import { getCurrency } from '../../constants';
import { AccountType, Conversation } from '../../models';
import { listConversationsNoMsg } from '../../api/customQueries';
import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries';

const styles = makeStyles({
  container: {
    minHeight: '85vh',
    minWidth: '95vw',
    position: 'relative',
    '&::after': {
      content: '""',
      maxHeight: '100vh',
      maxWidth: '100vw',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      position: 'absolute',
      backgroundImage: `url(${bgImg})`,
      backgroundSize: 'cover',
      backgroundPosition: 'top center',
      opacity: 0.4,
      zIndex: -1,
    },
  },
  main: {
    display: 'grid',
  },
  radiusSm: {
    borderRadius: '10px',
  },
});

export const CandidateView = () => {
  const classes = styles();
  const history = useHistory();
  const {
    auth: [authenticated],
    user: [usr, setUsr],
    previewUsr: [previewUser, setPreviewUser],
    msgContext: [,setMsgContext],
  } = useContext(AppContext);
  const [goingToMessage, setGoingToMessage] = useState(false);
  const [enabling, setEnabling] = useState(false);
  const usrVal = usr.accountType === AccountType.EMPLOYER ? previewUser : usr;
  const { candidate } = usrVal;
  const obv = candidate;
  const isMe = authenticated?.email === usrVal?.email;

  const currencyStr = formatter.format(obv.desiredSalary,
      { code: getCurrency(obv.desiredCurrency).abr });

  const enableAccount = async () => {
    setEnabling(true);
    await API.graphql({
      query: mutations.updateUser,
      variables: {
        input: {
          id: usr.id,
          _version: usr._version,
          active: true,
        },
      }
    });
    const { data: { getUser }} = await API.graphql(
        { query: queries.getUser, variables: { id: usr.id }}
    );
    setUsr(getUser);
    setEnabling(false);
  }

  const messageUser = async () => {
    setMsgContext(usrVal.id);
    if (!goingToMessage) {
      setGoingToMessage(true);
    }
    const { data: { listConversations: { items }}} = await API.graphql({
      query: listConversationsNoMsg,
      variables: {
        filter: {
          or: [
            { and: [
                { user1: { 'eq': usrVal.id }},
                { user2: { 'eq': usr.id }},
              ]
            },
            { and: [
                { user1: { 'eq': usr.id }},
                { user2: { 'eq': usrVal.id }},
              ]
            },
          ]
        }
      }
    });
    if (!items.length) {
      await DataStore.save(new Conversation({
        user1: usrVal.id,
        user2: usr.id,
        participants: [usr.owner, usrVal.owner],
      }));
    }
    Analytics.record({
      name: 'messageUser',
      attributes: {
        sender: usr.owner,
        recipient: previewUser.owner,
      }
    });
    history.push(`/chat`);
  }

  return (
        <div className={classes.container}>
          <div className={classes.main}>
            <Container>
              <Card className="shadow p-4 mt-lg-3 position-relative">
                {usr.accountType === 'EMPLOYER' &&
                    <div className="pb-3">
                      <Button
                          onClick={() => {
                            setPreviewUser();
                            history.push('/employer');
                          }}
                          variant="contained" color="primary" >
                        <i className="fa fa-chevron-left pr-2"></i>
                        Back to Search
                      </Button>
                    </div>
                }
                {!usrVal.active && <div className="text-center">
                  <h4 className="text-red">This account is inactive</h4>
                  {!usrVal.reviewed && <b>Pending admin review</b>}
                </div>
                }
                <Row>
                  <Col xs={9}>
                    <div className="d-flex flex-row">
                      <Avatar className="mr-2" aria-label="user-picture"
                        src={usrVal.picture}>
                      </Avatar>
                      <h2>
                        {usrVal.name}
                      </h2>
                    </div>
                    <h6>{isMe && '(previewing your own profile)'}</h6>
                    <h6>
                      {getYearText(obv.yearsExperience)} experience
                    </h6>
                    <h6>
                      Wants <b>{currencyStr}</b> (annual total package)
                    </h6>
                  </Col>
                  {isMe &&
                    <Col xs={12} md={3}>
                      <RedirectButton
                          className="w-100"
                          buttonText="Edit"
                          color="primary"
                          variant="contained"
                          disabled={enabling}
                          redirectTo="/you/edit"
                          />
                      {usr.active ?
                        <Button
                            color={"error"}
                            className="w-100 mt-1"
                            variant="contained"
                            onClick={() => history.push('/disable')}
                        >
                          Disable account
                        </Button>
                        :
                        <Button
                            disabled={enabling}
                            onClick={enableAccount}
                            className="w-100 mt-1"
                            variant="contained"
                            color="info">
                          Enable account
                        </Button>
                      }
                    </Col>
                  }
                </Row>
                <Row className="mt-4">
                  {usrVal.description &&
                    <Col xs={12} className="mb-3">
                      <div>
                        <MDEditor.Markdown source={usrVal.description} />
                      </div>
                    </Col>
                  }
                  <Col xs={12}>
                    <h3>
                      Details
                    </h3>
                    <div className="py-2">
                      <b>
                        Notice period
                      </b>
                      <div>
                        {getNoticeText(obv.availability)}
                      </div>
                    </div>
                    <div className="py-2">
                      {obv.countries.length &&
                      <>
                        <b>
                          Countries
                        </b>
                        {obv.countries.map((country) => {
                          return <div key={country}>
                            {country}
                          </div>
                        })
                        }
                      </>
                      }
                    </div>
                    {obv?.cities?.length > 0 &&
                    <div className="py-2">
                      <div className="font-weight-bold">
                        Cities
                      </div>
                      <Row>
                        <Col xs={12} className="pt-1">
                          {obv?.cities?.sort().map((city, i) => (
                              <Chip key={`${city}-${i}`} className="mr-1"
                                    label={city}/>
                          ))
                          }
                        </Col>
                      </Row>
                    </div>
                    }
                    <div className="py-2">
                      <b>Employment Type(s)</b>
                      {obv.workType.map((type) => {
                        return <div key={type}>
                          {type}
                        </div>
                      })
                      }
                    </div>
                    <div className="py-2">
                    {obv.openToRemote ?
                        <Chip className="mr-1 mb-1"
                              color="success"
                              label={`Open to remote roles`}/>
                        :
                        <Chip className="mr-1 mb-1"
                              style={{backgroundColor: '#d32f2f', color: 'white' }}
                              label={`No remote roles`}/>
                    }
                    </div>
                    <div className="py-2">
                      <div className="font-weight-bold">
                        Roles
                      </div>
                      <Row>
                        <Col xs={12}>
                          {obv?.workCategories?.sort().map((category, i) => (
                              <Chip key={`${category}-${i}`} className="mr-1" color="primary" label={category} />
                          ))
                          }
                        </Col>
                      </Row>
                    </div>
                    {obv?.programmingLanguages.length > 0 &&
                      <div className="py-2">
                        <div className="font-weight-bold">
                          Languages
                        </div>
                        <Row>
                          <Col xs={12} className="pt-1">
                            {obv?.programmingLanguages?.sort().map((lang, i) => (
                                <Chip key={`${lang}-${i}`} className="mr-1"
                                      label={lang}/>
                            ))
                            }
                          </Col>
                        </Row>
                      </div>
                    }
                  </Col>
                  {!isMe &&
                    <Col xs={12} className="pt-5">
                      <Button
                          onClick={messageUser}
                          disabled={goingToMessage}
                          variant="contained" color="primary">
                        <i className="fa fa-envelope-open-text pr-2"></i>
                        Message {obv.name}
                      </Button>
                    </Col>
                  }
                </Row>
              </Card>
            </Container>
          </div>
        </div>
  )
};
