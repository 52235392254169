import React from 'react';
import { Col, Row } from 'reactstrap';
import { CircularProgress } from '@mui/material';

export const OrbitSpinner = (props) => {
  const { message } = props;

  return (
      <Row className="pt-5 d-flex">
        <Col className="my-5" md="12">
          <div className="orbit-spinner mx-auto ">
            <div className="orbit"></div>
            <div className="orbit"></div>
            <div className="orbit"></div>
          </div>
          <h2 className="pt-5">{message}</h2>
        </Col>
      </Row>
  )
}

export const LoaderInRow = (props) => {
  const { message } = props;

  return (
      <Row className="pt-5">
        <Col className="mx-auto text-center my-5" md="8">
          <CircularProgress />
          <h2 className="pt-5">{message}</h2>
        </Col>
      </Row>
  )
}

export const ErrLoading = () => {
  return (
      <Row>
        <Col className="mx-auto text-center my-5" md="8">
          <h1>
            <i className="fas fa-exclamation-circle text-red"></i>
          </h1>
          <h4>
            Oh no, something went wrong
          </h4>
          <span className="text-default">
            Please try again or contact support
          </span>
        </Col>
      </Row>
  )
}
