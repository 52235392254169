export const availableWorkTypes = [
  { type: 'Permanent',  selected: false },
  { type: 'Internship', selected: false },
  { type: 'Contract',   selected: false },
  { type: 'Part-time',  selected: false },
  { type: 'Casual',     selected: false },
];
export const availableCountries = [
  { name: 'Australia', selected: true },
];
export const availableCities = [
  'Adelaide',
  'Brisbane',
  'Canberra',
  'Darwin',
  'Melbourne',
  'Hobart',
  'Perth',
  'Sydney',
];
