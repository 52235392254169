import React, { useContext, useEffect, useState } from 'react';
import {
  Col,
  Row,
} from 'reactstrap';
import AppContext from '../../contexts/AppContext';
import RedirectButton from '../../components/buttons/RedirectButton';
import { API } from 'aws-amplify';
import { AccountType, Candidate } from '../../models';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import { useHistory } from 'react-router-dom';
import { Button, CircularProgress } from '@mui/material';

export const CandidateSummaryView = () => {
  const { saving, user: [usr, setUsr], onBoarding: [obv,] } = useContext(AppContext);
  const [isSaving, setIsSaving] = saving;
  const [errSaving, setErrSaving] = useState(false);
  const history = useHistory();

  const [retryCount, setRetryCount] = useState(0);

  const saveCandidate = async () => {
    if (!isSaving) {
      setIsSaving(true);
    }
    const { data: { getUser } } = await API.graphql(
        { query: queries.getUser , variables: { id: usr.id }}
    );
    const currentVersion = getUser._version;
    const user = {
      id: usr.id,
      name: usr.name,
      phone: usr.phone,
      _version: currentVersion,
      accountType: AccountType.CANDIDATE,
      candidate: new Candidate({
        ...obv,
      })
    };
    try {
      const { data: { updateUser } } = await API.graphql(
          { query: mutations.updateUser, variables: {
              input: user,
              condition: null,
            }}
      )
      setUsr(updateUser);
      history.push('/pending');
    } catch (err) {
      setRetryCount(retryCount + 1);
      console.log(retryCount);
    }
  }

  useEffect(() => {
    saveCandidate()
      .catch((e) => {
        console.error(e);
        setErrSaving(e);
      })
      .finally(() => setIsSaving(false));
  }, []);

  return (
      <>
        <div className="px-4">
          <div className="mt-5 pb-5">
            {errSaving ?
              <Row>
                <Col className="mx-auto text-center my-5" md="8">
                  <h1>
                    <i className="fas fa-exclamation-circle text-red"></i>
                  </h1>
                  <h4>
                    Oh no, something went wrong
                  </h4>
                  <span className="text-default">
                    Please try again or contact support
                  </span>
                  <div className="pt-5">
                    <Button className="mr-2"
                            disabled={retryCount >= 5}
                            variant="contained"
                            aria-label="back-button"
                            color="primary" onClick={() => saveCandidate()}>
                      Try again
                    </Button>

                  </div>
                </Col>
              </Row>
              :
              isSaving ?
              <Row>
                <Col className="mx-auto text-center mt-5" md="8">
                  <CircularProgress />
                  <h2 className="title mt-5">
                    <span className="text-default">Submitting your data</span>
                  </h2>
                </Col>
              </Row>
            :
              <Row id="done-ski">
                <Col className="mx-auto text-center mt-5" md="8">
                  <h1>
                    <i className="fas fa-check text-success"></i>
                  </h1>
                  <h2 className="title">
                    <span className="text-primary">You're all set</span>
                  </h2>
                </Col>
                <Col className="mx-auto" lg="8" md="12">
                  <p className="description mb-5 text-center">
                    Our team has been alerted that your account has been created
                    and submitted
                    <br/>
                    Your profile is now under review. Someone from our team will
                    let you know as soon as we've activated your account.
                  </p>
                </Col>
              </Row>
            }
          </div>
          <div className="mt-2 py-5 border-top">
            <Row>
              <Col xs={12} className="d-flex">
                <div className="flex-row ml-auto">
                  <RedirectButton redirectTo="/"
                                  color="primary"
                                  buttonText="Done"
                                  disabled={isSaving || errSaving} />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </>
  );
}
