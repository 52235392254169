import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import bgImg from '../../assets/img/ill/bg_contactus3.svg';
import { Col, Row } from 'reactstrap';
import AppContext from '../../contexts/AppContext';
import {
  Alert,
  Autocomplete, Box, Button, Checkbox, Chip, CircularProgress, Container,
  FormControl, FormControlLabel, FormGroup,
  Input,
  InputAdornment,
  MenuItem, Paper, Radio, RadioGroup,
  Select, Snackbar, Switch, Tab, Tabs, TextField, Tooltip, Typography
} from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import {
  currencies,
  eligibilityOptions,
  languages,
  noticeOptions,
  yearOptions,
  jobSeekingStatusConstant,
  getCurrency, roleMapping, candidateTypeOptions
} from '../../constants';
import { mustache } from '../../utils/StringUtils';
import RedirectButton from '../../components/buttons/RedirectButton';
import { API } from 'aws-amplify';
import { AccountType, Candidate } from '../../models';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import { LoaderInRow } from '../../components/loader/Loaders';
import { AmplifyPasswordReset } from '../../components/amplify/AmplifyPasswordReset';
import {
  availableCities,
  availableCountries,
  availableWorkTypes
} from '../../constants/Availability';
import { TabPanel } from '../../components/TabPanel';
import { useHistory } from 'react-router-dom';

const styles = makeStyles({
  container: {
    minHeight: '85vh',
    minWidth: '95vw',
    position: 'relative',
    '&::after': {
      content: '""',
      maxHeight: '100vh',
      maxWidth: '100vw',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      position: 'absolute',
      backgroundImage: `url(${bgImg})`,
      backgroundSize: 'cover',
      backgroundPosition: 'top center',
      opacity: 0.4,
      zIndex: -1,
    },
  },
  main: {
    display: 'grid',
  },
  radiusSm: {
    borderRadius: '10px',
  },
});

export const CandidateEdit = () => {
  const classes = styles();
  const history = useHistory();
  // @TODO Refactors each of these pieces into the same code on the other pages
  // so we dont have duplication (sorry this was fastest)
  const { auth: [authenticated], user: [usr, setUsr], saving: [isSaving, setIsSaving] } = useContext(AppContext);
  let candidate = { ...usr.candidate };
  const { countries } = candidate;
  candidate.countries.forEach((country) => {
    availableCountries.find(({name}) => name === country).selected = true
  });
  candidate.workType.forEach((workType) => {
    availableWorkTypes.find(({type}) => type === workType).selected = true
  })
  const [loading, setLoading] = useState(true);
  const [showToast, setShowToast] = useState(false);
  const [savingErr, setSavingErr] = useState(false);
  const [phone, setPhone] = useState(usr.phone);
  const [email] = useState(usr.email);
  const [name, setName] = useState(usr.name);
  const [seekingStatus, setSeekingStatus] = useState(candidate.jobSeekingStatus);
  const [availability, setAvailability] = useState(candidate.availability);
  const [selectedLanguages, setSelectedLanguages] = useState(candidate.programmingLanguages);
  const [workCategories, setWorkCategories] = useState(
      candidate.workCategories || []);
  const [currentCurrency, setCurrentCurrency] = useState(candidate.salaryCurrency);
  const [currentSalary, setCurrentSalary] = useState(candidate.salary || null);
  const [desiredCurrency, setDesiredCurrency] = useState(candidate.desiredCurrency);
  const [desiredSalary, setDesiredSalary] = useState(candidate.desiredSalary || null);
  const [selectedCities, setSelectedCities] = useState(candidate.cities || []);
  const [eligibilitySelection, setEligibilitySelection] = useState(
      candidate.eligibility || []);
  const [workTypesSelected, setWorkTypesSelected] = useState(
      availableWorkTypes);
  const [countriesSelected, setCountriesSelected] = useState(
      availableCountries);
  const isSelfView = authenticated?.email === usr?.email;
  const [openToRemote, setOpenToRemote] = useState(candidate.openToRemote);
  const [tabIndex, setTabIndex] = useState(0);
  const [enabling, setEnabling] = useState(false);

  const tabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const setUsrCandidate = () => {
    usr.candidate = candidate;
    setUsr(usr);
  }
  const eligibilityOptionChosen = (e, country) => {
    const value = e.target.value;
    const newList = eligibilitySelection.filter(
        (entry) => entry.country !== country);
    newList.push({country, value});
    setEligibilitySelection(newList);
    candidate.eligibility = newList;
    setUsrCandidate();
  };

  const isEligibleChecked = (ctry, status) => {
    const find = candidate.eligibility
      .find(({ country }) => country === ctry);
    return find.value === status;
  }

  const getEligibilityMatch = (country) => {
    const match = eligibilitySelection.find(
        (entry) => entry.country === country);
    return match && match.value;
  }
  const setUserPhone = (value) => {
    setPhone(value);
    usr.phone = value;
    setUsr(usr);
  }
  const lookingStatusChosen = (option) => {
    setSeekingStatus(option);
    candidate.jobSeekingStatus = option;
    setUsrCandidate(usr);
  };
  const setUserAvailability = (e) => {
    const val = e.target.value;
    candidate.availability = val;
    setUsrCandidate();
    setAvailability(val);
  }
  const setUsersName = (value) => {
    setName(value);
    usr.name = value;
    setUsr(usr);
  }
  const saveRole = (val) => {
    setWorkCategories(val);
    candidate.workCategories = val;
    setUsrCandidate();
  };
  const languageChoice = (val) => {
    setSelectedLanguages(val);
    candidate.programmingLanguages = val;
    setUsrCandidate();
  }
  const salaryElected = (value) => {
    setCurrentSalary(value);
    candidate.salary = value;
    setUsrCandidate();
  }
  const currentCurrencyChange = (e) => {
    const value = e.target.value;
    // const match = currencies.find(({abr}) => abr === value);
    setCurrentCurrency(value);
    candidate.salaryCurrency = value;
    setUsrCandidate();
  }
  const desiredSalaryElected = (value) => {
    setDesiredSalary(value);
    candidate.desiredSalary = value;
    setUsrCandidate();
  }
  const desiredCurrencyChange = (e) => {
    const value = e.target.value;
    // const match = currencies.find(({abr}) => abr === value);
    setDesiredCurrency(value);
    candidate.desiredCurrency = value;
    setUsrCandidate();
  }
  const getWorkCategories = () => {
    return candidate.candidateTypes
      .map((type) => candidateTypeOptions
      .find(({ value }) => type === value))
      .map(({ categories }) => categories).flat();
  }
  const [availableWorkCategories, setAvailableWorkCategories] = useState(getWorkCategories())

  const enableAccount = async () => {
    setEnabling(true);
    await API.graphql({
      query: mutations.updateUser,
      variables: {
        input: {
          id: usr.id,
          _version: usr._version,
          active: true,
        },
      }
    });
    const { data: { getUser }} = await API.graphql(
        { query: queries.getUser, variables: { id: usr.id }}
    );
    setUsr(getUser);
    setEnabling(false);
  }
  const toggleWorkTypeSelection = (workType) => {
    const newSelected = [
      ...workTypesSelected,
    ];
    const match = newSelected.find(({type}) => workType === type);
    match.selected = !match.selected;
    setWorkTypesSelected(newSelected);
    candidate.workType = newSelected
    .filter(({selected}) => selected)
    .map(({type}) => type);
    setUsrCandidate();
  }
  useEffect(() => {
    const getUsah = async () => {
      const { data: { getUser } } = await API.graphql(
          { query: queries.getUser , variables: { id: usr.id }}
      );
      return getUser;
    }
    getUsah().then((u) => {
      setUsr(u);
    }).finally(() => setLoading(false));
  }, []);

  const setUserOpenToRemote = () => {
    const isOpen = !openToRemote;
    setOpenToRemote(isOpen);
    candidate.openToRemote = isOpen;
    setUsrCandidate();
  }

  const saveCandidate = async () => {
    setIsSaving(true);
    const newUsr = {
      id: usr.id,
      name: usr.name,
      phone: usr.phone,
      active: usr.active,
      _version: usr._version,
      accountType: AccountType.CANDIDATE,
      candidate: new Candidate({
        ...usr.candidate,
      })
    };
    await API.graphql(
        { query: mutations.updateUser, variables: {
            input: newUsr,
            condition: null,
          }}
    )
    const { data: { getUser }} = await API.graphql(
        { query: queries.getUser, variables: { id: usr.id }}
    );
    candidate = { ...getUser.candidate };
    setUsr(getUser);
  };

  const saveAndNext = async () => {
    try {
      setIsSaving(true);
      setSavingErr(false);
      await saveCandidate();
    } catch (err) {
      console.error(err);
      setSavingErr(err);
    } finally {
      setShowToast(true);
      setIsSaving(false);
    }
  }

  const cityChoice = (val) => {
    setSelectedCities(val);
    candidate.cities = val;
    setUsrCandidate();
  }

  const toggleCountrySelection = (country) => {
    const newSelected = [
      ...countriesSelected,
    ]
    const match = newSelected.find(({name}) => country === name);
    match.selected = !match.selected;
    if (!match.selected) {
        const newList = eligibilitySelection.filter(
            (entry) => entry.country !== country);
        setEligibilitySelection(newList);
        candidate.eligibility = newList;
    }
    setCountriesSelected(newSelected);
    candidate.countries = newSelected
      .filter(({selected}) => selected)
      .map(({name}) => name);
    setUsrCandidate();
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowToast(false);
  };

  const toggleCandidateType = (e) => {
    const workCategories = getWorkCategories();
    const category = e.target.value;
    const checked = e.target.checked;
    if (checked) {
      candidate.candidateTypes = [
          ...new Set([
            ...candidate.candidateTypes,
            category,
          ]),
        ];
    } else {
        candidate.candidateTypes =
            candidate.candidateTypes.filter((type) => type != category)
    }
    candidate.workCategories =
        candidate.workCategories.filter((cat) =>
            getWorkCategories().indexOf(cat) >= 0);
    setWorkCategories(candidate.workCategories);
    setAvailableWorkCategories(workCategories);
    setUsrCandidate();
  };

  return (
      <div className={classes.container}>
        <div className={classes.main}>
          <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={showToast} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose}
                   severity={savingErr ? 'error' : 'success'}
                   sx={{ width: '100%' }}>
              {savingErr ?
                  'Failed to save updates' :
                  'Your profile has been saved'
              }
            </Alert>
          </Snackbar>
          <Container>
            <Paper>
              <Tabs
                  className="mt-2"
                  value={tabIndex} onChange={tabChange} aria-label="basic tabs example">
                <Tab label="Profile"  />
                <Tab label="Settings" />
              </Tabs>
              <TabPanel tabIndex={tabIndex} index={0} sx={{ p: 2, pl: 4 }}>
                <Box className="position-relative">
                  {loading ?
                      <LoaderInRow message={'Loading'} />
                      :
                      isSaving ?
                          <Row>
                            <Col className="mx-auto text-center mt-5" md="8">
                              <CircularProgress />
                              <h2 className="title mt-5">
                          <span
                              className="text-default">
                            Saving your profile
                          </span>
                              </h2>
                            </Col>
                          </Row>
                          :
                          <>
                            <Row>
                              <Col xs={9} md={9}>
                                <div
                                    className="h-100 w-100 d-flex flex-column justify-content-center">
                                  <h2 className="pt-xs-2 text-default">
                                    Edit your profile
                                  </h2>
                                  {!usr.reviewed &&
                                  <div>
                                    *Pending admin approval
                                    <Tooltip
                                        className="ml-2"
                                        title="Your profile is still pending approval by Administrators">
                                      <InfoOutlined />
                                    </Tooltip>
                                  </div>
                                  }
                                </div>
                              </Col>
                              {isSelfView &&
                              <>
                                <Col md={3} xs={12}>
                                  <RedirectButton
                                      disabled={enabling}
                                      className="w-100"
                                      buttonText="Preview"
                                      color="primary"
                                      variant="contained"
                                      redirectTo="/you"
                                  />
                                  {usr.active ?
                                    <Button
                                        color={"error"}
                                        className="w-100 mt-1"
                                        variant="contained"
                                        onClick={() => history.push('/disable')}
                                    >
                                      Disable account
                                    </Button>
                                  :
                                    <Button
                                        disabled={enabling}
                                        onClick={enableAccount}
                                        className="w-100 mt-1"
                                        variant="contained"
                                        color="info">
                                      Enable account
                                    </Button>
                                  }
                                </Col>
                              </>
                              }
                            </Row>
                            {/*<Row className="mt-4">*/}
                            {/*  <Col xs={12} md={6}>*/}
                            {/*    <ProfilePictureUpload />*/}
                            {/*    <div>*/}
                            {/*      <b>*/}
                            {/*        Profile active*/}
                            {/*      </b>*/}
                            {/*      <Checkbox checked={active}*/}
                            {/*                onChange={() => {*/}
                            {/*                  toggleUsrActive()*/}
                            {/*                }}*/}
                            {/*                inputProps={{ 'aria-label': 'controlled' }}*/}
                            {/*      />*/}
                            {/*      <Tooltip title="If your profile is active it will be visible to employers">*/}
                            {/*        <InfoOutlined />*/}
                            {/*      </Tooltip>*/}
                            {/*    </div>*/}
                            {/*    <div className="mt--2">*/}
                            {/*      <Typography  variant="caption">*/}

                            {/*      </Typography>*/}
                            {/*    </div>*/}

                            {/*  </Col>*/}
                            {/*</Row>*/}
                            <Row className="pt-2">
                              <Col xs={12} md={6}>
                                <b>
                                  Preferred Name
                                </b>
                                <FormControl variant="standard" className="w-100">
                                  <Input
                                      id="input-name"
                                      value={name}
                                      placeholder="Name"
                                      onChange={(e) => setUsersName(e.target.value)}
                                  />
                                </FormControl>
                              </Col>
                            </Row>
                            <Row className="pt-2">
                              <Col xs={12} md={6}>
                                <b>
                                  Contact number
                                </b>
                                <FormControl variant="standard" className="w-100">
                                  <Input
                                      id="input-phonenr"
                                      value={phone}
                                      type="tel"
                                      placeholder="Contact number"
                                      onChange={(e) => setUserPhone(e.target.value)}
                                      startAdornment={
                                        <InputAdornment position="start">
                                          📱
                                        </InputAdornment>
                                      }
                                  />
                                </FormControl>
                              </Col>
                            </Row>
                            <Row className="pt-2">
                              <Col xs={12} md={6}>
                                <b>
                                  Email address
                                </b>
                                <FormControl variant="standard" className="w-100">
                                  <Input
                                      id="input-email"
                                      disabled
                                      value={email}
                                      placeholder="Email Address"
                                      type="email"
                                      startAdornment={
                                        <InputAdornment position="start">
                                          ✉
                                        </InputAdornment>
                                      }
                                  />
                                </FormControl>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={12}>
                                <Row className="mt-4">
                                  <Col xs={12} className="w-100">
                                    <div className="mb-1">
                                      <b>
                                        Select where you'd like to work
                                      </b>
                                    </div>
                                    {availableCountries.map(({ name }, idx) => {
                                      return (
                                          <Row key={`${name}-${idx}`}>
                                            <Col xs={8}>
                                              <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                      <Checkbox
                                                          value={name}
                                                          disabled
                                                          checked={candidate.countries.some((ctry) => ctry === name)}
                                                          onChange={() => toggleCountrySelection(name)} />
                                                    }
                                                    label={name} />
                                              </FormGroup>
                                              <Typography variant="caption">
                                                * We currently only support Australia, please let us know if you're interested in work elsewhere
                                              </Typography>
                                            </Col>
                                          </Row>
                                      )
                                    })}
                                  </Col>
                                  <Col xs={12} className={"w-100 my-3"}>
                                    <div className="mb-1">
                                      <b>
                                        Cities where you'd like to work
                                      </b>
                                    </div>
                                    <Autocomplete
                                        multiple={true}
                                        filterSelectedOptions={true}
                                        value={selectedCities || null}
                                        onChange={(e, value) => cityChoice(value)}
                                        renderTags={(value, getTagProps) =>
                                            value.map((option, index) => (
                                                <Chip color="primary" label={option} {...getTagProps({ index })} />
                                            ))
                                        }
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                placeholder="Cities"
                                            />}
                                        options={availableCities} />
                                  </Col>
                                </Row>
                              </Col>
                              <Col xs={12} md={6}>
                                <b>
                                  Availability
                                </b>
                                <FormControl className="w-100">
                                  <Select
                                      variant="standard"
                                      value={availability}
                                      onChange={(e) => setUserAvailability(e)}
                                  >
                                    {noticeOptions.map(({text, value}, idx) => {
                                      return (
                                          <MenuItem key={idx} value={value}>
                                            {text}
                                          </MenuItem>
                                      )
                                    })}
                                  </Select>
                                </FormControl>
                              </Col>
                            </Row>
                            <Row className="pt-4">
                              <Col xs="12">
                                <div className="mb-1">
                                  <b>
                                    How many (total) years of experience do you have?
                                  </b>
                                </div>
                                <FormControl className="w-100">
                                  <Select
                                      variant="standard"
                                      onChange={(e) => {
                                        setUsr({
                                          ...usr,
                                          candidate: {
                                            ...usr.candidate,
                                            yearsExperience: e.target.value,
                                          }
                                        })
                                      }}
                                      value={
                                        candidate.yearsExperience || ''
                                      }
                                  >
                                    {yearOptions.map(({value, text}, idx) => {
                                      return (
                                          <MenuItem value={value} key={value}>
                                            {text}
                                          </MenuItem>
                                      )
                                    })
                                    }
                                  </Select>
                                </FormControl>
                              </Col>
                            </Row>
                            <Row className="mt-4">
                              <Col xs="12">
                                <div className="mb-1">
                                  <b>
                                    Which industries would you work in?
                                  </b>
                                </div>
                                <Row>
                                {candidateTypeOptions
                                  .filter(({ available }) => available)
                                  .map(({ label, value }, idx) => {
                                  return (
                                        <Col xs={4} key={`${value}_${idx}`}>
                                          <FormGroup>
                                            <FormControlLabel
                                                control={
                                                  <Switch
                                                      value={`${value}`}
                                                      checked={!!candidate.candidateTypes.find(
                                                          (candType) => candType === value)}
                                                      onChange={(e) => toggleCandidateType(e)} />
                                                }
                                                label={label} />
                                          </FormGroup>
                                        </Col>
                                  )
                                })}
                                </Row>

                              </Col>
                              <Col xs="12">
                                <div className="mb-2">
                                  <b>
                                    Select your categories
                                  </b>
                                </div>
                                <Autocomplete
                                    multiple={true}
                                    filterSelectedOptions={true}
                                    value={workCategories || null}
                                    onChange={(e, value) => saveRole(value)}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip color="primary" label={option} {...getTagProps({ index })} />
                                        ))
                                    }
                                    groupBy={(option) => {
                                      return roleMapping[option];
                                    }}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            placeholder="Categories"
                                        />}
                                    options={availableWorkCategories} />
                              </Col>
                            </Row>
                            <Row className="mt-4">
                              <Col xs="12">
                                <div className="mb-2">
                                  <b>
                                    Select the languages you're familiar with
                                  </b>
                                </div>
                                <Autocomplete
                                    multiple={true}
                                    filterSelectedOptions={true}
                                    value={selectedLanguages}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip color="success" label={option} {...getTagProps({ index })} />
                                        ))
                                    }
                                    onChange={(e, value) => languageChoice(value)}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            placeholder="Languages"
                                        />}
                                    options={languages}/>
                              </Col>
                            </Row>
                            <Row className="mt-4">
                              <Col xs={12}>
                                <div>
                                  <b>
                                    Salary
                                  </b>
                                  <div>
                                    <Typography variant="caption">
                                      What is your annual income package <b>(full annual package)</b>
                                    </Typography>
                                  </div>
                                </div>
                                <div className="d-flex flex-row w-100">
                                  <FormControl variant="standard" className="w-auto">
                                    <Select
                                        variant="standard"
                                        value={getCurrency(currentCurrency).abr}
                                        onChange={(e) => currentCurrencyChange(e)}
                                    >
                                      {currencies.map(({abr, name}, idx) => {
                                        return (
                                            <MenuItem key={`${abr}-${name}`}
                                                      value={abr}>
                                              {abr}
                                            </MenuItem>
                                        )
                                      })}
                                    </Select>
                                  </FormControl>
                                  <FormControl variant="standard"
                                               className="flex-grow-1">
                                    <Input
                                        id="input-currency"
                                        value={currentSalary}
                                        placeholder="Current salary"
                                        onChange={(e) => salaryElected(
                                            e.target.value)}
                                        startAdornment={
                                          <InputAdornment position="start">
                                            {getCurrency(currentCurrency).symbol}
                                          </InputAdornment>
                                        }
                                    />
                                  </FormControl>
                                </div>
                              </Col>
                              <Col xs={12}>
                                <div className="mt-3">
                                  <b>
                                    Nominated Salary
                                  </b>
                                  <div>
                                    <Typography variant="caption">
                                      How much would you like to earn <b>(full annual package)</b>
                                    </Typography>
                                  </div>
                                </div>
                                <div className="d-flex flex-row w-100">
                                  <FormControl variant="standard" className="w-auto">
                                    <Select
                                        variant="standard"
                                        value={getCurrency(desiredCurrency).abr}
                                        onChange={(e) => desiredCurrencyChange(e)}
                                    >
                                      {currencies.map(({abr, name}, idx) => {
                                        return (
                                            <MenuItem key={`${abr}-${name}`}
                                                      value={abr}>
                                              {abr}
                                            </MenuItem>
                                        )
                                      })}
                                    </Select>
                                  </FormControl>
                                  <FormControl variant="standard"
                                               className="flex-grow-1">
                                    <Input
                                        id="input-currency-desired"
                                        value={desiredSalary}
                                        placeholder="Desired salary"
                                        onChange={(e) => desiredSalaryElected(
                                            e.target.value)}
                                        startAdornment={
                                          <InputAdornment position="start">
                                            {getCurrency(desiredCurrency).symbol}
                                          </InputAdornment>
                                        }
                                    />
                                  </FormControl>
                                </div>
                              </Col>
                            </Row>
                            <Row className="pt-4">
                              <Col xs={12} className="w-100">
                                <div className="pb-2">
                                  <b>
                                    Job search status
                                  </b>
                                </div>
                                <FormControl component="fieldset" variant="standard">
                                  <RadioGroup
                                      aria-label="gender"
                                      name="controlled-radio-buttons-group"
                                      value={seekingStatus}
                                      onChange={(e, v) => lookingStatusChosen(v)}
                                  >
                                    {jobSeekingStatusConstant.map(({value, text}, idx) =>
                                        <FormControlLabel value={value}
                                                          control={<Radio/>}
                                                          label={text}
                                                          key={`${value}-${idx}`}/>
                                    )}
                                  </RadioGroup>
                                </FormControl>
                              </Col>
                            </Row>
                            <Row className="pt-2">
                              <Col xs={12} className="mb-2">
                                <div className="mb-1">
                                  <b>
                                    Are you open to remote work?
                                  </b>
                                </div>
                                <FormGroup>
                                  <FormControlLabel
                                      control={
                                        <Checkbox
                                            value={openToRemote}
                                            checked={openToRemote}
                                            onChange={() => setUserOpenToRemote()}
                                        />
                                      }
                                      label={'Open to remote work'} />
                                </FormGroup>
                              </Col>
                              <Col xs={12}>
                                <div className="mb-1">
                                  <b>
                                    Which method of work would you like
                                  </b>
                                </div>
                                {availableWorkTypes.map(({ type }, idx) => {
                                  return (
                                      <Row key={`${type}-${idx}`}>
                                        <Col xs={8}>
                                          <FormGroup>
                                            <FormControlLabel
                                                control={
                                                  <Checkbox
                                                      value={type}
                                                      checked={candidate.workType.some((workType) => workType === type)}
                                                      onChange={() => toggleWorkTypeSelection(type)} />
                                                }
                                                label={type} />
                                          </FormGroup>
                                        </Col>
                                      </Row>
                                  )
                                })}
                              </Col>
                            </Row>
                            {(candidate.countries.length > 0) &&
                            <Row className="pt-4">
                              <Col xs={12}>
                                <h5 className="header-5">
                                  What is your citizenship status in
                                </h5>
                                <Row>
                                  {countries.map((country, idx) => {
                                    return (
                                        <Col xs={12}
                                             className="w-100"
                                             key={`${country}-${idx}`}>
                                          <div key={`${country}-${idx}`}>
                                            <div className="mb-2">
                                              <b>
                                                {country}
                                              </b>
                                            </div>
                                            <FormControl component="fieldset"
                                                         variant="standard">
                                              <RadioGroup
                                                  aria-label="gender"
                                                  name="controlled-radio-buttons-group"
                                                  value={getEligibilityMatch(country)}
                                              >
                                                {eligibilityOptions.map(
                                                    ({name, value}, idx) => {
                                                      return (
                                                          <FormControlLabel
                                                              value={value}
                                                              control={
                                                                <Radio
                                                                    checked={isEligibleChecked(country, value)}
                                                                />
                                                              }
                                                              onChange={(e) => eligibilityOptionChosen(
                                                                  e, country)}
                                                              label={mustache(name,
                                                                  'ctry', country)}
                                                              key={`${value}-${idx}`}/>
                                                      )
                                                    })}
                                              </RadioGroup>
                                            </FormControl>
                                          </div>
                                        </Col>
                                    )
                                  })
                                  }
                                </Row>
                              </Col>
                            </Row>
                            }
                            <div className="mt-auto pt-5">
                              <Row>
                                <Col xs={12} className="d-flex">
                                  <div>
                                    <RedirectButton buttonText={'Cancel'}
                                                    color={'primary'}
                                                    variant="outlined"
                                                    redirectTo={'/'}/>
                                  </div>
                                  <div className="flex-row ml-auto">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => saveAndNext()}>
                                      Save
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </>
                  }
                </Box>
              </TabPanel>
              <TabPanel tabIndex={tabIndex} index={1} sx={{ p: 2, pl: 4 }}>
                <Box className="position-relative">
                  <div className="mb-2">
                    <b>
                      Password Reset
                    </b>
                  </div>
                  <AmplifyPasswordReset />
                </Box>
              </TabPanel>
            </Paper>
          </Container>
        </div>
      </div>
  )
};
