import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import AppContext from '../../../contexts/AppContext';
import { MenuItem, MenuList } from '@mui/material';

export const NavbarDropdownMobile = ({ onClick }) => {
  const history = useHistory();

  const {
    auth: [authenticated],
    user,
    loading: [, setLoading],
    loadingMessage: [, setLoadingMessage],
  } = useContext(AppContext);
  const [userVal] = user;
  const isReviewed = userVal && userVal.reviewed;

  const logoutUser = (e) => {
    e.preventDefault();
    setLoadingMessage('Logging you out');
    setLoading(true);
    Auth.signOut().then(() => {
      setLoading(false);
      setLoadingMessage('');
    })
    goToPage('/');
  }

  const goToPage = (path) => {
    history.push(path);
    onClick();
  }

  return (
        <MenuList>
          {(userVal && !!userVal.accountType && isReviewed) &&
            <>
              {authenticated.groups.indexOf('administrators') >= 0 &&
              <MenuItem
                  onClick={() => goToPage('/admin')}
              >
                Administrator Home
              </MenuItem>
              }
              {authenticated.groups.indexOf('employers') >= 0 &&
              <MenuItem
                  onClick={() => goToPage('/employer')}
              >
                Employer Home
              </MenuItem>
              }
              <MenuItem
                  onClick={() => goToPage('/chat')}
              >
                Messages
              </MenuItem>
              <MenuItem
                  onClick={() => goToPage('/')}
              >
                Edit Profile
              </MenuItem>
            </>
          }
          <MenuItem
              onClick={(e) => logoutUser(e)}
          >
            Logout
          </MenuItem>
        </MenuList>
  )
}
