import React, { useEffect, useState } from 'react';
import { API } from 'aws-amplify';

import {
  Button, Chip,
  Container,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs, TextField
} from '@mui/material';
import { TabPanel } from '../../../components/TabPanel';
import {
  adminAddUserToGroup,
  adminListAdministrators,
  adminListCandidates,
  adminListEmployers,
  adminListGroupsForUser,
  adminListUsers,
  adminRemoveUserFromGroup
} from '../../../utils/AdminQueryUtils';
import { LoaderInRow } from '../../../components/loader/Loaders';
import * as queries from '../../../graphql/queries';
import { AccountType } from '../../../models';
import { fetchLimit } from '../../../constants/apiConstants';

export const GroupsAdmin = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  // Data from cognito
  const [cognitoUsers, setCognitoUsers] = useState([]);
  const [candidates, setCandidates] = useState([]);
  const [employers, setEmployers] = useState([]);
  const [admins, setAdmins] = useState([]);
  // Search
  const [searchName, setSearchName] = useState('');
  // API Data
  const [users, setUsers] = useState([]);
  const tabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const getAllData = async () => {
    await Promise.all([
      API.graphql(
          { query: queries.listUsers, variables: { limit: fetchLimit, } }
      ).then(({ data: { listUsers: { items }}}) => setUsers(items?.filter((item) => !item._deleted))),
      adminListCandidates()
        .then(({ Users }) => setCandidates(Users)),
      adminListEmployers()
        .then(({ Users }) => setEmployers(Users)),
      adminListAdministrators()
        .then(({ Users }) => setAdmins(Users)),
      adminListUsers()
        .then(({ Users }) => setCognitoUsers(Users))
    ]);
  }

  useEffect(() => {
    getAllData()
    .finally(() => setLoading(false));
  }, []);

  const getUserFromCognitoUser = (user) => {
    if (!user) return;
    const { Value } = user.Attributes.find((attr) => attr.Name === 'email');
    const match = users.find(({ email }) => email === Value);
    return {
      ...match,
      original: { ...user },
    };
  };
  const fixUserCognitoGroup = async (user) => {
    const username = user.original.Username;
    const { Groups } = await adminListGroupsForUser(username);
    const groups =  Groups.map(({ GroupName }) => GroupName);
    // ensure the user is part of the wrong group
    setLoading(true);
    if (user.accountType === AccountType.CANDIDATE) {
      if (groups.indexOf('employers') >= 0) {
        await adminRemoveUserFromGroup(username, 'employers');
      }
      if (groups.indexOf('candidates') === -1) {
        await adminAddUserToGroup(username, 'candidates');
      }
    }
    if (user.accountType === AccountType.EMPLOYER) {
      if (groups.indexOf('candidates') >= 0) {
        await adminRemoveUserFromGroup(username, 'candidates');
      }
      if (groups.indexOf('employers') === -1) {
        await adminAddUserToGroup(username, 'employers');
      }
    }
    await getAllData().finally(() => setLoading(false));
  }

  if (loading) {
    return (
        <Container >
          <LoaderInRow />
        </Container>
    )
  }

  return (
      <>
        <Tabs value={tabIndex} onChange={tabChange} aria-label="admin tabs">
          <Tab label="Employers" />
          <Tab label="Candidates" />
          <Tab label="Admins" />
          <Tab label="Unassigned" />
          <Tab label="No Auth data" />
        </Tabs>
        <TabPanel tabIndex={tabIndex} index={0} sx={{ p: 2 }}>
          <div className="d-flex flex-row align-items-center">
            <TextField
                value={searchName}
                onChange={(e) => setSearchName(e.target.value)}
                className="my-2" label="Search by name" variant="filled" />
          </div>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Account Type</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {employers
                .map((employer) => getUserFromCognitoUser(employer))
                .filter(({ name }) => {
                  if (!searchName || searchName.length === 0) {
                    return true;
                  }
                  return name.toLowerCase().indexOf(searchName.toLowerCase()) >= 0;
                })
                .map((user, idx) => {
                  return (
                      <TableRow
                          key={`employer-row-${idx}`}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          {user.name}
                        </TableCell>
                        <TableCell>
                          {user.email}
                        </TableCell>
                        <TableCell>
                          <Chip label={user.accountType}
                                color={user.accountType === AccountType.EMPLOYER ? 'success' : 'error'}
                          />
                        </TableCell>
                        <TableCell>
                          {user.accountType !== AccountType.EMPLOYER &&
                          <Button variant="contained"
                                  onClick={() => fixUserCognitoGroup(user)}
                          >
                            Fix Cognito group
                          </Button>
                          }
                          {user.accountType === AccountType.EMPLOYER &&
                          <Button disabled>No actions</Button>
                          }
                        </TableCell>
                      </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
        <TabPanel tabIndex={tabIndex} index={1} sx={{ p: 2 }}>
          <div className="d-flex flex-row align-items-center">
            <TextField
                value={searchName}
                onChange={(e) => setSearchName(e.target.value)}
                className="my-2" label="Search by name" variant="filled" />
          </div>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Account Type</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {candidates
                .map((user) => getUserFromCognitoUser(user))
                .filter(({ name }) => {
                  if (!searchName || searchName.length === 0) {
                    return true;
                  }
                  return name.toLowerCase().indexOf(searchName.toLowerCase()) >= 0;
                })
                .map((user, idx) => {
                  return (
                      <TableRow
                          key={`candidates-row-${idx}`}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          {user.name}
                        </TableCell>
                        <TableCell>
                          {user.email}
                        </TableCell>
                        <TableCell>
                          <Chip label={user.accountType}
                                color={user.accountType === AccountType.CANDIDATE ? 'success' : "error"}
                          />
                        </TableCell>
                        <TableCell>
                          {user.accountType !== AccountType.CANDIDATE &&
                          <Button variant="contained"
                                  onClick={() => fixUserCognitoGroup(user)}
                          >
                            Fix Cognito group
                          </Button>
                          }
                          {user.accountType === AccountType.CANDIDATE &&
                          <Button disabled>No actions</Button>
                          }
                        </TableCell>
                      </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
        <TabPanel tabIndex={tabIndex} index={2} sx={{ p: 2 }}>
          <div className="d-flex flex-row align-items-center">
            <TextField
                value={searchName}
                onChange={(e) => setSearchName(e.target.value)}
                className="my-2" label="Search by name" variant="filled" />
          </div>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Account Type</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {admins
                  .map((user) => getUserFromCognitoUser(user))
                  .filter(({ name }) => {
                    if (!searchName || searchName.length === 0) {
                      return true;
                    }
                    return name.toLowerCase().indexOf(searchName.toLowerCase()) >= 0;
                  })
                .map((user, idx) => {
                  return (
                      <TableRow
                          key={`admin-row-${idx}`}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          {user.name}
                        </TableCell>
                        <TableCell>
                          {user.email}
                        </TableCell>
                        <TableCell>
                          <Chip label={user.accountType}
                                color={user.accountType === AccountType.ADMIN ? 'success' : "error"}
                          />
                        </TableCell>
                        <TableCell>
                          {user.accountType !== AccountType.ADMIN &&
                          <Button variant="contained"
                                  onClick={() => fixUserCognitoGroup(user)}
                          >
                            Fix Cognito group
                          </Button>
                          }
                          {user.accountType === AccountType.ADMIN &&
                          <Button disabled>No actions</Button>
                          }
                        </TableCell>
                      </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
        <TabPanel tabIndex={tabIndex} index={3} sx={{ p: 2 }}>
          <div className="d-flex flex-row align-items-center">
            <TextField
                value={searchName}
                onChange={(e) => setSearchName(e.target.value)}
                className="my-2" label="Search by name" variant="filled" />
          </div>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Cognito ID</TableCell>
                  <TableCell>Account Type</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cognitoUsers
                .filter((user) => !employers.find(({ Username }) => user.Username === Username))
                .filter((user) => !candidates.find(({ Username }) => user.Username === Username))
                .filter((user) => !admins.find(({ Username }) => user.Username === Username))
                .map((user) => getUserFromCognitoUser(user))
                .filter((user) => !!user.name)
                .filter(({ name }) => {
                  if (!searchName || searchName.length === 0) {
                    return true;
                  }
                  return name.toLowerCase().indexOf(searchName.toLowerCase()) >= 0;
                })
                .map((user, idx) => {
                  return (
                      <TableRow
                          key={`unassigned-row-${idx}`}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          {user.name}
                        </TableCell>
                        <TableCell>
                          {user.email}
                        </TableCell>
                        <TableCell>
                          {user.owner}
                        </TableCell>
                        <TableCell>
                          <Chip
                              color={(user.accountType && user.accountType !== '') ? 'success' : 'error'}
                              label={user.accountType} />
                        </TableCell>
                        <TableCell>
                          <Button
                              disabled={
                                !user.accountType
                                || user.accountType === AccountType.ADMIN
                              }
                              variant="contained"
                              onClick={() => fixUserCognitoGroup(user)}
                          >
                            Fix Cognito group
                          </Button>
                        </TableCell>
                      </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
        <TabPanel tabIndex={tabIndex} index={4} sx={{ p: 2 }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Cognito ID</TableCell>
                  <TableCell>Email</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cognitoUsers
                  .filter((user) => !employers.find(({ Username }) => user?.Username === Username))
                  .filter((user) => !candidates.find(({ Username }) => user?.Username === Username))
                  .filter((user) => !admins.find(({ Username }) => user?.Username === Username))
                  .map((user) => getUserFromCognitoUser(user))
                  .filter((user) => !user.name)
                  .map((user) => user.original)
                  .map((user, idx) => {
                    return (
                        <TableRow
                            key={`cognito-users-row-${idx}`}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                            {user.Username}
                          </TableCell>
                          <TableCell>
                            {user.Attributes.find(({ Name }) => Name === 'email').Value}
                          </TableCell>
                        </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
      </>
  )
}
