import React from 'react';

export const OnboardingHeader = ({ header, subHeader }) => {

  return (
      <div className="text-center mt-5">
        <h3 className="mt-3">
          {header}
        </h3>
        <h4 className="mt-2">
          {subHeader}
        </h4>
      </div>
  )
};
