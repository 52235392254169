import * as yup from 'yup';

export const signUpSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup
    .string('Enter your password')
    .min(6, 'Password should be of minimum 6 characters length')
    .required('Password is required'),
  termsAccepted: yup
    .bool().oneOf([true], 'Accepting the terms is required')
});

export const signInSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter your email')
    .required('Email is required'),
  password: yup
    .string('Enter your password')
    .required('Password is required'),
});

export const verifySchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter your email')
    .required('Email is required'),
  verificationCode: yup
    .string('Enter your verification code')
    .min(6, 'Must be 6 digits')
    .max(6, 'Must be 6 digits')
    .required('Verification code is required'),
});

export const resetPassSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter your valid email')
    .required('Email is required'),
});

export const resetPasswordSchema = yup.object({
  oldPassword: yup
    .string('Enter your old password')
    .required('Old password is required'),
  password: yup
    .string('Enter your new password')
    .required('New password is required'),
  confirmPassword: yup
    .string('Confirm your password')
    .required('Confirm password is required')
    .oneOf([yup.ref('password'), null], 'Passwords must match')
})

export const updatePassSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter your email')
    .required('Email is required'),
  verificationCode: yup
    .string('Enter your verification code')
    .min(6, 'Must be 6 digits')
    .max(6, 'Must be 6 digits')
    .required('Verification code is required'),
  password: yup
    .string('Enter your password')
    .min(6, 'Password should be of minimum 6 characters length')
    .required('Password is required'),
});

export const blogPostSchema = yup.object({
  title: yup
    .string('Enter the title')
    .required('Title is required'),
  slug: yup
    .string('Enter the slug')
    .required('Slug is required'),
  content: yup
    .string('Enter the content')
    .required('Content is required'),
  authorName: yup
    .string('Enter the author')
    .required('Author is required'),
  preview: yup
    .string('Enter the preview text')
    .required('Preview text is required'),
});

// title,
// slug,
// content,
// imageUrl,
// authorName,
// preview,
// hidden,
