export const availabilityText = (noticeValue) => {
  return noticeOptions.find(({ value }) => value === noticeValue)?.summary;
}

export const noticeOptions = Object.freeze([
  { text: 'No notice period', value: '0weeks', summary: 'Available now' },
  { text: '1 week', value: '1week', summary: 'Available with one week notice' },
  { text: '2 weeks', value: '2weeks', summary: 'Available with two weeks notice' },
  { text: '4 weeks', value: '4weeks', summary: 'Available with four weeks notice' },
  { text: '4+ weeks', value: 'moreWeeks', summary: 'Needs 4+ weeks notice' },
]);

export const companySizes = Object.freeze([
  { text: 'Less than 10', value: 'lessThan10' },
  { text: '11 to 50', value: '11to50' },
  { text: '51 to 100', value: '51to100' },
  { text: '100+', value: 'over100' },
]);

export const getCompanySizeText = (value) => {
  return companySizes.find((option) => value === option.value)?.text;
}

export const jobSeekingStatusConstant = Object.freeze([
  {
    value: 'already-interviewing',
    text: `I've already had some interviews`,
  },
  {
    value: 'ready-to-interview',
    text: 'I want to start interviewing',
  },
  {
    value: 'looking-around',
    text: 'I am just keeping an eye on the market',
  },
  {
    value: 'just-looking',
    text: `Don't want to change jobs quite yet`,
  },
]);

export const yearOptions = Object.freeze([
  { value: 'zeroToOne', text: '0-1 year', range: [0, 1] },
  { value: 'oneToThree', text: '1-3 years', range: [1, 3] },
  { value: 'threeToFive', text: '3-5 years', range: [3, 5] },
  { value: 'sixToTen', text: '6-10 years', range: [6, 9] },
  { value: 'tenPlus', text: '10+ years', range: [10, 99] },
]);

export const getYearsValueFromText = (txt) => {
  return yearOptions.find((option) => txt === option.text);
}

export const getYearsExperienceRange = (value) => {
  return yearOptions.find((option) => value === option.value)?.range;
}

export const getYearOptionsText = (value) => {
  return yearOptions.find((option) => value === option.value)?.text;
}

export const eligibilityOptions = Object.freeze([
  {
    name: 'I hold citizenship in {{ctry}}',
    value: 'citizenship',
  },
  {
    name: 'I am a Permanent Resident in {{ctry}}',
    value: 'pr',
  },
  {
    name: 'I have a Work visa for {{ctry}}',
    value: 'visa',
  },
  {
    name: 'None of the above',
    value: 'none',
  },
]);
