/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      recipient
      type
      text
      seen
      context
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      recipient
      type
      text
      seen
      context
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      recipient
      type
      text
      seen
      context
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const createConversationMsg = /* GraphQL */ `
  mutation CreateConversationMsg(
    $input: CreateConversationMsgInput!
    $condition: ModelConversationMsgConditionInput
  ) {
    createConversationMsg(input: $input, condition: $condition) {
      id
      cid
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateConversationMsg = /* GraphQL */ `
  mutation UpdateConversationMsg(
    $input: UpdateConversationMsgInput!
    $condition: ModelConversationMsgConditionInput
  ) {
    updateConversationMsg(input: $input, condition: $condition) {
      id
      cid
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteConversationMsg = /* GraphQL */ `
  mutation DeleteConversationMsg(
    $input: DeleteConversationMsgInput!
    $condition: ModelConversationMsgConditionInput
  ) {
    deleteConversationMsg(input: $input, condition: $condition) {
      id
      cid
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createUserDisableReason = /* GraphQL */ `
  mutation CreateUserDisableReason(
    $input: CreateUserDisableReasonInput!
    $condition: ModelUserDisableReasonConditionInput
  ) {
    createUserDisableReason(input: $input, condition: $condition) {
      id
      owner
      email
      reason
      comments
      rating
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateUserDisableReason = /* GraphQL */ `
  mutation UpdateUserDisableReason(
    $input: UpdateUserDisableReasonInput!
    $condition: ModelUserDisableReasonConditionInput
  ) {
    updateUserDisableReason(input: $input, condition: $condition) {
      id
      owner
      email
      reason
      comments
      rating
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserDisableReason = /* GraphQL */ `
  mutation DeleteUserDisableReason(
    $input: DeleteUserDisableReasonInput!
    $condition: ModelUserDisableReasonConditionInput
  ) {
    deleteUserDisableReason(input: $input, condition: $condition) {
      id
      owner
      email
      reason
      comments
      rating
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createConversation = /* GraphQL */ `
  mutation CreateConversation(
    $input: CreateConversationInput!
    $condition: ModelConversationConditionInput
  ) {
    createConversation(input: $input, condition: $condition) {
      id
      user1
      user2
      participants
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      messages {
        items {
          id
          conversationID
          participants
          sender
          message
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updateConversation = /* GraphQL */ `
  mutation UpdateConversation(
    $input: UpdateConversationInput!
    $condition: ModelConversationConditionInput
  ) {
    updateConversation(input: $input, condition: $condition) {
      id
      user1
      user2
      participants
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      messages {
        items {
          id
          conversationID
          participants
          sender
          message
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteConversation = /* GraphQL */ `
  mutation DeleteConversation(
    $input: DeleteConversationInput!
    $condition: ModelConversationConditionInput
  ) {
    deleteConversation(input: $input, condition: $condition) {
      id
      user1
      user2
      participants
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      messages {
        items {
          id
          conversationID
          participants
          sender
          message
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      conversationID
      participants
      sender
      message
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      conversationID
      participants
      sender
      message
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      conversationID
      participants
      sender
      message
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      active
      reviewed
      owner
      name
      email
      phone
      city
      picture
      description
      accountType
      candidate {
        candidateTypes
        yearsExperience
        workCategories
        availability
        salary
        salaryCurrency
        desiredSalary
        desiredCurrency
        countries
        cities
        eligibility {
          country
          value
        }
        workType
        openToRemote
        jobSeekingStatus
        programmingLanguages
        createdAt
        updatedAt
      }
      employer {
        companyName
        companySize
        workCategories
        workType
        countries
        description
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      active
      reviewed
      owner
      name
      email
      phone
      city
      picture
      description
      accountType
      candidate {
        candidateTypes
        yearsExperience
        workCategories
        availability
        salary
        salaryCurrency
        desiredSalary
        desiredCurrency
        countries
        cities
        eligibility {
          country
          value
        }
        workType
        openToRemote
        jobSeekingStatus
        programmingLanguages
        createdAt
        updatedAt
      }
      employer {
        companyName
        companySize
        workCategories
        workType
        countries
        description
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      active
      reviewed
      owner
      name
      email
      phone
      city
      picture
      description
      accountType
      candidate {
        candidateTypes
        yearsExperience
        workCategories
        availability
        salary
        salaryCurrency
        desiredSalary
        desiredCurrency
        countries
        cities
        eligibility {
          country
          value
        }
        workType
        openToRemote
        jobSeekingStatus
        programmingLanguages
        createdAt
        updatedAt
      }
      employer {
        companyName
        companySize
        workCategories
        workType
        countries
        description
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createBlogPost = /* GraphQL */ `
  mutation CreateBlogPost(
    $input: CreateBlogPostInput!
    $condition: ModelBlogPostConditionInput
  ) {
    createBlogPost(input: $input, condition: $condition) {
      id
      owner
      title
      slug
      content
      imageUrl
      authorName
      preview
      hidden
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateBlogPost = /* GraphQL */ `
  mutation UpdateBlogPost(
    $input: UpdateBlogPostInput!
    $condition: ModelBlogPostConditionInput
  ) {
    updateBlogPost(input: $input, condition: $condition) {
      id
      owner
      title
      slug
      content
      imageUrl
      authorName
      preview
      hidden
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteBlogPost = /* GraphQL */ `
  mutation DeleteBlogPost(
    $input: DeleteBlogPostInput!
    $condition: ModelBlogPostConditionInput
  ) {
    deleteBlogPost(input: $input, condition: $condition) {
      id
      owner
      title
      slug
      content
      imageUrl
      authorName
      preview
      hidden
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
