import { Col, Row } from 'reactstrap';
import { OrbitSpinner } from '../../components/loader/Loaders';
import React from 'react';

export const LoadingProfileLanding = () => {
  return (
      <Row>
        <Col className="mx-auto text-center my-5" md="8">
          <OrbitSpinner />
          <h2 className="title mt-5">
            <span
                className="text-default">
              Loading your profile
            </span>
          </h2>
        </Col>
      </Row>
  )
}
