import React, { useContext, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Alert, Box, Button, CircularProgress,
  Container,
  FormControl, Input, InputAdornment, MenuItem, Paper, Select,
  Snackbar, Tab, Tabs,
} from '@mui/material';
import bgImg from '../../assets/img/ill/bg_contactus3.svg';
import { Col, Row } from 'reactstrap';
import RedirectButton from '../../components/buttons/RedirectButton';
import AppContext from '../../contexts/AppContext';
import { API } from 'aws-amplify';
import { companySizes } from '../../constants';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import { AmplifyPasswordReset } from '../../components/amplify/AmplifyPasswordReset';
import { TabPanel } from '../../components/TabPanel';

const styles = makeStyles({
  container: {
    minHeight: '85vh',
    minWidth: '95vw',
    position: 'relative',
    '&::after': {
      content: '""',
      maxHeight: '100vh',
      maxWidth: '100vw',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      position: 'absolute',
      backgroundImage: `url(${bgImg})`,
      backgroundSize: 'cover',
      backgroundPosition: 'top center',
      opacity: 0.4,
      zIndex: -1,
    },
  },
  main: {
    display: 'grid',
  },
  radiusSm: {
    borderRadius: '10px',
  },
});

export const EmployerEdit = () => {
  const classes = styles();
  const {
    user: [usr, setUsr],
    saving: [isSaving, setIsSaving] } = useContext(AppContext);
  const { employer } = { ...usr };
  const [phone, setPhone] = useState(usr.phone);
  const [email] = useState(usr.email);
  const [name, setName] = useState(usr.name);
  const [companyName, setCompanyName] = useState(employer?.companyName);
  const [companySize, setCompanySize] = useState(employer?.companySize);
  const [showToast, setShowToast] = useState(false);
  const [savingErr, setSavingErr] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const tabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const saveUser = async () => {
    const saveEmployer = async () => {
      setIsSaving(true);
      const newUsr = {
        id: usr.id,
        name: usr.name,
        phone: usr.phone,
        _version: usr._version,
        employer: {
          companyName,
          companySize
        }
      };
      await API.graphql(
      { query: mutations.updateUser, variables: {
          input: newUsr,
          condition: null,
        }}
      );
      const { data: { getUser }} = await API.graphql(
          { query: queries.getUser, variables: { id: usr.id }}
      );
      setUsr(getUser);
    };
    try {
      setIsSaving(true);
      setSavingErr(false);
      await saveEmployer();
    } catch (err) {
      console.error(err);
      setSavingErr(err);
    } finally {
      setShowToast(true);
      setIsSaving(false);
    }
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowToast(false);
  };
  const setUserPhone = (value) => {
    setPhone(value);
    usr.phone = value;
    setUsr(usr);
  }
  const setUsersName = (value) => {
    setName(value);
    usr.name = value;
    setUsr(usr);
  }
  const setCompany = (value) => {
    setCompanyName(value)
  }
  const companySizeSelected = ({ target: { value }}) => {
    setCompanySize(value);
  }
  return (
      <div className={classes.container}>
        <div className={classes.main}>
          <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={showToast} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose}
                   severity={savingErr ? 'error' : 'success'}
                   sx={{ width: '100%' }}>
              {savingErr ?
                  'Failed to save updates' :
                  'Your profile has been saved'
              }
            </Alert>
          </Snackbar>
          <Container>
            <Paper>
              <Tabs
                  className="mt-2"
                  value={tabIndex} onChange={tabChange} aria-label="basic tabs example">
                <Tab label="Profile"  />
                <Tab label="Settings" />
              </Tabs>
              <TabPanel tabIndex={tabIndex} index={0} sx={{ p: 2, pl: 4 }}>
                <Box className="position-relative">
                  {isSaving ?
                      <Row>
                        <Col className="mx-auto text-center mt-5" md="8">
                          <CircularProgress />
                          <h2 className="title mt-5">
                          <span
                              className="text-default">
                            Saving your profile
                          </span>
                          </h2>
                        </Col>
                      </Row>
                      :
                      <>
                        <Row>
                          <Col xs={9} md={9}>
                            <div
                                className="h-100 w-100 d-flex flex-column justify-content-center">
                              <h2 className="pt-xs-2 text-default">
                                Edit your profile
                              </h2>
                            </div>
                          </Col>
                        </Row>
                        <Row className="pt-2">
                          <Col xs={12} md={6}>
                            <b>
                              Preferred Name
                            </b>
                            <FormControl variant="standard" className="w-100">
                              <Input
                                  id="input-name"
                                  value={name}
                                  placeholder="Name"
                                  onChange={(e) => setUsersName(e.target.value)}
                              />
                            </FormControl>
                          </Col>
                        </Row>
                        <Row className="pt-2">
                          <Col xs={12} md={6}>
                            <b>
                              Contact number
                            </b>
                            <FormControl variant="standard" className="w-100">
                              <Input
                                  id="input-phonenr"
                                  value={phone}
                                  type="tel"
                                  placeholder="Contact number"
                                  onChange={(e) => setUserPhone(e.target.value)}
                                  startAdornment={
                                    <InputAdornment position="start">
                                      📱
                                    </InputAdornment>
                                  }
                              />
                            </FormControl>
                          </Col>
                        </Row>
                        <Row className="pt-2">
                          <Col xs={12} md={6}>
                            <b>
                              Email address
                            </b>
                            <FormControl variant="standard" className="w-100">
                              <Input
                                  id="input-email"
                                  disabled
                                  value={email}
                                  placeholder="Email Address"
                                  type="email"
                                  startAdornment={
                                    <InputAdornment position="start">
                                      ✉
                                    </InputAdornment>
                                  }
                              />
                            </FormControl>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12}>
                            <h4 className="pt-4"> Company Details </h4>
                          </Col>
                        </Row>
                        <Row >
                          <Col xs={12} md={6}>
                            <b>
                              Company name
                            </b>
                            <FormControl variant="standard" className="w-100">
                              <Input
                                  id="input-companyname"
                                  value={companyName}
                                  type="tel"
                                  placeholder="Company name"
                                  onChange={(e) => setCompany(e.target.value)}
                              />
                            </FormControl>
                          </Col>
                        </Row>
                        <Row className="pt-2">
                          <Col xs={12} md={6}>
                            <b>
                              Number of employees
                            </b>
                            <FormControl className="w-100">
                              <Select
                                  variant="standard"
                                  value={companySize || null}
                                  onChange={(e) => companySizeSelected(e)}
                              >
                                {companySizes.map(({text, value}, idx) => {
                                  return (
                                      <MenuItem key={idx} value={value}>
                                        {text}
                                      </MenuItem>
                                  )
                                })}
                              </Select>
                            </FormControl>
                          </Col>
                        </Row>
                      </>
                  }
                  <div className="mt-auto pt-5">
                    <Row>
                      <Col xs={12} className="d-flex">
                        <div>
                          <RedirectButton buttonText={'Cancel'}
                                          color={'primary'}
                                          variant="outlined"
                                          redirectTo={'/'}/>
                        </div>
                        <div className="flex-row ml-auto">
                          <Button variant="contained"
                              color="primary"
                                  onClick={() => saveUser()}>
                            Save
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Box>
              </TabPanel>
              <TabPanel tabIndex={tabIndex} index={1} sx={{ p: 4 }}>
                <Box className="position-relative">
                  <div className="mb-2">
                    <b>
                      Password Reset
                    </b>
                  </div>
                  <AmplifyPasswordReset />
                </Box>
              </TabPanel>
            </Paper>
          </Container>
        </div>
      </div>
  )
}
