import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import Headroom from "headroom.js";
import {
  Collapse,
  NavbarBrand,
  Navbar,
  Nav,
  Row,
  Col,
} from "reactstrap";


import whiteSvg from 'assets/img/logo-white.svg';
import AppContext from '../../contexts/AppContext';
import { isMobile } from "react-device-detect";
import { getDevEnv, isDevEnvironment } from '../../utils/EnvTools';
import { NavTabsDesktop } from './desktop/NavTabsDesktop';
import { NavbarDropdownDesktop } from './desktop/NavbarDropdownDesktop';
import { NavOptionsMobile } from './mobile/NavOptionsMobile';
import { NavbarDropdownMobile } from './mobile/NavbarDropdownMobile';
import { Container } from '@mui/material';

export const TopNavbar = () => {
  const [showNav, setShowNav] = useState(true);
  const [navbarMain] = useState(document.getElementById("dark-navbar-main"));
  const [collapseOpen, toggleCollapse] = useState(false);
  const { auth: [authenticated], loading: [isLoading], navTab: [, setActiveTab] } = useContext(AppContext);
  const history = useHistory();

  const shouldHide = (pathname) => {
    const isLogout = pathname === '/logout';
    const isSigningIn = pathname.startsWith('/signin');
    const isSSOLogin = pathname.startsWith('/login') && isLoading;
    return !(isLogout || isSigningIn || isSSOLogin)
  }

  useEffect(() => {
    setShowNav(shouldHide(history.location.pathname));
    const listener = history.listen((e) => {
      const { pathname } = e;
      setShowNav(shouldHide(pathname));
    })
    if (navbarMain) {
      let headroom = new Headroom(navbarMain);
      // initialise
      headroom.init();
    }
    return () => {
      listener();
    };
  }, [authenticated, navbarMain]);
  let navbarType = "bg-default navbar-dark";
  if (!showNav) {
    return <></>
  }

  const { innerWidth: width } = window;
  const smallMenu = isMobile || (width <= 576);

  return (
        <>
          <Navbar
              className={"border-bottom py-3 navbar-main headroom " + navbarType}
              expand="lg"
              id="dark-navbar-main"
          >
            <Container className="d-flex flex-row">
              <div className="ml-4 d-flex align-items-center">
                <NavbarBrand className="align-items-lg-center ml-lg-auto mr-lg-5" to="/" tag={Link}
                             onClick={() => setActiveTab(0)}>
                  <img
                      onClick={() => setActiveTab(0)}
                      className="pr-3" alt="..." src={whiteSvg}
                  ></img>
                  Upward Spiral
                  {isDevEnvironment && <>{` - ${getDevEnv}`}</>}
                </NavbarBrand>
              </div>
              <button
                  className="ml-auto navbar-toggler"
                  type="button"
                  onClick={() => toggleCollapse(!collapseOpen)}
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <Collapse
                  id="navbar_global"
                  navbar
                  className="on-toppest"
                  toggler="#navbar_global"
                  isOpen={collapseOpen}
              >
                <div className="navbar-collapse-header">
                  <Row>
                    <Col className="collapse-brand" xs="6">
                      <Link to="/index">
                      </Link>
                    </Col>
                    <Col className="collapse-close" xs="6">
                      <button
                          className="navbar-toggler"
                          onClick={() => toggleCollapse(!collapseOpen)}
                      >
                        <span></span>
                        <span></span>
                      </button>
                    </Col>
                  </Row>
                </div>
                <Nav
                    className="on-toppest navbar-nav-hover align-items-lg-center ml-lg-auto"
                    navbar
                >
                  {smallMenu && !authenticated && <NavOptionsMobile callback={() => toggleCollapse(!collapseOpen)} />}
                  {smallMenu && authenticated && <NavbarDropdownMobile
                      onClick={() => {
                        toggleCollapse(!collapseOpen)
                      }} />}
                  {!smallMenu && !authenticated && <NavTabsDesktop />}
                  {!smallMenu && authenticated && <NavbarDropdownDesktop />}
                </Nav>
              </Collapse>
            </Container>
          </Navbar>
        </>
  );
}

TopNavbar.defaultProps = {
  type: "dark",
};
TopNavbar.propTypes = {
  type: PropTypes.oneOf(["dark", "transparent", "primary", "white"]),
};

export default TopNavbar;
