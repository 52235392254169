import React from 'react';
import { Col, Row } from 'reactstrap';
import RedirectButton from '../../components/buttons/RedirectButton';
import { Button, Tooltip } from '@mui/material';

export const OnboardingFooter = ({ hideCancel, back, next, nextDisabled }) => {

  const _disabled = nextDisabled ?
      typeof nextDisabled === 'function' ? nextDisabled() : nextDisabled
      : false;

  return (
      <>
        <div className="mt-auto py-5 border-top">
          <Row>
            <Col xs={12} className="d-flex">
              {!hideCancel && <div>
                <RedirectButton buttonText={'Cancel'}
                                color={'primary'}
                                aria-label="cancel-button"
                                variant="outlined"
                                redirectTo={'/'} />
              </div>}
              <div className="flex-row ml-auto">
                {back && <Button className="mr-2"
                                 aria-label="back-button"
                                 color="primary" onClick={() => back()}>
                  Back
                </Button>
                }
                <Tooltip title={_disabled ? 'All fields are required' : 'All fields are required'}>
                  <>
                    <Button
                        variant="contained"
                        aria-label="next-button"
                        disabled={_disabled}
                        color="primary" onClick={() => next()}>
                      Next
                    </Button>
                  </>
                </Tooltip>
              </div>
            </Col>
          </Row>
        </div>
      </>
  )
};
