import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'normalize.css';
import "assets/css/nucleo-svg.css";
import "assets/css/nucleo-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-design-system.scss?v1.0.0";

import Amplify, { Analytics } from 'aws-amplify';
import { getAwsConfig } from './utils/ConfigUtils';

import { ThemeProvider, createTheme } from '@mui/material/styles';
const theme = createTheme({
  palette: {
    primary: {
      main: '#172b4d'
    },
    white: {
      main: 'white',
    },
    grey: {
      main: 'grey',
    }
  }
});

Analytics.disable();
// .env or amplify.yml
const useAnalytics = process.env.REACT_APP_USE_ANALYTICS === 'true';
if (useAnalytics) {
 Analytics.enable();
}

Amplify.configure(getAwsConfig());
ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
