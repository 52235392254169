/*eslint-disable*/
import React from "react";
import { Container } from '@mui/material';
import {
  Row,
  Col,
  NavLink,
} from "reactstrap";

export const CommonFooter = () => {
  return (
    <>
      <footer className="footer border-top mt-5">
        <Container>
          <Row className="align-items-center justify-content-md-between">
            <Col md="6">
              <div className="copyright">
                © {new Date().getFullYear() + ' '}
                  Upward Spiral by Lucid Software.
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="9">
              <div className="column">
                <nav>
                  <ul>
                    <li className="d-inline-block">
                      <NavLink
                          className="pl-0"
                          href="/terms"
                          target="_blank"
                      >
                        <div className="font-weight-light">
                          Terms &amp; Conditions
                        </div>
                      </NavLink>
                    </li>
                    <li className="d-inline-block">
                      <NavLink
                          href="/privacy"
                          target="_blank"
                      >
                        <div className="font-weight-light">
                          Privacy Policy
                        </div>
                      </NavLink>
                    </li>
                    <li className="d-inline-block">
                      <NavLink
                          href="/code_of_conduct"
                          target="_blank"
                      >
                        <div className="font-weight-light">
                          Code of Conduct
                        </div>
                      </NavLink>
                    </li>
                  </ul>
                </nav>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}
