import React, {
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { makeStyles } from '@mui/styles';
import bgImg from '../assets/img/ill/pattern_pricing5.svg';
import clsx from 'clsx';
import {
  Card,
  FormControl,
  InputAdornment,
  Input,
  Button,
  Container,
  Avatar,
  CircularProgress,
} from '@mui/material';
import { API, DataStore } from 'aws-amplify';
import {
  AccountType,
  ConversationMsg,
  User
} from '../models';

import {
  CardBody,
  CardFooter,
  Col,
  ListGroup,
  ListGroupItem,
  Media,
  Row,
} from 'reactstrap';
import AppContext from '../contexts/AppContext';
import { ErrLoading, LoaderInRow } from '../components/loader/Loaders';
import { CandidatePreview } from '../components/preview/CandidatePreview';
import { EmployerPreview } from '../components/preview/EmployerPreview';
import { listConversationsNoMsg } from '../api/customQueries';
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';

const styles = makeStyles({
  outer: {
    maxHeight: '80vh',
    minWidth: '95vw',
    position: 'relative',
    '&::after': {
      content: '""',
      maxHeight: '100vh',
      maxWidth: '100vw',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      position: 'absolute',
      backgroundImage: `url(${bgImg})`,
      backgroundPosition: 'top center',
      opacity: 0.4,
      zIndex: -1,
    },
  },
  main: {
    display: 'grid',
  },
  cotainerHeight: {
    maxHeight: '85vh',
    height: '85vh',
    overflowY: 'auto',
  },
  heightMax: {
    maxHeight: '75vh',
    height: '75vh',
    overflow: 'scroll',
    overflowX: 'hidden',
    position: 'relative',
  }
});
export const ChatPage = () => {
  const classes = styles();
  const {
    auth: [authenticated],
    user: [usr],
    msgContext: [msgCtx, setMsgContext]
  } = useContext(AppContext);
  const [users, setUsers] = useState([]);
  const [currentConversation, setCurrentConversation] = useState();
  const [conversations, setConversations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errLoading, setErrLoading] = useState();
  const [messageFocus, setMessageFocus] = React.useState('');
  const [sendingMessage, setSendingMessage] = useState(false);

  const [values, setValues] = useState({
    messageInput: '',
  });
  const messagesDiv = useRef();

  const conversationSelected = (conversation) => {
    if (conversation.id !== currentConversation?.id) {
      setCurrentConversation(conversation);
      setTimeout(() => {
        messagesDiv?.current?.scrollIntoView({behavior: "auto"});
      }, 50);
    }
  };

  useEffect(() => {
    const someSubscriber = DataStore
      .observe(ConversationMsg)
      .subscribe(async (msg) => {
        const { element } = msg;
        const match = conversations.findIndex((conv) => conv.id === element.cid);
        if (match >= 0) {
          const { data: { listMessages: { items } } } = await API.graphql({
            query: queries.listMessages,
            variables: {
              filter: {
                conversationID: { eq: conversations[match].id }
              }
            }
          })
          const filtered = items?.filter((item) => !item._deleted);
          const replacement = {
            ...conversations[match],
            messages: filtered.sort((a, b) => new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime()),
          };
          conversations[match] = replacement;
          setConversations(sortConversations(conversations));
          // Scroll to bottom if its the current conversation
          if (element.cid === currentConversation?.id) {
            setCurrentConversation(replacement);
            setSendingMessage(false);
            setTimeout(() => {
              messagesDiv?.current?.scrollIntoView({behavior: "smooth"});
            }, 50);
          }
        }
      })
    return () => {
      someSubscriber.unsubscribe();
    }
  }, [conversations, currentConversation]);

  useEffect(() => {
    const getConversations = async () => {
      const { data: { listConversations: { items }}} = await API.graphql({
        query: listConversationsNoMsg,
        variables: {
          filter: {
            or: [
              { user1: { 'eq': usr.id }},
              { user2: { 'eq': usr.id }},
            ],
          }
        }
      });
      return await Promise.all(
          items
          .filter((item) => !item._deleted)
          .map(async (conversation) => {
        const { data: { listMessages: { items } } } = await API.graphql({
          query: queries.listMessages,
          variables: {
            filter: {
              conversationID: { eq: conversation.id }
            }
          }
        })
        const sorted = items
          .sort((a, b) => new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime())
        return { ...conversation, messages: sorted };
      }));
    }
    getConversations()
    .then(async (cvs) => {
      const allUsers = [];
      const result = [];
      const map = new Map();
      for (const item of cvs) {
        if (!map.has(item.id)) {
          map.set(item.id, true);
          result.push(item);
        }
      }
      for (const conv of result) {
        if (!allUsers.find((user) => conv.user1 === user?.id)) {
          const u1 = await DataStore.query(User, conv.user1);
          if (u1) {
            allUsers.push(u1);
          }
        }
        if (!allUsers.find((user) => conv.user2 === user?.id)) {
          const u2 = await DataStore.query(User, conv.user2);
          if (u2) {
            allUsers.push(u2);
          }
        }
      }
      if (allUsers.length && users.length !== allUsers.length) {
        setUsers(allUsers);
      }
      if (result.length > 0) {
        if (conversations.length !== cvs.length) {
          setConversations(sortConversations(result));
        }
        if (msgCtx) {
          conversationSelected(
              cvs.find(({user1, user2}) => msgCtx === user1 || msgCtx === user2)
              || result[0]);
        } else if (!currentConversation) {
          conversationSelected(result[0]);
        }
      }
      if (loading) {
        setLoading(false);
      }
    })
    .catch((err) => {
      console.log(err);
      setErrLoading(err);
    }).finally(() => {
      if (msgCtx) {
        setMsgContext();
      }
    });
  }, []);

  const sortConversations = (convos) => {
    if (!convos || convos.length === 0) {
      return convos;
    }
    return convos.sort((a, b) => {
      return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
    });
  }

  const handleChange = (prop) => (event) => {
    setValues({...values, [prop]: event.target.value});
  };

  const getUsersFromConversation = (conversation) => {
    const me = users.find((user) => user.id === usr.id);
    if (!me || !conversation) {
      return;
    }
    if (me.id === conversation.user2) {
      return {
        me,
        other: users.find(({id}) => id === conversation.user1)
      };
    }
    return {
      me,
      other: users.find((user) => user.id === conversation.user2)
    };
  }

  const saveNewMessage = async (message) => {
    // Save the message
    await API.graphql({
      query: mutations.createMessage,
      variables: {
        condition: null,
        input: {
          conversationID: currentConversation.id,
          participants: currentConversation.participants,
          sender: authenticated.username,
          message,
        }
      }
    });
    // Update the conversation
    await API.graphql({
      query: mutations.updateConversation,
      variables: {
        conditions: null,
        input: {
          id: currentConversation.id,
          updatedAt: new Date().toISOString(),
        }
      }
    });
    await DataStore.save(new ConversationMsg({
      cid: currentConversation.id,
    }));
  };

  const isMyMessage = (message) => message?.sender === authenticated?.username;
  const getMessageDateString = (message) => {
    const createdAt = new Date(message.createdAt);
    const dateStr = createdAt.toLocaleDateString('en-AU');
    const timeStr = `${createdAt.toTimeString().substr(0, 8)}`
    return `${timeStr} ${dateStr}`;
  }

  const sendMessage = () => {
    if (!values.messageInput) {
      return;
    }
    if (!sendingMessage) {
      setSendingMessage(true);
    }
    saveNewMessage(values.messageInput).then(() => {
      setValues({
        ...values,
        messageInput: '',
      });
      messagesDiv?.current?.scrollIntoView({behavior: "smooth"});
    });
    setTimeout(async () => {
      if (sendingMessage) {
        setSendingMessage(false);
      }
    }, 2500);
  }
  const getInfoOfOtherUser = () => {
    const users = getUsersFromConversation(currentConversation);
    if (users) {
      const {other} = users;
      if (!other) {
        return <></>
      }
      if (other.accountType === AccountType.EMPLOYER) {
        return <EmployerPreview user={other} />
      } else if (other.accountType === AccountType.CANDIDATE) {
        return <CandidatePreview user={other} />;
      } else {
        return (
            <div>
              {other?.name}
            </div>
        )
      }
    }
    return <></>;
  }

  const renderMessage = (message, idx) => {
    if (!message) {
      return;
    }
    const isMine = isMyMessage(message);
    return (
        <Row key={`message-${idx}`} className={clsx("my-2",
            isMine ? 'justify-content-start'
                : 'justify-content-end text-right')}>
          <Col className="col-auto">
            <Card className={isMine ? "bg-gradient-secondary"
                : "bg-gradient-primary text-white"}
              >
              <CardBody className="p-2">
                <p className="mb-1">
                  {message.message}
                </p>
                <div>
                  <small className="opacity-60">
                    {getMessageDateString(message)}
                  </small>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
    )
  }

  return (
      <div className={classes.outer}>
        <div className={classes.main}>
          <Container maxWidth={'xl'}>
            <Card className={clsx('mt-lg-3')}>
              {loading ?
                  <LoaderInRow message="Loading messages"/>
                  :
                  errLoading ?
                      <ErrLoading/>
                      :
                      <Row className="flex-row chat">
                        <Col md={2} xs={6} className="pr-0">
                          {conversations.length > 0 ?
                              <ListGroup className={"list-group-chat"}
                                         style={{
                                           maxHeight: '85vh',
                                           overflowY: 'auto'
                                         }}
                                         flush
                                         tag="div">
                                {conversations.map((conversation) => {
                                  return (
                                      <ListGroupItem
                                          key={conversation.id}
                                          style={{
                                            minHeight: '85px',
                                            borderRadius: '0.3em'
                                          }}
                                          className={clsx(
                                              currentConversation
                                              && conversation
                                              && currentConversation.id
                                              === conversation.id
                                                  ? "active bg-gradient-primary"
                                                  : "bg-gradient-secondary",
                                              "mb-1 clickable no-select overflow-hidden")}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            conversationSelected(conversation);
                                          }}
                                          tag="a"
                                      >
                                        <Media>
                                          <Avatar aria-label="user-picture"
                                                  src={getUsersFromConversation(
                                                      conversation)?.other?.picture}>
                                          </Avatar>
                                          <Media body className="ml-2">
                                            <div
                                                className="justify-content-between align-items-center">
                                              <h6 className={currentConversation
                                              && conversation
                                              && currentConversation.id
                                              === conversation.id
                                                  ? "text-white mb-0"
                                                  : "mb-0"}>
                                                {getUsersFromConversation(
                                                    conversation)?.other?.name}
                                              </h6>
                                            </div>
                                          </Media>
                                        </Media>
                                      </ListGroupItem>
                                  )
                                })}
                              </ListGroup>
                              :
                              <></>
                          }
                        </Col>
                        <Col md={7} xs={12}  className="pl-0">
                          <Card>
                            <CardBody className={classes.heightMax}>
                              {!!currentConversation
                                  ? currentConversation.messages?.length > 0 ?
                                      <>
                                        {currentConversation.messages?.map(
                                            (msg, i) => renderMessage(msg, i))}
                                        {sendingMessage &&
                                        <Row key={`message-loader`}
                                             className="my-2 justify-content-start">
                                          <Col className="col-auto">
                                            <Card
                                                className="bg-gradient-secondary">
                                              <CardBody className="py-3 mx-4">
                                                <CircularProgress />
                                              </CardBody>
                                            </Card>
                                          </Col>
                                        </Row>
                                        }
                                      </>
                                      :
                                      <>
                                        <h5>
                                          No messages in this conversation
                                        </h5>
                                      </>
                                  :
                                  <>
                                    <h5>
                                      You currently have no active conversations
                                    </h5>
                                  </>
                              }
                              <div id={"messagesDiv"} ref={messagesDiv}></div>
                            </CardBody>
                            <CardFooter className="d-block">
                              <FormControl className={messageFocus}
                                           sx={{m: 1, width: '100%'}}
                                           variant="standard">
                                <Input
                                    placeholder="Type a message"
                                    disabled={!currentConversation}
                                    value={values.messageInput}
                                    onFocus={() => {
                                      setMessageFocus('focused')
                                    }
                                    }
                                    onBlur={() => setMessageFocus('')}
                                    onKeyPress={(e) => {
                                      if (!e.shiftKey && e.key === 'Enter') {
                                        sendMessage();
                                      }
                                    }}
                                    onChange={handleChange('messageInput')}
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <Button onClick={sendMessage}
                                                disabled={
                                                  !currentConversation
                                                  || values.messageInput === ''}
                                        >
                                          <i className="pr-3 ni ni-send"
                                          ></i>
                                          Send
                                        </Button>

                                      </InputAdornment>}
                                    aria-describedby="outlined-weight-helper-text"
                                    inputProps={{
                                      'aria-label': 'weight',
                                    }}
                                />
                              </FormControl>
                            </CardFooter>
                          </Card>
                        </Col>
                        <Col md={3} xs={12} className="p-2 pr-3">
                          <div style={{
                              paddingRight: '1em',
                              maxHeight: '85vh',
                              overflowY: 'auto',
                              overflowX: 'hidden',
                              }}>
                            {getInfoOfOtherUser()}
                          </div>
                        </Col>
                      </Row>
              }
            </Card>
          </Container>
        </div>
      </div>
  );
}
