//
import { Alert } from 'reactstrap';
import { getAuthErrMessage } from './ErrUtils';

export const getLoginErrorAlert = ({ code, message }) => {
  return (
      <Alert color="danger" fade={true} >
        <span className="alert-inner--icon">
          <i className="fa fa-exclamation-circle"></i>
        </span>
        <span className="alert-inner--text">
          {getAuthErrMessage(code, message)}
        </span>
      </Alert>
  );
}

export const getErrMsgAlert = (message) => {
  return (
    <Alert color="danger" fade={true} >
      <span className="alert-inner--text">
        {message}
      </span>
    </Alert>
    )
}

export const getSuccessAlert = (message) => {
  return (
      <Alert color="success" fade={true}>
        <span className="alert-inner--text">
          {message}
        </span>
      </Alert>
  );
}

export const getVerifiedAlert = (msg) => {
  return (
      <Alert color="info" fade={true}>
        <span className="alert-inner--icon">
          <i className="fa fa-exclamation-circle"></i>
        </span>
        <span className="alert-inner--text">
          {msg}
        </span>
      </Alert>
  );
}
