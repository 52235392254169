export const userByEmail = /* GraphQL */ `
    query UserByEmail(
        $email: String
    ) {
        userByEmail(
            email: $email
        ) {
            items {
                id
                active
                owner
                name
                email
                reviewed
                phone
                picture
                accountType
                candidate {
                    yearsExperience
                    workCategories
                    availability
                    salary
                    desiredSalary
                    countries
                    workType
                    jobSeekingStatus
                    programmingLanguages
                    createdAt
                    updatedAt
                }
                employer {
                    companyName
                    createdAt
                    updatedAt
                }
                createdAt
                updatedAt
                _deleted
                _lastChangedAt
            }
        }
    }
`;

export const listCandidates = /* GraphQL */ `
    query ListCandidates(
        $filter: ModelUserFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                name
                picture
                description
                candidate {
                    yearsExperience
                    workCategories
                    availability
                    desiredSalary
                    desiredCurrency
                    countries
                    workType
                    jobSeekingStatus
                    programmingLanguages
                    createdAt
                    updatedAt
                }
                createdAt
                updatedAt
            }
            nextToken
            startedAt
        }
    }
`;

export const listConversationsNoMsg = /* GraphQL */ `
    query ListConversations(
        $filter: ModelConversationFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listConversations(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                user1
                user2
                participants
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
            }
            nextToken
            startedAt
        }
    }
`;
