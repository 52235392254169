import React, { useContext, useState } from 'react';
import { Col, Row } from 'reactstrap';
import AppContext from '../../contexts/AppContext';
import { OnboardingHeader } from '../onboarding/OnboardingHeader';
import { OnboardingFooter } from '../onboarding/OnboardingFooter';
import { Checkbox, FormControlLabel, FormGroup, } from '@mui/material';
import { availableWorkTypes } from '../../constants/Availability';

export const CandidateHowStep = ({ back, next }) => {
  const { onBoarding: [onBoardingValue, setOnboardingValue] } = useContext(AppContext);
  onBoardingValue.workType.forEach((workType) => {
    availableWorkTypes.find(({ type }) => type === workType).selected = true
  })
  const [workTypesSelected, setWorkTypesSelected] = useState(availableWorkTypes);

  const [openToRemote, setOpenToRemote] = useState(onBoardingValue.openToRemote || false);

  const disableNext = () => {
    const hasWorkType = onBoardingValue.workType.length > 0;
    const hasCountry = onBoardingValue.countries.length > 0;
    return !hasWorkType || !hasCountry;
  }

  const setUserOpenToRemote = () => {
    const isOpen = !openToRemote;
    setOpenToRemote(isOpen);
    onBoardingValue.openToRemote = isOpen;
    setOnboardingValue(onBoardingValue);
  }

  const toggleSelection = (workType) => {
    const newSelected = [
      ...workTypesSelected,
    ];
    const match = newSelected.find(({ type }) => workType === type);
    match.selected = !match.selected;
    setWorkTypesSelected(newSelected);
    onBoardingValue.workType = newSelected
    .filter(({ selected }) => selected)
      .map(({ type }) => type);
    setOnboardingValue(onBoardingValue);
  }

  return (
      <>
        <div className="px-4">
          <OnboardingHeader
              header={`We get you!`}
              subHeader="How do you like to work?" />
          <div className="mt-5 py-5 border-top">
            <Row>
              <Col xs={12} className="mb-2">
                <div className="mb-1">
                  <b>
                    Are you open to remote work?
                  </b>
                </div>
                <FormGroup>
                  <FormControlLabel
                      control={
                        <Checkbox
                            value={openToRemote}
                            checked={openToRemote}
                            onChange={() => setUserOpenToRemote()}
                        />
                      }
                      label={'Open to remote work'} />
                </FormGroup>
              </Col>
              <Col xs={12}>
                <div className="mb-1">
                  <b>
                    Which method of work would you like
                  </b>
                </div>
                {availableWorkTypes.map(({ type }, idx) => {
                  return (
                      <Row key={`${type}-${idx}`}>
                        <Col xs={8}>
                          <FormGroup>
                            <FormControlLabel
                                control={
                                  <Checkbox
                                      value={type}
                                      checked={onBoardingValue.workType.some((workType) => workType === type)}
                                      onChange={() => toggleSelection(type)} />
                                }
                                label={type} />
                          </FormGroup>
                        </Col>
                      </Row>
                  )
                })}
              </Col>
            </Row>
          </div>
          <OnboardingFooter
              nextDisabled={disableNext}
              next={next}
              back={back}
          />
        </div>
      </>
  );
}
