import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import bgImg from '../assets/img/ill/pattern_pricing5.svg';
import { Container } from '@mui/material';
import MDEditor from '@uiw/react-md-editor';
import { Col, Row } from 'reactstrap';


import { API } from 'aws-amplify';
import * as queries from '../graphql/queries';
import AppContext from '../contexts/AppContext';
import { useParams } from 'react-router-dom';
import { LoaderInRow } from '../components/loader/Loaders';
import { CommonFooter } from '../components/CommonFooter';

const styles = makeStyles({
  container: {
    minHeight: '85vh',
    minWidth: '95vw',
    position: 'relative',
    '&::after': {
      content: '""',
      maxHeight: '100vh',
      maxWidth: '100vw',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      position: 'absolute',
      backgroundImage: `url(${bgImg})`,
      backgroundSize: 'cover',
      backgroundPosition: 'top center',
      opacity: 0.8,
      zIndex: -1,
    },
  },
  main: {
    display: 'grid',
    paddingTop: '10vh'
  },
});

export const BlogPage = () => {
  const { auth: [authed] } = useContext(AppContext);
  const classes = styles();
  const params = useParams();
  const { blogName } = params;
  const [loading, setLoading] = useState(false);
  const [blogPost, setBlogPost] = useState({});

  useEffect(() => {
    const getBlogPost = async () => {
      if (!loading) {
        setLoading(true);
      }
      const { data: { blogBySlug: { items }}} = await API.graphql(
            {
              query: queries.blogBySlug,
              variables: {
                slug: blogName
              },
              authMode: authed ? 'AMAZON_COGNITO_USER_POOLS' : 'API_KEY'
            }
        );
      setBlogPost(items.pop());
    }
    getBlogPost()
      .finally(() => setLoading(false));
  }, []);

  return (
      <div className={classes.container}>
        <div className="page-header page-header-mini header-filter">
          <div
              className="page-header-image"
          ></div>
          <div className="content-center">
            <Row>
              <Col className="mx-auto text-center" md="6">
                {loading ?
                  <>
                    <LoaderInRow />
                  </>
                    :
                <h1 className="title text-white">
                  {blogPost.title}
                </h1>
                }
              </Col>
            </Row>
          </div>
        </div>
        {!loading &&
        <div className={classes.main}>
          <Container>
            <Row>
              <Col className="mx-auto" lg="10" md="8">
                <MDEditor.Markdown
                    components={
                      {
                        'a': props => <a href={props.href}>{props.children}</a>,
                        'span': props => {
                          if (props.className === 'icon icon-link') {
                            return <span></span>
                          }
                          return <span>{props.children}</span>
                        }
                      }
                    }
                    source={blogPost.content} />
                {blogPost.authorName &&
                <div className="pt-5">
                  <span className="blockquote">{blogPost.authorName} {new Date(blogPost.createdAt).toLocaleDateString()}</span>
                </div>
                }
              </Col>
            </Row>
          </Container>
        </div>
        }
        <CommonFooter />
      </div>
  )
}
