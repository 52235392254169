import React, { useContext, useState } from 'react';
import {
  Col,
  Row
} from 'reactstrap';
import AppContext from '../../contexts/AppContext';
import { mustache } from '../../utils/StringUtils';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@mui/material';
import { eligibilityOptions } from '../../constants';
import { OnboardingHeader } from '../onboarding/OnboardingHeader';
import { OnboardingFooter } from '../onboarding/OnboardingFooter';

export const CandidateWorkEligibility = ({ back, next }) => {
  const { onBoarding: [obv, setObv] } = useContext(AppContext);
  const { countries } = obv;
  const [selections, setSelections] = useState(obv.eligibility || []);

  const disableNext = () => {
    const hasEligibility = obv.eligibility.length > 0;
    return !hasEligibility;
  }

  const optionChosen = (e, country) => {
    const value = e.target.value;
    const newList = selections.filter((entry) => entry.country !== country);
    newList.push({ country, value });
    setSelections(newList);
    obv.eligibility = newList;
    setObv(obv);
  };

  const getMatch = (country) => {
    const match = selections.find((entry) => entry.country === country);
    if (match) {
      return match.value;
    }
    return null;
  }

  return (
      <>
        <div className="px-4">
          <OnboardingHeader
              header="Getting there..."
              subHeader="We need to know if we can make this work" />
          <div className="mt-5 py-5 border-top">
            <Row>
              <Col xs={12}>
                <Row>
                  <Col xs={12}>
                    <h5 className="header-5">
                      What is your citizenship status in
                    </h5>
                    <Row>
                    {countries.map((country, idx) => {
                        return (
                            <Col xs={12}
                                 className="w-100" key={`${country}-${idx}`}>
                              <div key={`${country}-${idx}`}>
                                <div className="mb-2">
                                  <b>
                                    {country}
                                  </b>
                                </div>
                                <FormControl component="fieldset" variant="standard">
                                  <RadioGroup
                                      aria-label="gender"
                                      name="controlled-radio-buttons-group"
                                      value={getMatch(country)}
                                  >
                                {eligibilityOptions.map(({ name, value }, idx) => {
                                  return (
                                      <FormControlLabel value={value}
                                                        control={<Radio />}
                                                        onChange={(e) => optionChosen(e, country)}
                                                        label={mustache(name, 'ctry', country)}
                                                        key={`${value}-${idx}`} />
                                  )
                                  })}
                                  </RadioGroup>
                                </FormControl>
                              </div>
                            </Col>
                        )
                      })
                    }
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <OnboardingFooter
              nextDisabled={disableNext}
              next={next}
              back={back}
          />
        </div>
      </>
  );
}
