import React, { useContext, useEffect } from 'react';
import queryString from 'query-string';
import {
  Container,
} from '@mui/material';
import { Col, Row } from 'reactstrap';
import { Hub } from 'aws-amplify';
import { useHistory, useLocation } from 'react-router-dom';
import AppContext from '../contexts/AppContext';
import { OrbitSpinner } from '../components/loader/Loaders';

export const SigningInPage = () => {
  const { auth: [, setAuth], oauthErr: [, setOAuthErr] } = useContext(AppContext);
  const history = useHistory();
  const location = useLocation();

  // {
  //   "error": "invalid_request",
  //     "error_description": "PreSignUp failed with error ;User must sign up first. ",
  //     "state": "SjS5Mq7Yn8NpQMS6eAXBCINqdaLRdnDM"
  // }

  useEffect(() => {
    const query = queryString.parse(location.search);
    if (query.error) {
      setOAuthErr(query.error_description.split(';').pop());
      history.push('/login');
      return;
    }
    const signinListener = Object.freeze(async(event) => {
      switch (event.payload.event) {
        case 'signIn':
          const { data } = event.payload;
          const email = data.signInUserSession.idToken.payload.email;
          setAuth({
            ...data,
            email
          });
          setTimeout(() => history.push('/'), 2000);
          break;
        case 'signIn_failure':
          history.push('/');
          break;
        case 'signOut':
        case 'cognitoHostedUI_failure':
          history.push('/');
          break;
        default:
          return;
      }
    });
    Hub.listen('auth', signinListener, `signinListener`);
    return () => {
      Hub.remove('auth');
    };
  });
  return (
    <>
      <div className="wrapper reset-page">
        <div className="page-header bg-white">
          <Container>
            <Row>
              <Col className="mx-auto text-center my-5" md="8">
                <OrbitSpinner />
                <h2 className="title">
                    <span
                        className="text-default">
                      Waiting for a response
                    </span>
                </h2>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}
