import { Box } from '@mui/material';

export const TabPanel = (props) => {
  const { children, tabIndex, index, sx, ...other } = props;

  return (
      <div
          role="tabpanel"
          hidden={tabIndex !== index}
          id={`full-width-tabpanel-${index}`}
          aria-labelledby={`full-width-tab-${index}`}
          {...other}
      >
        {tabIndex === index && (
            <Box sx={sx}>
              {children}
            </Box>
        )}
      </div>
  );
}
