import React, { useContext, useState } from 'react';
import {
  Col,
  Row,
} from 'reactstrap';
import {
  Autocomplete,
  Checkbox, Chip,
  FormControl,
  FormControlLabel, FormGroup, Input, InputAdornment, MenuItem,
  Radio,
  RadioGroup, Select, TextField, Typography,
} from '@mui/material';

import AppContext from '../../contexts/AppContext';
import {
  jobSeekingStatusConstant,
  noticeOptions
} from '../../constants';
import {
  availableCities,
  availableCountries
} from '../../constants/Availability';
import { OnboardingHeader } from '../onboarding/OnboardingHeader';
import { OnboardingFooter } from '../onboarding/OnboardingFooter';

export const CandidatePersonalDetails = ({ back, next }) => {
  const { user: [usr, setUsr], onBoarding: [obv, setObv] } = useContext(AppContext);
  const [phone, setPhone] = useState(usr.phone);
  const [email] = useState(usr.email);
  const [name, setName] = useState(usr.name);
  const [jobSeekingStatus, setJobSeekingStatus] = useState(obv.jobSeekingStatus);
  const [availability, setAvailability] = useState(obv.availability);

  obv.countries.forEach((country) => {
    availableCountries.find(({ name }) => name === country).selected = true
  });
  const [countriesSelected, setCountriesSelected] = useState(availableCountries);
  const [selectedCities, setSelectedCities] = useState(obv.cities || []);

  const disableNext = () => {
    const hasName = !!name && name !== '';
    const hasPhone = !!phone && phone !== '';
    const hasAvailability = !!availability && availability !== '';
    const hasJobSeekingStatus = !!jobSeekingStatus && jobSeekingStatus !== '';
    return !hasName || !hasPhone || !hasAvailability || !hasJobSeekingStatus;
  }

  const lookingStatusChosen = (option) => {
    setJobSeekingStatus(option);
    obv.jobSeekingStatus = option;
    setObv(obv);
  };

  const setUserPhone = (value) => {
    setPhone(value);
    usr.phone = value;
    setUsr(usr);
  }

  const setUserAvailability = (e) => {
    const val = e.target.value;
    obv.availability = val;
    setObv(obv);
    setAvailability(val);
  }

  const setUsersName = (value) => {
    setName(value);
    usr.name = value;
    setUsr(usr);
  }

  const cityChoice = (val) => {
    setSelectedCities(val);
    obv.cities = val;
    setObv(obv);
  }

  const toggleCountrySelection = (country) => {
    const newSelected = [
      ...countriesSelected,
    ]
    const match = newSelected.find(({ name }) => country === name);
    match.selected = !match.selected;
    setCountriesSelected(newSelected);
    obv.countries = newSelected
      .filter(({ selected }) => selected)
      .map(({ name }) => name);
    setObv(obv);
  }

  return (
      <>
        <div className="px-4">
          <OnboardingHeader
              header="Personal Details"
              subHeader="We need to know how to get in contact with you!" />
          <div className="mt-5 py-5 border-top">
            <Row>
              <Col xs={12} md={6}>
                <b>
                  Preferred Name
                </b>
                <FormControl variant="standard" className="w-100">
                  <TextField
                      id="input-name"
                      variant="standard"
                      value={name}
                      placeholder="Name"
                      onChange={(e) => setUsersName(e.target.value)}
                  />
                </FormControl>
              </Col>
            </Row>
            <Row className="pt-2">
              <Col xs={12} md={6}>
                <b>
                  Contact number
                </b>
                <FormControl variant="standard" className="w-100">
                  <TextField
                      id="input-phonenr"
                      value={phone}
                      type="tel"
                      variant="standard"
                      placeholder="Contact number"
                      onChange={(e) => setUserPhone(e.target.value)}
                  />
                </FormControl>
              </Col>
            </Row>
            <Row className="pt-2">
              <Col xs={12} md={6}>
                <b>
                  Email address
                </b>
                <FormControl variant="standard" className="w-100">
                  <Input
                      id="input-email"
                      disabled
                      value={email}
                      placeholder="Email Address"
                      type="email"
                      startAdornment={
                        <InputAdornment position="start">
                          ✉
                        </InputAdornment>
                      }
                  />
                </FormControl>
              </Col>
            </Row>
            <Row className="pt-2">
              <Col xs={12} md={6}>
                <b>
                  Availability
                </b>
                <FormControl className="w-100">
                  <Select
                      variant="standard"
                      value={availability}
                      onChange={(e) => setUserAvailability(e)}
                  >
                    {noticeOptions.map(({ text, value }, idx) => {
                        return (
                            <MenuItem key={idx} value={value}>
                              {text}
                            </MenuItem>
                        )
                      })}
                  </Select>
                </FormControl>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col xs={12} className="w-100">
                <div className="mb-1">
                  <b>
                    Select where you'd like to work
                  </b>
                </div>
                {availableCountries.map(({ name }, idx) => {
                  return (
                      <Row key={`${name}-${idx}`}>
                        <Col xs={8}>
                          <FormGroup>
                            <FormControlLabel
                                control={
                                  <Checkbox
                                      value={name}
                                      disabled
                                      checked={obv.countries.some((ctry) => ctry === name)}
                                      onChange={() => toggleCountrySelection(name)} />
                                }
                                label={name} />
                          </FormGroup>
                          <Typography variant="caption">
                            * We currently only support Australia, please let us know if you're interested in work elsewhere
                          </Typography>
                        </Col>
                      </Row>
                  )
                })}
              </Col>
              <Col xs={6}>
                <div className="mt-3 mb-1">
                  <b>
                    Select where you'd like to work
                  </b>
                </div>
                <Autocomplete
                    multiple={true}
                    filterSelectedOptions={true}
                    value={selectedCities || null}
                    onChange={(e, value) => cityChoice(value)}
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                            <Chip color="primary" label={option} {...getTagProps({ index })} />
                        ))
                    }
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            variant="standard"
                            placeholder="Cities"
                        />}
                    options={availableCities} />
              </Col>
            </Row>
            <Row className="pt-4">
              <Col xs={12} className="w-100">
                <div className="pb-2">
                  <b>
                    Job search status
                  </b>
                </div>
                <FormControl component="fieldset" variant="standard">
                  <RadioGroup
                      aria-label="gender"
                      name="controlled-radio-buttons-group"
                      value={jobSeekingStatus}
                      onChange={(e, v) => lookingStatusChosen(v)}
                  >
                    {jobSeekingStatusConstant.map(({value, text}, idx) =>
                        <FormControlLabel value={value} control={<Radio />} label={text} key={`${value}-${idx}`} />
                    )}
                  </RadioGroup>
                </FormControl>
              </Col>
            </Row>
          </div>
          <OnboardingFooter
              nextDisabled={disableNext}
              back={back}
              next={() => {
                next()
              }}
          />
        </div>
      </>
  );
}
