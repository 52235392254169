/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      recipient
      type
      text
      seen
      context
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        recipient
        type
        text
        seen
        context
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const byRecipient = /* GraphQL */ `
  query ByRecipient(
    $recipient: String
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byRecipient(
      recipient: $recipient
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        recipient
        type
        text
        seen
        context
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const byRecipientAndType = /* GraphQL */ `
  query ByRecipientAndType(
    $recipient: String
    $type: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byRecipientAndType(
      recipient: $recipient
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        recipient
        type
        text
        seen
        context
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncNotifications = /* GraphQL */ `
  query SyncNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        recipient
        type
        text
        seen
        context
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getConversationMsg = /* GraphQL */ `
  query GetConversationMsg($id: ID!) {
    getConversationMsg(id: $id) {
      id
      cid
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listConversationMsgs = /* GraphQL */ `
  query ListConversationMsgs(
    $filter: ModelConversationMsgFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConversationMsgs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cid
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncConversationMsgs = /* GraphQL */ `
  query SyncConversationMsgs(
    $filter: ModelConversationMsgFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncConversationMsgs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        cid
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getUserDisableReason = /* GraphQL */ `
  query GetUserDisableReason($id: ID!) {
    getUserDisableReason(id: $id) {
      id
      owner
      email
      reason
      comments
      rating
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listUserDisableReasons = /* GraphQL */ `
  query ListUserDisableReasons(
    $filter: ModelUserDisableReasonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserDisableReasons(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        email
        reason
        comments
        rating
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUserDisableReasons = /* GraphQL */ `
  query SyncUserDisableReasons(
    $filter: ModelUserDisableReasonFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserDisableReasons(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        email
        reason
        comments
        rating
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getConversation = /* GraphQL */ `
  query GetConversation($id: ID!) {
    getConversation(id: $id) {
      id
      user1
      user2
      participants
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      messages {
        items {
          id
          conversationID
          participants
          sender
          message
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listConversations = /* GraphQL */ `
  query ListConversations(
    $filter: ModelConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConversations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user1
        user2
        participants
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        messages {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncConversations = /* GraphQL */ `
  query SyncConversations(
    $filter: ModelConversationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncConversations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        user1
        user2
        participants
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        messages {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      conversationID
      participants
      sender
      message
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        conversationID
        participants
        sender
        message
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncMessages = /* GraphQL */ `
  query SyncMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMessages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        conversationID
        participants
        sender
        message
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      active
      reviewed
      owner
      name
      email
      phone
      city
      picture
      description
      accountType
      candidate {
        candidateTypes
        yearsExperience
        workCategories
        availability
        salary
        salaryCurrency
        desiredSalary
        desiredCurrency
        countries
        cities
        eligibility {
          country
          value
        }
        workType
        openToRemote
        jobSeekingStatus
        programmingLanguages
        createdAt
        updatedAt
      }
      employer {
        companyName
        companySize
        workCategories
        workType
        countries
        description
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        active
        reviewed
        owner
        name
        email
        phone
        city
        picture
        description
        accountType
        candidate {
          candidateTypes
          yearsExperience
          workCategories
          availability
          salary
          salaryCurrency
          desiredSalary
          desiredCurrency
          countries
          cities
          workType
          openToRemote
          jobSeekingStatus
          programmingLanguages
          createdAt
          updatedAt
        }
        employer {
          companyName
          companySize
          workCategories
          workType
          countries
          description
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      scannedCount
      totalCount
      count
      startedAt
    }
  }
`;
export const byAccountType = /* GraphQL */ `
  query ByAccountType(
    $accountType: AccountType
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byAccountType(
      accountType: $accountType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        active
        reviewed
        owner
        name
        email
        phone
        city
        picture
        description
        accountType
        candidate {
          candidateTypes
          yearsExperience
          workCategories
          availability
          salary
          salaryCurrency
          desiredSalary
          desiredCurrency
          countries
          cities
          workType
          openToRemote
          jobSeekingStatus
          programmingLanguages
          createdAt
          updatedAt
        }
        employer {
          companyName
          companySize
          workCategories
          workType
          countries
          description
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      scannedCount
      totalCount
      count
      startedAt
    }
  }
`;
export const userByEmail = /* GraphQL */ `
  query UserByEmail(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        active
        reviewed
        owner
        name
        email
        phone
        city
        picture
        description
        accountType
        candidate {
          candidateTypes
          yearsExperience
          workCategories
          availability
          salary
          salaryCurrency
          desiredSalary
          desiredCurrency
          countries
          cities
          workType
          openToRemote
          jobSeekingStatus
          programmingLanguages
          createdAt
          updatedAt
        }
        employer {
          companyName
          companySize
          workCategories
          workType
          countries
          description
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      scannedCount
      totalCount
      count
      startedAt
    }
  }
`;
export const userByOwner = /* GraphQL */ `
  query UserByOwner(
    $owner: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        active
        reviewed
        owner
        name
        email
        phone
        city
        picture
        description
        accountType
        candidate {
          candidateTypes
          yearsExperience
          workCategories
          availability
          salary
          salaryCurrency
          desiredSalary
          desiredCurrency
          countries
          cities
          workType
          openToRemote
          jobSeekingStatus
          programmingLanguages
          createdAt
          updatedAt
        }
        employer {
          companyName
          companySize
          workCategories
          workType
          countries
          description
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      scannedCount
      totalCount
      count
      startedAt
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  query SyncUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        active
        reviewed
        owner
        name
        email
        phone
        city
        picture
        description
        accountType
        candidate {
          candidateTypes
          yearsExperience
          workCategories
          availability
          salary
          salaryCurrency
          desiredSalary
          desiredCurrency
          countries
          cities
          workType
          openToRemote
          jobSeekingStatus
          programmingLanguages
          createdAt
          updatedAt
        }
        employer {
          companyName
          companySize
          workCategories
          workType
          countries
          description
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      scannedCount
      totalCount
      count
      startedAt
    }
  }
`;
export const getBlogPost = /* GraphQL */ `
  query GetBlogPost($id: ID!) {
    getBlogPost(id: $id) {
      id
      owner
      title
      slug
      content
      imageUrl
      authorName
      preview
      hidden
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listBlogPosts = /* GraphQL */ `
  query ListBlogPosts(
    $filter: ModelBlogPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBlogPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        title
        slug
        content
        imageUrl
        authorName
        preview
        hidden
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const blogBySlug = /* GraphQL */ `
  query BlogBySlug(
    $slug: String
    $sortDirection: ModelSortDirection
    $filter: ModelBlogPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    blogBySlug(
      slug: $slug
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        title
        slug
        content
        imageUrl
        authorName
        preview
        hidden
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncBlogPosts = /* GraphQL */ `
  query SyncBlogPosts(
    $filter: ModelBlogPostFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBlogPosts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        title
        slug
        content
        imageUrl
        authorName
        preview
        hidden
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
