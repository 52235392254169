const devEnvs = [
    'local',
    'develop',
];

export const getDevEnv = devEnvs.find((env) => window.location.host.startsWith(env));

export const isDevEnvironment = !!getDevEnv;

export const appVersion = process.env.REACT_APP_VERSION;
export const appName = process.env.REACT_APP_NAME;
const useDevelopAPI = process.env.REACT_APP_DEVELOP_API;

export const auth0JWTAudience = useDevelopAPI ?
    'https://api.develop.upwardspiral.io' :
    'https://api.upwardspiral.io'

export const apiEndpoint = `${auth0JWTAudience}/`;
