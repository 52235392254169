export const getAuthErrMessage = (errCode, message?) => {
  switch (errCode) {
    case "UserNotFoundException":
      if (message && message.startsWith('Username/client id combination')) {
        return 'Please check your email and code are both correct'
      }
      return `Incorrect email or password`;
    case "InvalidParameterException":
      if (message.indexOf('Custom') >= 0) {
        return 'Something went wrong';
      }
      return `Username can't be an email`;
    case "UsernameExistsException":
      return `Email is already in use`;
    case "CodeMismatchException":
      return `Invalid code, please try again.`
    case "NotAuthorizedException":
      if (message.indexOf(`CONFIRMED`) >= 0) {
        return `Account already verified`;
      }
      if (message.indexOf(`Incorrect`) >= 0) {
        return message;
      }
      return `User not authorized`;
    default:
      return 'Something went wrong';
  }
}
