import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom';
import { Card, Col, Row } from 'reactstrap';
import clsx from 'clsx';

import bgImg from '../assets/img/ill/bg_contactus3.svg';
import AppContext from '../contexts/AppContext';
import {
  Box, Button, CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel, Radio, RadioGroup, TextField
} from '@mui/material';
import { API } from 'aws-amplify';
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';

const styles = makeStyles({
  container: {
    minHeight: '85vh',
    minWidth: '95vw',
    position: 'relative',
    '&::after': {
      content: '""',
      maxHeight: '100vh',
      maxWidth: '100vw',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      position: 'absolute',
      backgroundImage: `url(${bgImg})`,
      backgroundSize: 'cover',
      backgroundPosition: 'top center',
      opacity: 0.4,
      zIndex: -1,
    },
  },

});

export const DisablePage = () => {
  const {
    user: [usr, setUsr],
  } = useContext(AppContext);
  const history = useHistory();
  const classes = styles();
  const [loading, setLoading] = useState(false);
  const [leaveReason, setLeaveReason] = useState('found-job-through-platform');
  const [comment, setComment] = useState('');

  useEffect(() => {
    if (!usr.active) {
      history.push('/');
    }
  }, []);

  const handleLeaveRadio = (event) => {
    setLeaveReason(event.target.value);
  };

  const deactivateAccount = async () => {
    setLoading(true);
    await API.graphql(
      { query: mutations.updateUser,
        variables: {
          input: {
            id: usr.id,
            email: usr.email,
            active: false,
            _version: usr?._version
          },
          condition: null,
        }}
    )
    const { data: { getUser }} = await API.graphql(
        { query: queries.getUser, variables: { id: usr.id }}
    );
    setUsr(getUser);
    setLoading(false);
    history.push('/');
    API.graphql({
      query: mutations.createUserDisableReason,
      variables: {
        input: {
          owner: usr.owner,
          reason: leaveReason,
          comments: comment,
        }
      }
    })
  };

  return (
    <div className={classes.container}>
      <Container>
        <Card className={clsx('card-profile shadow mt-3')}>
          {!usr.active &&
            <>
              <h4>Your account is not currently active</h4>
            </>
          }
          <Box sx={{ p: 2 }}>
            <Row>
              <Col xs={12}>
                <h4>Disable your account</h4>
              </Col>
              <Col xs={12}>
                <FormControl component="fieldset" disabled={loading}>
                  <FormLabel component="legend">Please select a reason</FormLabel>
                  <RadioGroup
                      aria-label="deactivate-reason"
                      value={leaveReason}
                      onChange={handleLeaveRadio}
                  >
                    <FormControlLabel value="found-job-through-platform" control={<Radio />} label="I found a job through the platform" />
                    <FormControlLabel value="found-job-outside-platform" control={<Radio />} label="I found a job outside of the platform" />
                    <FormControlLabel value="stopping-search" control={<Radio />} label="I am stopping my job search" />
                    <FormControlLabel value="no-reason" control={<Radio />} label="I am seeking other avenues of finding a job" />
                    <FormControlLabel value="other" control={<Radio />} label="Other" />
                  </RadioGroup>
                </FormControl>
              </Col>
              <Col md={9} xs={12}>
                <TextField
                    id="outlined-textarea"
                    disabled={loading}
                    fullWidth
                    minRows={4}
                    maxRows={4}
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    label="Details (optional)"
                    placeholder="Please put any additional comments here"
                    multiline
                />
              </Col>
              <Col xs={12} className="pt-2">
                <Button
                    className="mr-2"
                    disabled={loading}
                    color="primary"
                    onClick={() => history.push('/')}
                    variant="outlined">
                  Cancel
                </Button>
                <Button
                    disabled={loading}
                    color="primary"
                    onClick={deactivateAccount}
                    variant="contained">
                  {loading ? <CircularProgress style={{ height: '1.75rem', width: '1.75rem' }} /> : 'Deactivate my account'}
                </Button>
              </Col>
            </Row>
          </Box>
        </Card>
      </Container>
    </div>
  )
};
