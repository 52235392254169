import React, { useEffect, useState } from 'react';
import {
  Button, Chip,
  Container,
  Paper,
  Tab, Table, TableBody, TableCell, TableContainer,
  TableHead,
  TableRow,
  Tabs, TextField
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { LoaderInRow } from '../../../components/loader/Loaders';
import { API } from 'aws-amplify';
import * as queries from '../../../graphql/queries';
import * as mutations from '../../../graphql/mutations';
import { fetchLimit } from '../../../constants/apiConstants';
import { TabPanel } from '../../../components/TabPanel';
import { Col, Row } from 'reactstrap';
import { CandidatePreview } from '../../../components/preview/CandidatePreview';
import { AccountType } from '../../../models';
import { EmployerPreview } from '../../../components/preview/EmployerPreview';
import { adminListGroupsForUser } from '../../../utils/AdminQueryUtils';


export const UserListAdmin = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [initialisedUsers, setInitialisedUsers] = useState([]);
  const [usersNeedingAttention, setUsersNeedingAttention] = useState([]);
  const [previewUser, setPreviewUser] = useState();
  const [searchName, setSearchName] = useState('');

  const tabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const toggleActive = async (user) => {
    await API.graphql({
      query: mutations.updateUser,
      variables: {
        input: {
          id: user.id,
          _version: user._version,
          active: !user.active,
        },
        condition: null,
      }
    })
    await getAllData();
  }
  const toggleReviewed = async (user) => {
    await API.graphql({
      query: mutations.updateUser,
      variables: {
        input: {
          id: user.id,
          _version: user._version,
          reviewed: !user.reviewed,
        },
        condition: null,
      }
    })
    await getAllData();
  }
  const getAllData = async () => {
    setLoading(true);
    await Promise.all([
      API.graphql(
          { query: queries.listUsers, variables: { limit: fetchLimit, } }
      ).then(async ({ data: { listUsers: { items }}}) => {
        const filtered = items?.filter((item) => !item._deleted);
        const needingAttention = filtered
          .map((user) => {
            const { candidate, employer, accountType } = user;
            const noAccountType = !accountType;
            const missingCandidateData = accountType === AccountType.CANDIDATE && !candidate;
            const missingEmployerData = accountType === AccountType.EMPLOYER && !employer;
            return {
              ...user,
              noAccountType,
              missingCandidateData,
              missingEmployerData
            }
          })
          .filter(({ noAccountType, missingCandidateData, missingEmployerData }) => {
            return noAccountType || missingCandidateData || missingEmployerData;
          });
        const newItems = await Promise.all(
          filtered
          .filter(( { owner }) => owner)
          .map(async (item) => {
            try {
              const { Groups } = await adminListGroupsForUser(item.owner);
              const groups = Groups.map(({ GroupName }) => GroupName);
              let disableToggle = false;
              if (item.accountType === AccountType.CANDIDATE) {
                disableToggle = groups.indexOf('candidates') === -1;
              } else if (item.accountType === AccountType.EMPLOYER) {
                disableToggle = groups.indexOf('employers') === -1;
              }
              return {
                ...item,
                disableToggle,
              }
            } catch (err) {
              return item;
            }
        }));
        setUsersNeedingAttention(needingAttention);
        setInitialisedUsers(newItems.filter(({ id }) => !needingAttention.find((u) => u.id === id)));
      }),
    ]).finally(() => setLoading(false));
  }

  useEffect(() => {
    getAllData().finally(() => setLoading(false));
  }, []);


  if (loading) {
    return (
      <Container >
        <LoaderInRow message="Loading User data..." />
      </Container>
    )
  }

  return (
      <>
        <Tabs value={tabIndex} onChange={tabChange} aria-label="admin tabs">
          <Tab label="Users" />
          <Tab label="Needing attention" />
        </Tabs>
        <TabPanel tabIndex={tabIndex} index={0} sx={{ p: 1 }}>
          <Row>
            <Col md={8}>
              <div className="d-flex flex-row align-items-center">
                <TextField
                    value={searchName}
                    onChange={(e) => setSearchName(e.target.value)}
                    className="my-2" label="Search by name" variant="filled" />
              </div>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Active</TableCell>
                      <TableCell>Reviewed</TableCell>
                      <TableCell>Account Type</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {initialisedUsers
                      .filter(({ name }) => {
                        if (!searchName || searchName.length === 0) {
                          return true;
                        }
                        return name.toLowerCase().indexOf(searchName.toLowerCase()) >= 0;
                      })
                      .map((user, idx) => {
                      return (
                          <TableRow
                              key={`row-${idx}`}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell component="th" scope="row">
                              {user.name}
                            </TableCell>
                            <TableCell>
                              {user.active ? '✔️' : '❌'}
                            </TableCell>
                            <TableCell>
                              {user.reviewed ? '✔️' : '❌'}
                            </TableCell>
                            <TableCell>
                              <Chip
                                  color={user.accountType
                                      ? user.accountType === AccountType.CANDIDATE ? 'info' :
                                          user.accountType === AccountType.ADMIN ? 'warning' : 'success'
                                      : 'error'
                                  }
                                  label={user.accountType} />
                            </TableCell>
                            <TableCell>
                              {}
                              <Button
                                  onClick={() => setPreviewUser(user)}
                                  startIcon={<SearchIcon />}
                                  variant="contained">
                                Preview
                              </Button>
                              {user.disableToggle && <Button disabled>Add to Cognito groups to toggle</Button>}
                              {!user.disableToggle &&
                                  <>
                                    <Button
                                        disabled={user.disableToggle}
                                        onClick={() => toggleActive(user)}
                                        variant="outlined" className="ml-1">Toggle active</Button>
                                    <Button
                                        disabled={user.disableToggle}
                                        onClick={() => toggleReviewed(user)}
                                        variant="outlined" className="ml-1">Toggle review</Button></>
                              }
                            </TableCell>

                          </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Col>
            <Col md={4} className="mt-6">
              {previewUser ?
                  previewUser.accountType === AccountType.CANDIDATE ?
                      <CandidatePreview user={previewUser} /> :
                      <EmployerPreview user={previewUser} />
                : <>Selected a user to preview</>
              }
            </Col>
          </Row>
        </TabPanel>
        <TabPanel tabIndex={tabIndex} index={1} sx={{ p: 1 }}>
          <Row>
            <Col md={12}>
              <div className="d-flex flex-row align-items-center">
                <TextField
                    value={searchName}
                    onChange={(e) => setSearchName(e.target.value)}
                    className="my-2" label="Search by name" variant="filled" />
              </div>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Email</TableCell>
                      <TableCell>Active</TableCell>
                      <TableCell>Reviewed</TableCell>
                      <TableCell>Account Type</TableCell>
                      <TableCell>Reason</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {usersNeedingAttention
                      .filter(({ name }) => {
                        if (!searchName || searchName.length === 0) {
                          return true;
                        }
                        return name.toLowerCase().indexOf(searchName.toLowerCase()) >= 0;
                      })
                      .map((user, idx) => {
                      return (
                          <TableRow
                              key={`row-${idx}`}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell component="th" scope="row">
                              {user.email}
                            </TableCell>
                            <TableCell>
                              {user.active ? '✔️' : '❌'}
                            </TableCell>
                            <TableCell>
                              {user.reviewed ? '✔️' : '❌'}
                            </TableCell>
                            <TableCell>
                              <Chip
                                  color={user.accountType
                                      ? user.accountType === AccountType.CANDIDATE ? 'info' :
                                          user.accountType === AccountType.ADMIN ? 'warning' : 'success'
                                      : 'error'
                                  }
                                  label={user.accountType
                                      ? user.accountType
                                      : 'No Account Type'
                                  } />
                            </TableCell>
                            <TableCell>
                              {user.noAccountType && 'No account type'}
                              {user.missingCandidateData && 'Missing Candidate data'}
                              {user.missingEmployerData && 'Missing Employer data'}
                            </TableCell>
                          </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Col>
          </Row>
        </TabPanel>
      </>
  )
}
