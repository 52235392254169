import React, { useContext } from "react";

// reactstrap components

import { Button, Container } from '@mui/material';
import { Row, Col } from "reactstrap";
import { useHistory } from 'react-router-dom';
import { ImportantDevices } from '@mui/icons-material';
import AppContext from '../../../contexts/AppContext';

// Core Components

export const HireFeature = () => {
  const history = useHistory();
  const { navTab: [, setActiveTab] } = useContext(AppContext);

  return (
    <div className="section features-1 px-3">
      <Container>
        <Row>
          <Col xs={12} md={4} className="d-flex flex-column">
            <div className="my-3 icon icon-lg icon-shape icon-shape-info shadow rounded-circle">
              <i className="ni ni-lock-circle-open"></i>
            </div>
            <h4 className="font-weight-bolder">
              Reach talent
            </h4>
            <div className="pt-2">
              Every profile is screened for job seeker intent and capability.
            </div>
          </Col>
          <Col xs={12} md={4} className="d-flex flex-column">
            <div className="my-3 icon icon-lg icon-shape icon-shape-info shadow rounded-circle">
              <i className="ni ni-bullet-list-67"></i>
            </div>
            <h4 className="font-weight-bolder">
              Be rigorous
            </h4>
            <div className="pt-2">
              Upward Spiral gives you access to the widest range of candidates so you can find the best fit.
            </div>
          </Col>
          <Col xs={12} md={4} className="d-flex flex-column">
            <div className="my-3 icon icon-lg icon-shape icon-shape-success shadow rounded-circle">
              <i className="ni ni-satisfied"></i>
            </div>
            <h4 className="font-weight-bolder">
              Build your team
            </h4>
            <div className="pt-2">
              Hiring through the platform is as effortless as contacting a recruiter but you stay in control of the process.
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <div className="w-100 pt-4 d-flex flex-row justify-content-start">
              <Button
                  onClick={() => {
                    setActiveTab(3);
                    history.push('/login?create')
                  }}
                  variant="contained" style={{ color: 'white'}}
                  startIcon={<ImportantDevices />}
              >
                Hire
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
