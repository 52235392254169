import React, { useContext } from "react";

import { Button, Container } from '@mui/material';
import { Row, Col } from "reactstrap";
import { useHistory } from 'react-router-dom';
import { PersonAddAlt } from '@mui/icons-material';
import AppContext from '../../../contexts/AppContext';

export const FindWorkFeature = () => {
  const history = useHistory();
  const { navTab: [, setActiveTab] } = useContext(AppContext);

  return (
    <div className="section features-1 px-3">
      <Container>
        <Row>
          <Col xs={12} md={3} className="d-flex flex-column">
            <div className="my-3 icon icon-lg icon-shape icon-shape-info shadow rounded-circle">
              <i className="ni ni-circle-08"></i>
            </div>
            <h4 className="font-weight-bolder">
              Create your profile
            </h4>
            <div className="pt-2">
              Answer a few questions about your skills, experience and salary expectations
            </div>
          </Col>
          <Col xs={12} md={3} className="d-flex flex-column">
            <div className="my-3 icon icon-lg icon-shape icon-shape-info shadow rounded-circle">
              <i className="ni ni-books"></i>
            </div>
            <h4 className="font-weight-bolder">
              Get advice
            </h4>
            <div className="pt-2">
              Get help benchmarking your skills and salary so we can build your profile.
            </div>
          </Col>
          <Col xs={12} md={3} className="d-flex flex-column">
            <div className="my-3 icon icon-lg icon-shape icon-shape-info shadow rounded-circle">
              <i className="ni ni-notification-70"></i>
            </div>
            <h4 className="font-weight-bolder">
              Hear back
            </h4>
            <div className="pt-2">
              Employers are vetted so you’ll only get legit offers from companies looking to hire.
            </div>
          </Col>
          <Col xs={12} md={3} className="d-flex flex-column">
            <div className="my-3 icon icon-lg icon-shape icon-shape-success shadow rounded-circle">
              <i className="ni ni-spaceship"></i>
            </div>
            <h4 className="font-weight-bolder">
              Get hired
            </h4>
            <div className="pt-2">
              Interview and get hired!
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <div className="w-100 pt-4 d-flex flex-row justify-content-start">
              <Button
                  style={{ zIndex: 5, color: 'white'}}
                  onClick={() => {
                    setActiveTab(3);
                    history.push('/login?create')
                  }}
                  variant="contained"
                  startIcon={<PersonAddAlt />}
              >
                Create profile
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
