import React, { useContext, useState } from 'react';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';

import MailIcon from '@mui/icons-material/Mail';
import SettingsIcon from '@mui/icons-material/Settings';

import AppContext from '../../../contexts/AppContext';
import { IconButton, Tooltip } from '@mui/material';
import { StyledBadge } from '../../buttons/Badges';
import { Logout, Person, AdminPanelSettings, SupervisorAccountTwoTone } from '@mui/icons-material';
export const NavbarDropdownDesktop = () => {
  const history = useHistory();
  const [messagesUnread] = useState(0);
  const {
    auth: [authenticated],
    user,
    loading: [, setLoading],
    loadingMessage: [, setLoadingMessage],
  } = useContext(AppContext);
  const [userVal] = user;
  const isReviewed = userVal && userVal.reviewed;

  const logoutUser = (e) => {
    e.preventDefault();
    setLoadingMessage('Logging you out');
    setLoading(true);
    Auth.signOut().then(() => {
      setLoading(false);
      setLoadingMessage('');
    })
  }

  if (!authenticated) {
    return <></>
  }

  return (
      <>
        {(userVal && !!userVal.accountType && isReviewed) &&
          <IconButton aria-label="cart"
            onClick={() => {
              history.push('/chat');
            }}>
            <StyledBadge badgeContent={messagesUnread} color="info">
              <Tooltip title={'View messages'}>
                <MailIcon color="white"/>
              </Tooltip>
            </StyledBadge>
          </IconButton>
        }
        <Dropdown nav
                  toggle={() => {
                  }}
        >
          <DropdownToggle
              aria-expanded={false}
              aria-haspopup={true}
              caret
              color="default"
              data-toggle="dropdown"
              href="#pablo"
              onClick={(e) => e.preventDefault()}
              id="navbar-primary_dropdown_1"
              nav
              role="button"
          >
            <SettingsIcon className={"mr-1"}/>
            <span className="nav-link-inner--text d-lg-none">
              Settings
            </span>
          </DropdownToggle>
          {authenticated &&
          <DropdownMenu aria-labelledby="navbar-primary_dropdown_1" end>
            {authenticated.groups && authenticated.groups.indexOf('administrators') >= 0 &&
              <DropdownItem
                  onClick={() => {
                    return history.push('/admin');
                  }}>
                <AdminPanelSettings /> Administration
              </DropdownItem>
            }
            {(userVal && !!userVal.accountType && isReviewed) &&
              <>
                {authenticated.groups && authenticated.groups.indexOf('employers') >= 0 &&
                  <DropdownItem
                      onClick={() => {
                        return history.push('/employer');
                      }}>
                    <SupervisorAccountTwoTone /> Employer Home
                  </DropdownItem>
                }
                <DropdownItem
                    onClick={() => {
                      return history.push('/you/edit');
                  }}>
                  <Person fontSize="small" /> Edit Profile
                </DropdownItem>
              </>
            }
            <DropdownItem
                onClick={(e) => logoutUser(e)}
            >
              <Logout fontSize="small" /> Logout
            </DropdownItem>
          </DropdownMenu>
          }
        </Dropdown>
      </>
  )
}
