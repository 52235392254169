export const currencies = Object.freeze([
  {
    name: 'Australian Dollar',
    abr: 'AUD',
    symbol: '$',
  },
  // {
  //   name: 'Euro',
  //   abr: 'EUR',
  //   symbol: '€',
  // },
  // {
  //   name: 'South African Rand',
  //   abr: 'ZAR',
  //   symbol: 'R',
  // },
]);

export const getCurrency = (abr) => {
  return currencies.find((currency) => currency.abr === abr) || currencies[0];
}
