import { Paper, Tab, Tabs } from '@mui/material';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import AppContext from '../../../contexts/AppContext';

const styles = makeStyles({
  tab: {
    '&.MuiTab-root.Mui-selected': {
      backgroundColor: '#91b4c0',
    }
  }
});
export const NavTabsDesktop = () => {
  const classes = styles();
  const history = useHistory();
  const { navTab: [activeTab, setActiveTab] } = useContext(AppContext);
  const handleTabChange = (event, tab) => {
    setActiveTab(tab);
  };

  return (
      <Paper square>
        <Tabs
            value={activeTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleTabChange}
            aria-label="disabled tabs example"
            className="p-0"
        >
          <Tab
              className={classes.tab}
              label="Find work"
              onClick={() => history.push('/')}
          />
          <Tab
              className={classes.tab}
              label="Hire"
              onClick={() => history.push('/hire')}
          />
          <Tab
              className={classes.tab}
              label="Blog"
              onClick={() => history.push('/blog')}
          />
          <Tab
              className={classes.tab}
              label="Login"
              onClick={() => history.push('/login')}
          />
        </Tabs>
      </Paper>
  )

}
