/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cognito_identity_pool_id": "ap-southeast-2:93ce4bf1-a96c-47a6-8bed-3968928b48b4",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_yYJUNr0p3",
    "aws_user_pools_web_client_id": "46iflte66isnd68sdpbh0pfvlk",
    "oauth": {
        "domain": "upwardspiralbea9a1ad-bea9a1ad-develop.auth.ap-southeast-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://develop.upwardspiral.io/signin/,http://localhost:3000/signin/",
        "redirectSignOut": "https://develop.upwardspiral.io/,http://localhost:3000/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://vtw55rqjmjh3tbuptnnkojdzia.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-wbbf4xvpjjgkplxe46pze3r5fy",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://qnwdc6dzk7.execute-api.ap-southeast-2.amazonaws.com/develop",
            "region": "ap-southeast-2"
        }
    ],
    "aws_mobile_analytics_app_id": "45a8d18e38344d64ba5773cd9438a723",
    "aws_mobile_analytics_app_region": "us-west-2"
};


export default awsmobile;
