import React, { useContext, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import bgImg from '../assets/img/ill/404.svg';
import headImg from '../assets/img/pages/nicolas-prieto.jpg';
import failbackImg from '../assets/img/ill/ill.png';

import {
  Button,
  Card,
  Container, Skeleton,
  Typography
} from '@mui/material';
import { isMobile } from "react-device-detect";

import MDEditor from '@uiw/react-md-editor';
import { CardBody, CardTitle, Col, Row } from 'reactstrap';

import { API } from 'aws-amplify';
import * as queries from '../graphql/queries';
import AppContext from '../contexts/AppContext';
import { useHistory } from 'react-router-dom';
import { CommonFooter } from '../components/CommonFooter';

const styles = makeStyles({
  container: {
    minHeight: '85vh',
    minWidth: '95vw',
    position: 'relative',
  },
  main: {
    display: 'grid',
    paddingTop: isMobile ? '15vh' : '10vh'
  },
});

export const BlogsPage = () => {
  const { auth: [authed] } = useContext(AppContext);
  const history = useHistory();
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [apiNextToken, setNextToken] = useState();
  const classes = styles();
  const messagesDiv = useRef();

  const getBlogPosts = async (scroll) => {
    if (!loading) {
      setLoading(true);
    }
    const { data: { listBlogPosts: { items, nextToken } }} = await API.graphql(
        {
          query: queries.listBlogPosts,
          variables: {
            limit: 10,
            nextToken: apiNextToken ? apiNextToken : null,
          },
          authMode: authed ? 'AMAZON_COGNITO_USER_POOLS' : 'API_KEY'
        }
    );
    setBlogs([
      ...blogs,
      ...items
    ]
    .filter(({ hidden }) => !hidden)
    .sort(({ updatedAt }, b) => new Date(b.updatedAt).getTime() - new Date(updatedAt).getTime()));
    setNextToken(nextToken);
    if (scroll) {
      setTimeout(() => {
        messagesDiv?.current?.scrollIntoView({ behavior: "smooth" });
      }, 250);
    }
  }

  useEffect(() => {
    getBlogPosts().finally(() => setTimeout(() => setLoading(false), 1500));
  }, []);

  const goToBlog = (blog) => {
    history.push(`/blog/${blog.slug}`);
  }

  const getSkeleton = () => {
    return (
        <Row className="pt-1 mb-5">
          <Col xs={2}>
            <Typography variant="h1" >
              <Skeleton variant="rectangular"/>
            </Typography>
          </Col>
          <Col xs={10}>
            <Typography variant="h1">
              <Skeleton variant="rectangular" />
            </Typography>
          </Col>
          <Col xs={12}>
            <Typography variant="h3">
              <Skeleton variant="text" />
            </Typography>
          </Col>
        </Row>
    )
  }

  return (
      <div className={classes.container}>
        <div className="page-header page-header-small header-filter overflow-hidden"
            style={{
              backgroundImage:
                  `url(${headImg})`,
              backgroundSize: 'cover',
              backgroundPosition: 'top center',
            }}>
          <div className="content-center">
            <Row>
              <Col className="mx-auto text-center" md="6">
                <h1 className="title text-white">
                  A place for our blogs and content
                </h1>
              </Col>
            </Row>
          </div>
        </div>
        <div className={classes.main}>
          <Container>
            <Row>
              <Col className="mx-auto" lg="10" md="8">
                {loading ?
                    <>
                      {getSkeleton()}
                    </>
                  :
                  blogs.length === 0 &&
                    <Row>
                      <Col className="mb-2">
                        <h5>It seems there's no blog content to read at the moment</h5>
                      </Col>
                    </Row>
                  }
                {blogs.map((blog, i) => {
                  return (
                      <Card key={`blog-${i}`} className="card-blog card-plain blog-horizontal mb-5">
                        <Row className="clickable" onClick={(e) => {
                          e.preventDefault()
                          goToBlog(blog);
                        }}>
                          <Col lg="4">
                            <div className="pl-4 card-image d-flex flex-column h-100 justify-content-center">
                              <img
                                  alt="..."
                                  className="img-fluid rounded"
                                  src={blog.imageUrl ? blog.imageUrl : failbackImg}
                              ></img>
                            </div>
                          </Col>
                          <Col lg="8">
                            <CardBody className="px-5 pt-5 pb-3 d-flex flex-column h-100">
                              <CardTitle tag="h3">
                                {blog.title}
                                {blog.authorName &&
                                  <div className="blockquote">
                                    <Typography variant='caption'>
                                      {blog.authorName} - {new Date(blog.updatedAt).toLocaleDateString()}
                                    </Typography>
                                  </div>
                                }
                              </CardTitle>
                              <MDEditor.Markdown
                                  components={
                                    {
                                      'a': props => <a href={props.href}>{props.children}</a>,
                                      'span': props => {
                                        if (props.className === 'icon icon-link') {
                                          return <span></span>
                                        }
                                        return <span>{props.children}</span>
                                      }
                                    }
                                  }
                                  source={blog.preview} />
                              <div className="mt-auto text-blue">
                                <Button variant="link" href={`/blog/${blog.slug}`}>
                                  Read more...
                                </Button>
                              </div>
                            </CardBody>
                          </Col>
                        </Row>
                      </Card>
                  )
                })}
                <div id={"messagesDiv"} ref={messagesDiv}></div>
              </Col>
              <Col className="mx-auto" lg="10" md="8">
                <Button
                    disabled={!apiNextToken}
                    variant="contained"
                    color="primary"
                    onClick={() => getBlogPosts()}>
                  More...
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
        <CommonFooter />
      </div>
  )
}
