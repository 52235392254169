export const codeOfConduct = `
# **Code of Conduct**
**You agree that you will:**

1. Comply with all applicable laws;
1. Provide accurate information to us and keep it updated;
1. Use our services in a professional manner;
1. Communicate promptly with all users of the Upward Spiral platform including us.

**You agree that you will *not*:**

1. Act in an unlawful or unprofessional manner in connection with our Services, including being dishonest, abusive, or discriminatory;
1. Post inaccurate, defamatory obscene, discriminatory, shocking, hateful, threatening or otherwise inappropriate content or airing personal grievances or disputes;
1. Use or attempt to use another Upward Spiral user’s account;
1. Develop, support or use software, devices, scripts, robots, or any other means or processes (including crawlers, browser plugins and add-ons, or any other technology or manual work) to scrape the Services or otherwise copy profiles and other data from the Services;
1. Copy, use, disclose or distribute any information obtained from the services, whether directly or through third parties (such as search engines), without the consent of Upward Spiral;
1. Use, disclose or distribute any data obtained in violation of this policy;
1. Disclose information that you do not have the consent to disclose (such as confidential information of others (including your employer));
1. Violate the intellectual property rights of others or Upward Spiral;
1. Use bots or other automated methods to access the Services, add or download contacts, send, or redirect messages;
1. Engage in "framing," "mirroring", or otherwise simulating the appearance or function of the Services;
1. Use a Service for tasks that it is not intended for; and
1. Override any security feature of the Services;
`

export const privacyPolicy = `
# **Privacy Notice**
**This Privacy Notice was last updated on 22 October 2021**

1. Introduction
   1. In order to deliver UPWARD SPIRAL's services and to give you the best possible experience, UPWARD SPIRAL will need to collect and process some of your personal information. Your privacy and the security of your and our personal data will always be of paramount importance. This notice will explain how, when and why we gather, store, share and use your personal data - as well as outline the controls and choices you have around when and how you choose to share your personal data.
   1. That is our objective, and this Privacy Notice ("**Notice**") will explain exactly what we mean in further detail.
2. About this Notice
   1. This Notice sets out the essential details relating to the personal data that UPWARD SPIRAL processes about you. The Notice applies to all UPWARD SPIRAL services and websites operated by UPWARD SPIRAL under the domain UPWARD SPIRAL.com (referred to as the '**UPWARD SPIRAL Services**'). The terms governing your use of the UPWARD SPIRAL Services are defined in our Terms and Conditions of Use (the "**Terms and Conditions of Use**").
   1. If there are any changes to our data processing practices or to this Notice, the updated Notice will be made available on our website at[ ](https://www.offerzen.com)upwardspiral.io and will be applicable to all UPWARD SPIRAL Services provided from the date of publication.
   1. The aim of this Notice is to:
      1. Ensure that you understand what personal data we collect about you, when and how we collect this data and the reasons we collect and use it. We also want you to understand who we might share your personal data with.
      1. Explain the way we use the personal data that you share with us.
      1. Explain your rights and choices in relation to the personal data we collect and process about you and how we will protect your privacy.
   1. We hope that this Notice will help you understand our privacy commitments to you. If you have any questions about your privacy, your rights, or how to exercise them, please contact our privacy officer using the information provided in the "How to Contact Us" section of this Notice. We will respond to your request within a reasonable period of time upon verification of your identity and no later than within one month of your request. If you are unhappy with the way we are using your personal data or the way we process your request to exercise your rights as a data subject you are free to lodge a complaint with your local Data Protection Authority as may be applicable. Alternatively, if you do not agree with the content of this Notice, then please remember that it is your choice whether you want to use the UPWARD SPIRAL Services or not.
   1. By agreeing to this Notice, you agree to UPWARD SPIRAL collecting, processing and using your personal data in accordance with this Notice and applicable laws, including European Union law and specifically the General Data Protection Regulation or "**GDPR**".
3. What is personal data?
   1. According to the GDPR:
      1. "personal data are any information which are related to an identified or identifiable natural person. The data subjects are identifiable if they can be directly or indirectly identified, especially by reference to an identifier such as a name, an identification number, location data, an online identifier or one of several special characteristics, which expresses the physical, physiological, genetic, mental, commercial, cultural or social identity of these natural persons. In practice, these also include all data which are or can be assigned to a person in any kind of way. For example, the telephone, credit card or personnel number of a person, account data, number plate, appearance, customer number or address are all personal data."
4. Your rights and your preferences: Choice and control
   1. UPWARD SPIRAL processes your personal data in the context of providing the UPWARD SPIRAL Services only when you have given us your consent to do so, and where processing your personal data is necessary for us to perform our obligations under our contract with you, and where this is allowed by applicable law or we have a legitimate interest to do so. We will only collect the necessary personal data we need (no more or less) and we will delete your personal data as soon as we no longer have grounds to process it.
   1. Applicable law, specifically the GDPR gives individuals and juristic persons certain rights relating to their personal data. In line with these laws, we have implemented additional data transparency and access controls to help users of an UPWARD SPIRAL Service to be able to exercise their data protection rights. Except as limited under applicable law, the rights afforded to you are:
      1. **Right to Withdraw Consent** - The right to withdraw your consent to the processing of your personal data at any time.
      1. **Right of Access** - The right to be informed of and request access to your personal data we collect and process. UPWARD SPIRAL will, at a minimum, comply with its obligations and prescribed processes under GDPR.
      1. **Right to Rectification** - The right to request that we amend or update your personal data if it is inaccurate or incomplete.
      1. **Right to Erasure** - The right to request that we delete your personal data.
      1. **Right to Restrict** - The right to request that we temporarily or permanently stop processing all or some of your personal data.
      1. **Right to Object** -
         1. The right, at any time, to object to us processing your personal data on grounds of a legitimate interest. Should you exercise this right, we will ask you to provide us with reasons for your objection which we will consider in order to determine whether a legitimate interest exists allowing us to continue processing your personal data or whether we should delete your data. We will communicate with you throughout this process.
         1. The right to object to your personal data being processed for direct marketing purposes.
      1. **Right to Data Portability** - The right to request a copy of your personal data in electronic format and the right to transmit that personal data to another party.
      1. **Right not to be subject to Automated Decision-making** - The right to not be subject to a decision based solely on automated decision making, including profiling, where the decision would have a legal effect on you or produce a similarly significant effect. Please see Section 7 'Automated decision making' for further details.
   1. In order to enable you to exercise these rights with ease and to record your preferences in relation to how UPWARD SPIRAL uses your personal data, we provide you with access to the following activities:
      1. **Privacy Settings** - Allow you to contact us and request changes to the management of your personal data and control whether we process personal data about you via:
         1. A 'Delete Account' or 'Delete Profile' request which enables you to delete all of the personal data UPWARD SPIRAL uses about you.
         1. A 'Request data' request which allows you to access your personal data and transfer it elsewhere as you see fit.
      1. **Email Preferences** - Allow you to choose which communications you receive from UPWARD SPIRAL.
   1. You may also request updates to your personal data and opt out of receiving certain messages from UPWARD SPIRAL. If we send you electronic marketing messages based on your consent or, as otherwise permitted by applicable law, you may, at any time, withdraw such consent or declare your objection ("**opt-out**") at no cost. The electronic marketing messages you receive from UPWARD SPIRAL (e.g. those sent via email) will also include an opt-out mechanism within the message itself (e.g. an unsubscribe link). Opt-outs may take a few days to process and, as a result, it may happen that you receive marketing communication even after opting-out where such opt-out has not yet been processed by UPWARD SPIRAL.
5. What personal data do we collect and how do we collect it?
   1. We collect your personal data in the following ways:
      1. **When you sign up for the UPWARD SPIRAL Service by setting up a candidate Profile** - When you create an UPWARD SPIRAL candidate Profile and choose to use this UPWARD SPIRAL Service, we collect certain personal data in order to deliver the UPWARD SPIRAL Service to you on the basis of the contract between us and your consent. This includes your name, email address, IP address, personal identifier, UserID, cookie information (if you have consented to our Cookie Declaration), address, identification number, contact number, birth date, postal code, employment and education history, profile picture, current salary, daily contracting rate, payment details, social media handles, resumes, location, work or study permits, education transcripts, certifications, performance data, data relating to any previous circumvention of the UPWARD SPIRAL Service in contravention of UPWARD SPIRAL's terms and conditions of use and other information you choose to share with us.
      1. Some of the personal data we will ask you to provide is required in order to create your Profile. We may ask you to provide additional or updated personal data from time to time in order to update or enhance your Profile. **When you sign up for the UPWARD SPIRAL Service by creating an employer or member Account** - When you create an UPWARD SPIRAL employer or member Account and choose to use this UPWARD SPIRAL Service, we collect certain personal data in order to deliver the UPWARD SPIRAL Service to you on the basis of the contract between us and your consent. This includes your name, a contact person, a contact email address, company/employer name, hiring location, number of employees, a contact phone number, profile picture, your job title, data relating to any previous circumvention of the UPWARD SPIRAL Service in contravention of UPWARD SPIRAL's terms and conditions of use and other information you choose to share with us.
      1. **When you sign up for or attend our events** - When you participate in an event or exercise conducted by UPWARD SPIRAL and agree to the terms of the event, we collect certain personal data from you in order to improve your profile on the basis of the contract between us and your consent. This includes your performance during these activities.
      1. **When you interact with any of our promotional or marketing material or any UPWARD SPIRAL Service** - when you interact with any of our promotional or marketing material or UPWARD SPIRAL Service, we collect certain personal data around your engagement in order to improve the UPWARD SPIRAL Service and the accuracy of our marketing and promotional activities. This includes Technical Data such as URL information, cookie data, your IP address, the types of devices you are using to access or connect to the UPWARD SPIRAL Service, unique device IDs, device attributes, browser type, language, information enabling digital rights management, operating system, and UPWARD SPIRAL application version. Further details about the technical data that is processed by us can be found in our Cookie Declaration available at[ ](https://www.offerzen.com)upwardspiral.io. This data is collected on the grounds of UPWARD SPIRAL having a legitimate interest in doing so.
      1. As far as reasonably possible, we use anonymised and aggregated information for purposes that include testing our IT systems, research, data analysis, creating marketing and promotion models, improving the UPWARD SPIRAL Service, and developing new features and functionality within the UPWARD SPIRAL Service. **Throughout your use of the UPWARD SPIRAL Service** - when you use the UPWARD SPIRAL Service, we collect personal data about your use of the UPWARD SPIRAL Service. This includes:
         1. The dates and times of any interview requests you make or receive, the number of interview requests you make or receive, interviews you have had and the outcomes of these interviews, offers and employment contracts you make or receive and the details of these offers and contracts, offers you withdraw, accept or turn down and the related reasons, profiles you view, and your interactions with other UPWARD SPIRAL users. This may also include details of your use of certain UPWARD SPIRAL features. This data is collected on the basis of the contract between us.
         1. Matches between UPWARD SPIRAL Profile holders, interactions between them and the outcomes of these interactions. This data is collected on the basis of the contract between us.
         1. User engagement, including messages you send and/or receive via UPWARD SPIRAL and your interactions with the UPWARD SPIRAL Customer Service team. This data is collected on the basis of the contract between us.
      1. **Personal data collected that enables us to provide you with additional features/functionality** - from time to time, you may also provide us with additional personal data or give us your permission to collect additional personal data e.g. to provide you with more features or functionality. You always have the option to change your mind and withdraw your consent at any time by requesting to delete your data.
      1. **From third parties** - we will receive personal data about you and your activity from third parties, including partners we work with in order to provide you with the best possible UPWARD SPIRAL Service (please see 'Sharing your personal data' below). We will use this personal data only where you have either provided your consent to this data being shared to the third party or to UPWARD SPIRAL or where UPWARD SPIRAL has a legitimate interest to use the personal data in order to provide you with the UPWARD SPIRAL Service.
      1. **Personal data collected that enables us to provide you with services we provide together with our partners** - from time to time, UPWARD SPIRAL will partner with third parties to deliver additional products, services or other value to persons who specifically sign up for these products, services or value. We will collect personal data about you when you sign up and we will use this personal data only where you have provided your consent to this data being shared with UPWARD SPIRAL's third party partner. At a minimum, we will collect your name and email address. The exact personal data collected will vary depending on the partner, additional service, product or value. Your personal data is used to allow you to sign up for or to be considered for any such service, product or additional value.
6. In order to make use of any such additional service, product or value, UPWARD SPIRAL will be required to share this data with its relevant partners and will ask you for consent before it does so.
   1. The following personal data will always be publicly available:
      1. **UPWARD SPIRAL Content Data** - In order for us to be able to publish legitimate content online, when you write content with us, we will publish your name, profile picture, social media handles, personal biography, and other information you choose to share with the public.
      1. **UPWARD SPIRAL Competition Data** - In order to market UPWARD SPIRAL competitions, when you agree to participate at an UPWARD SPIRAL event, we may publish your results, name, social media handles, personal biography, and other information you choose to share with the public.
   1. The following personal data may be shared by UPWARD SPIRAL in order to provide the UPWARD SPIRAL Service:
      1. **Candidate (UPWARD SPIRAL Profile holders) Data** - In order to provide the UPWARD SPIRAL Service, we will need to share your personal data from your UPWARD SPIRAL Profile with employers making use of UPWARD SPIRAL (UPWARD SPIRAL Account holders) and potential UPWARD SPIRAL Account holders. We will only share the minimum amount of data, anonymised where possible, required to provide or improve the UPWARD SPIRAL Service. Each UPWARD SPIRAL Account holder will act as a data controller in relation to the personal data that we supply to them; and their own privacy policy will govern their use of your personal data.
      1. **Employers (UPWARD SPIRAL Account holders) Data** - In order to provide the UPWARD SPIRAL Service, we will need to share your personal data from your UPWARD SPIRAL Account with UPWARD SPIRAL Profile holders and potential UPWARD SPIRAL Profile holders. We will only share the minimum amount of data, anonymised where possible, required to provide or improve the UPWARD SPIRAL Service.
      1. Information shared with service providers:
         1. We use technical service providers which may operate the technical infrastructure that we need to provide the UPWARD SPIRAL Service, in particular providers which host, store, manage, and maintain the UPWARD SPIRAL applications, our content and the data we process.
         1. We also use technical and non-technical service providers to help us communicate with you, to show you more tailored content, or to help us understand your use of the UPWARD SPIRAL Service, to provide you with a better service.
         1. UPWARD SPIRAL has taken measures to ensure that these parties will treat your data confidentially and in line with applicable laws and regulations.
   1. Information we may share with your consent:
      1. **UPWARD SPIRAL Partners** - To provide additional products, services or value together with our partners, we may partner with third parties with whom we may share your personal data, only with your consent. Each partner will act as a data controller in relation to the personal data that we supply to them; and their own privacy policy will govern their use of your personal data.
   1. Other reasons we may share your personal data:
      1. **Academic Research -** We will share your personal data for activities such as statistical analysis and academic study, but only in a pseudonymised format.
      1. **Other UPWARD SPIRAL Group Companies -** We will share your personal data with other UPWARD SPIRAL Group companies to carry out our daily business operations and to enable us to maintain and provide the UPWARD SPIRAL Service to you.
      1. **Law Enforcement and Data Protection Authorities** - We will share your personal data when we, in good faith, believe it is necessary for us to do so in order to comply with a legal obligation under applicable law, or respond to valid legal process, such as a search warrant, a court order, or a subpoena.
      1. **Purchasers of our business -** We may need to share some of your personal data in those cases where we sell or negotiate to sell our business to a buyer or prospective buyer. In this situation, UPWARD SPIRAL will continue to ensure the confidentiality of your personal data, anonymise your personal data where possible and give you notice before your personal data is transferred to the buyer or becomes subject to a different Privacy Notice.
   1. We will also share your personal data where we, in good faith, believe that it is necessary for the purpose of our own, or a third party's legitimate interest relating to national security, law enforcement, litigation, criminal investigation, protecting the safety of any person, or to prevent death or imminent bodily harm, provided that we deem that such interest is not overridden by your interests or fundamental rights and freedoms requiring the protection of your personal data.
7. Automated decision making
   1. By creating a candidate Profile on UPWARD SPIRAL, you are making use of the UPWARD SPIRAL Service. In order to provide the best possible UPWARD SPIRAL Service, not all candidate Profiles will meet certain pre-requisite criteria required for candidate Profiles to "go live" on UPWARD SPIRAL and, as a result, be made visible to UPWARD SPIRAL clients, members or employers with UPWARD SPIRAL Accounts.
   1. In order to determine whether certain minimum candidate Profile criteria have been met, UPWARD SPIRAL uses automated decision-making using the personal data provided by candidates when creating their UPWARD SPIRAL Profile.
   1. If you require further information about the criteria that UPWARD SPIRAL uses to assess candidate Profiles or the information we use to create candidate Profiles to justify our reliance on automated decision making under the GDPR, please see contact us.
   1. If you wish to object to UPWARD SPIRAL's candidate profiling, please contact us.
   1. If you wish to edit the personal data provided in creating your UPWARD SPIRAL Profile, you can do so by editing the data in your UPWARD SPIRAL Profile page on upwardspiral.io or contacting the talent advisor assigned to your Profile.
   1. UPWARD SPIRAL applies automated decision making to Profile candidates in a manner that does not discriminate based on sensitive personal data and based on the minimum amount of personal data required to make its assessment.
8. Data retention and deletion
   1. We keep your personal data only as long as necessary to provide you with the UPWARD SPIRAL Service and for legitimate and essential business purposes, such as maintaining the performance of the UPWARD SPIRAL Service, making data-driven business decisions about new features and offerings, complying with our legal obligations, and resolving disputes. By using an UPWARD SPIRAL Service, you are joining UPWARD SPIRAL's tech community and we will regularly be in touch with you. We keep some of your personal data for as long as you are a member of the UPWARD SPIRAL community or until such time as you request us to delete your Profile, Account or personal information.
   1. If you request, we will delete or anonymise your personal data so that it no longer identifies you, unless we are legally allowed or required to maintain certain personal data, including situations such as the following:
      1. If there is an unresolved issue relating to your Account or Profile, such as an unresolved claim or dispute we will retain the necessary personal data until the issue is resolved;
      1. Where we are required to retain the personal data for our legal, tax, audit, and accounting obligations, we will retain the necessary personal data for the period required by applicable law; and/or,
      1. Where necessary for our legitimate business interests such as fraud prevention or to maintain the legitimacy of the UPWARD SPIRAL Service.
9. Transfer to other countries
   1. UPWARD SPIRAL shares your personal data globally with other companies in the UPWARD SPIRAL Group in order to carry out the activities specified in this Notice. This includes to UPWARD SPIRAL Group companies in the following countries regions: Europe, America, Africa, and Oceania. UPWARD SPIRAL may also subcontract processing to, or share your personal data with, third parties located in countries other than your home country. UPWARD SPIRAL will, in these cases, ensure that Standard Contractual Clauses are agreed upon with these parties so as to ensure protection of your data.
   1. Personal data collected within the European Economic Area (EEA) may, for example, be transferred to and processed by third parties located in a country outside of the EEA. In such instances UPWARD SPIRAL shall ensure that the transfer of your personal data is carried out in accordance with applicable privacy laws and, in particular, that appropriate contractual, technical, and organisational measures are in place such as the Standard Contractual Clauses approved by the EU Commission.
10. Links
    1. We may display advertisements from third parties and other content that links to third-party websites. We cannot control or be held responsible for third parties' privacy practices and content. If you click on a third-party advertisement or link, please understand that you are leaving the UPWARD SPIRAL Service and any personal data you provide will not be covered by this Notice. Please read their privacy policies to find out how they collect and process your personal data.
    1. We are committed to protecting our users' personal data. We implement appropriate technical and organisational measures to help protect the security of your personal data; however, please note that no system is ever completely secure. We have implemented various measures including pseudonymisation, encryption, access, and retention policies to guard against unauthorised access and unnecessary retention of personal data in our systems.
    1. Your password protects your user Account or Profile, so we encourage you to use a unique and strong password, limit access to your computer and browser, and log out after having used the UPWARD SPIRAL Service.
11. Children
    1. The UPWARD SPIRAL Service is not directed to persons under the age of 18 years. However, in some countries, stricter age limits may apply under local law. Please see our Terms and Conditions of Use for further details.
    1. We do not knowingly collect personal data from persons under 18 years or under the applicable age limit (the "**Age Limit**"). If you are under the Age Limit, please do not use the UPWARD SPIRAL Service, and do not provide any personal data to us.
    1. If you are a parent of a child under the Age Limit and become aware that your child has provided personal data to UPWARD SPIRAL, please contact us using the information provided in the "How to Contact Us" section of this Privacy Notice, and you may request exercise of your applicable rights detailed in the 'Your rights and your preferences: Giving you choice and control' Section 3 of this Notice.
    1. If we learn that we have collected the personal data of a child under the age of 18 years, we will take reasonable steps to delete the personal data. This may require us to delete the UPWARD SPIRAL account for that child.
12. Changes to this Privacy Notice
    1. We may occasionally make changes to this Notice. The applicable version of this Notice will always be accessible on upwardspiral.io.
    1. When we make material changes to this Notice, we'll provide you with prominent notice as appropriate under the circumstances, e.g., by displaying a prominent notice within the UPWARD SPIRAL Service or by sending you an email. We may notify you in advance.
    1. Please, therefore, make sure you read any such notice carefully.
    1. If you want to find out more about this Notice and how UPWARD SPIRAL uses your personal data, please contact us to find out more. See the "How to Contact Us" section of this Notice for details.
13. How to contact us
    1. General and support please mail us at support@upwardspiral.io
    2. Marketing or communications at marketing@upwardspiral.io

Thank you for reading our Privacy Notice. If you have any questions about this Notice, please contact our privacy officer by emailing privacy@upwardspiral.io

UPWARD SPIRAL is the data controller or authorised representative of the data controller for the purposes of the personal data processed under this Notice.


We hope you enjoy UPWARD SPIRAL!
`

export const termsAndConditions = `
# **Terms and Conditions**
## **Definitions**
Throughout this document, the words "UPWARD SPIRAL," "us," "we," and "our," refer to our company (UPWARD SPIRAL for Services provided in our Terms and Conditions, our Site [upwardspiral.io](upwardspiral.io) or our Services, as is appropriate in the context of the use of the words.

"Applicant" refers to persons or entities who apply to participate in an UPWARD SPIRAL Programme.

"Candidate" refers to prospective employees who are seeking employment opportunities through UPWARD SPIRAL.

"Employer" refers to companies that have signed up to UPWARD SPIRAL with the intention to recruit Candidates, or to the individuals who represent those companies.

"Start Date" refers to the first working day of a Candidate at an Employer.

"Interview Request" refers to an expression of interest sent via UPWARD SPIRAL from an Employer to a Candidate. An Interview Request is non-binding and does not create any contractual obligations to either Employers or Candidates.

"Job Offer" is a formal, binding offer of employment from an Employer to a Candidate.

"UPWARD SPIRAL Programme" refers to any alternative programme, offering or project offered by UPWARD SPIRAL, including on or through its Site and whether in partnership with a third party or otherwise.

"Participant" refers to Applicants who are eligible to participate in an UPWARD SPIRAL Programme.

"Success Fee" is the fee charged to Employers by UPWARD SPIRAL on the successful hire of the Candidate.
## **UPWARD SPIRAL Services**
UPWARD SPIRAL is offering a way for Employers to connect with Candidates, and for those Candidates to express interest in specific companies.

As an Employer you acknowledge that we will not perform background checks on Candidates and that you are responsible for performing due diligence on all Candidates including, but not limited to, background checks, reference checks and checks for the accuracy of the provided information.

UPWARD SPIRAL also offers participation in UPWARD SPIRAL Programmes from time to time.
## **Candidates and Employers**
### **Roles and Responsibilities**
#### **Candidate's Role**
After your registration as a Candidate has been accepted by us, you will be able to make your profile visible to Employers. Employers will then have the opportunity to send non-binding Interview Requests to you. You must respond to Interview Requests within 48 hours, but you are not obliged to accept any Interview Request or contact any Employers that send an Interview Request to you.

Once an Employer has sent an Interview Request to you and you have responded to it, you may use other means of communication other than the UPWARD SPIRAL platform to communicate with the Employer. Until you have received an Interview Request from an Employer, you agree not to attempt to circumvent UPWARD SPIRAL by communicating outside UPWARD SPIRAL's platform. We may remove any Candidate that attempts to do this.
#### **Employer's Role**
After your registration as an Employer has been accepted on the UPWARD SPIRAL platform, you will be able to browse Candidates on UPWARD SPIRAL and submit Interview Requests.

Once you have identified a Candidate on UPWARD SPIRAL, you agree to initiate communication with the Candidate exclusively via an Interview Request. The Employer and the Candidate may use other means of communication once the Candidate has responded to the Interview Request.

If a Candidate accepts a Job Offer, you agree to inform UPWARD SPIRAL within five business days of the Candidate's acceptance, and provide the details, including the Start Date, remuneration and duration, along with a copy of the final accepted offer or employment contract. Where an Employer does not provide these details in a timely manner, or provides insufficient or incorrect details, UPWARD SPIRAL is entitled to estimate both the Candidate's remuneration and Start Date and invoice according to this estimation.

You agree not to attempt to circumvent UPWARD SPIRAL by attempting to communicate and hire a Candidate after discovering the Candidate on UPWARD SPIRAL. If you do this, there will be a Penalty Fee of up to 10% (excluding VAT) of the Candidate's annual total cost to company (CTC) in addition to the Success Fee and your access to UPWARD SPIRAL will be denied.
### **Payments and Refunds**
#### **For Candidates**
UPWARD SPIRAL is free for Candidates.

As a Candidate, you are required to promptly notify UPWARD SPIRAL within five business days if you accept a Job Offer, whether for an indefinite or fixed term. You shall also provide UPWARD SPIRAL with a copy of your signed offer letter or employment contract containing the key terms of the Job Offer, including your Start Date and remuneration.
#### **For Employers**
If a Candidate is identified through UPWARD SPIRAL and accepts a Job Offer within six months of the Candidate's UPWARD SPIRAL profile being visible to the Employer, you as the Employer will be charged a Success Fee, regardless of whether the Candidate accepts an Interview Request. The Success Fee (excluding VAT) is equal to 10% of the Candidate's annual total CTC, inclusive of all guaranteed income. UPWARD SPIRAL will invoice you within one week of the Candidate's Start Date and the Success Fee shall be due 30 days after the Start Date.

If you already had an Active Process with the Candidate, no Success Fee will be due. An Active Process means that there was at least one communication regarding a potential hiring (email, call, interview) between the Candidate and the Employer at least 90 days before the Candidate's profile was made visible on the UPWARD SPIRAL platform and such communication did not result in a rejection of the Candidate by the Employer.
#### **Refunds:**
If the Candidate or the Employer terminates the employment within 90 days of the Start Date for any reason other than redundancy, retrenchments or economic reasons, the paid Success Fee will be refunded to the Employer.

In order to claim your refund, you as the Employer, must notify UPWARD SPIRAL about the termination of the Candidate's employment and provide all the requested details. You must also have notified UPWARD SPIRAL about the original accepted Job Offer in accordance with our requirements set out above. A refund will only be due to the Employer where the relevant Success Fee was paid in accordance with UPWARD SPIRAL's payment terms.
#### **Fixed Term Contracting**
If a Candidate is hired on a fixed term contract of less than a year there are two options for the payment of the Success Fee, with Option 1 being the default unless you let us know otherwise.

**Option 1:**

The Employer pays the full Success Fee equal to 10% (excluding VAT) of the Candidate’s annualised monthly CTC, inclusive of all guaranteed income. The Employer will then be eligible for the refund if the employment contract is terminated within 3 months, provided they meet the Refund criteria set out above.

**Option 2:**

The Employer pays a Success Fee of 10% (excluding VAT) of the Candidate’s total CTC for the duration of the contract. If the Candidate is made permanent or their contract is extended, you agree to notify UPWARD SPIRAL of this, and you will be required to pay the remaining Success Fee of 10% (excluding VAT) of the Candidate’s annualised total CTC less the amount already paid. Should you choose this option you will not be eligible for a refund if the Candidate’s employment contract is terminated.
## **UPWARD SPIRAL Programmes**
As an Applicant, you acknowledge that (i) at UPWARD SPIRAL's sole discretion, you may or may not be eligible to participate in an UPWARD SPIRAL Programme; and (ii) where UPWARD SPIRAL Programmes are offered in partnership with any third parties, you may or may not be required to accept further terms and conditions of such third party as part of your application.

As a Participant, you agree to be bound by any further terms and conditions which may be applicable to an UPWARD SPIRAL Programme and which may be made available to you prior to your participation.

UPWARD SPIRAL reserves the right to limit application to and/or for participation in any UPWARD SPIRAL Programme.
## **General**
### **Cancellation**
At any time, and without cause, we may cancel or terminate your access to UPWARD SPIRAL or use of any Services at our sole discretion. You are not entitled to any kind of compensation if you request to cancel or terminate your account, any Service or participation in an UPWARD SPIRAL Programme and will continue to be liable for any remaining payment obligations.
### **Privacy Notice**
All of our interactions with you will be in accordance with our Privacy Notice. The Privacy Notice can be accessed on UPWARD SPIRAL [upwardspiral.io/privacy](/privacy) and will be updated from time to time as required. All questions around UPWARD SPIRAL's Privacy Notice or your personal data should be directed to privacy@upwardspiral.io.
### **Confidentiality**
You agree to keep all information gained from using UPWARD SPIRAL and/or any Service confidential.

You agree not to disclose the names or identities of any Candidates listed on UPWARD SPIRAL, outside of your recruiting or hiring department. You also agree to keep information about Interview Requests confidential.

You agree to keep your login details secret and not to divulge them in any form to any person or, in the case of company members, to any other company member.
### **Publicity**
UPWARD SPIRAL may include the name of any Employer in any document or website for marketing and publicity purposes.
### **Indemnification**
You agree to indemnify and hold UPWARD SPIRAL, its employees, its officers, and its agents harmless from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney's fees) arising from your access or use of the UPWARD SPIRAL platform and/or any Service.

By logging in and accessing UPWARD SPIRAL, the Site and/or applying to Participate in any UPWARD SPIRAL Programme, you acknowledge that you have read, understood, and agree to be legally bound by these Terms of Service, our Privacy Notice and our Code of Conduct [upwardspiral.io/code\\_of\\_conduct](/code\\_of\\_conduct/).

We may amend these conditions from time to time. By accessing this Site you are, and agree to be, bound to the version of our Terms of Use published here at the time of any visit to this Site.
### **Applicable law and jurisdiction**
These terms and conditions and any dispute, controversy, proceedings or claim of whatever nature arising out of or in any way relating to these terms and conditions or their formation (including any non-contractual disputes or claims), shall be governed by and construed in accordance with laws of (i) Australia where the Services are provided in Australia and the courts in Australia*** will have exclusive jurisdiction. UPWARD SPIRAL retains the right to have any dispute brought before any other competent court.
`
