import { Auth, API } from 'aws-amplify';

export const adminListEmployers = async (limit, nextToken) => {
  let apiName = 'AdminQueries';
  let path = '/listUsersInGroup';
  let myInit = {
    queryStringParameters: {
      "groupname": "employers",
      "limit": limit,
      "token": nextToken
    },
    headers: {
      'Content-Type' : 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
    }
  }
  return await API.get(apiName, path, myInit);
}
export const adminListCandidates = async (limit, nextToken, currData) => {
  let apiName = 'AdminQueries';
  let path = '/listUsersInGroup';
  let myInit = {
    queryStringParameters: {
      "groupname": "candidates",
      "limit": limit || 20,
      "token": nextToken
    },
    headers: {
      'Content-Type' : 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
    }
  }
  const { Users, NextToken } = await API.get(apiName, path, myInit);
  const fwd = currData ? [...currData, ...Users] : Users;
  if (NextToken) {
    return await adminListCandidates(null, NextToken, fwd)
  }
  return { Users: fwd };
}
export const adminListAdministrators = async (limit, nextToken) => {
  let apiName = 'AdminQueries';
  let path = '/listUsersInGroup';
  let myInit = {
    queryStringParameters: {
      "groupname": "administrators",
      "limit": limit,
      "token": nextToken
    },
    headers: {
      'Content-Type' : 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
    }
  }
  return await API.get(apiName, path, myInit);
}
export const adminListUsers = async (limit, nextToken, currData) => {
  let apiName = 'AdminQueries';
  let path = '/listUsers';
  let myInit = {
    queryStringParameters: {
      "limit": limit,
      "token": nextToken
    },
    headers: {
      'Content-Type' : 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
    }
  }
  const { Users, NextToken } = await API.get(apiName, path, myInit);
  const fwd = currData ? [...currData, ...Users] : Users;
  if (NextToken) {
    return await adminListUsers(null, NextToken, fwd)
  }
  return { Users: fwd };
}

export const adminListGroupsForUser = async (username) => {
  let apiName = 'AdminQueries';
  let path = `/listGroupsForUser?username=${username}`;
  let myInit = {
    headers: {
      'Content-Type' : 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
    }
  }
  return await API.get(apiName, path, myInit);
}

export const adminRemoveUserFromGroup = async (username, groupname) => {
  if (!username || !groupname) {
    throw new Error('Username and groupname are required top remove from group');
  }
  let apiName = 'AdminQueries';
  let path = '/removeUserFromGroup';
  let myInit = {
    body: {
      username,
      groupname,
    },
    headers: {
      'Content-Type' : 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
    }
  }
  return await API.post(apiName, path, myInit);
}

export const adminAddUserToGroup = async (username, groupname) => {
  if (!username || !groupname) {
    throw new Error('Username and groupname are required top remove from group');
  }
  let apiName = 'AdminQueries';
  let path = '/addUserToGroup';
  let myInit = {
    body: {
      username,
      groupname,
    },
    headers: {
      'Content-Type' : 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
    }
  }
  return await API.post(apiName, path, myInit);
}
