import React from 'react';
import { withRouter } from 'react-router-dom'
import { Button } from '@mui/material';

const RedirectButton = ({
  className,
  color,
  history,
  redirectTo,
  onClick,
  buttonText,
  variant,
  disabled,
}) => {
  const redirect = () => history.push(redirectTo);

  return (
      <Button
          disabled={disabled}
          color={color}
          variant={variant}
          className={className ? className : ''} onClick={() => {
        if (onClick) {
          onClick();
        }
        redirect();
      }}>
        { buttonText }
      </Button>
  );
}

export default withRouter(RedirectButton);
