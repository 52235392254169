export const engineeringWorkCategories = Object.freeze([
  'Analyst Software Engineer',
  'Junior Software Engineer',
  'Intermediate Software Engineer',
  'Senior Software Engineer',
  'Principal Software Engineer',
  'Solution Architect',
  'Enterprise Architect',
  'Web Developer',
  'Front-End Engineer',
  'Back-End Engineer',
  'Full Stack Engineer',
  'DevOps Engineer',
  'Tech Lead',
  'Team Lead',
  'Engineering Manager',
  'Chief Technical Officer',
  'Chief Information Officer'
]);

export const cyberWorkCategories = Object.freeze([
  'Chief Information Security Officer',
  'Chief Privacy Officer',
  'Security Analysis',
  'Computer Forensics',
  'Threat Intelligence Analysis',
  'Security Consulting',
  'Digital Forensics',
  'Cryptography',
  'Cloud Security',
  'Security Administration',
  'Penetration Testing',
  'Security Software Development',
  'Security Specialised (specialist)',
  'Security Code Auditing',
  'Security Architecture',
  'Malware Analysis',
  'Data Protection',
  'Cybercrime Investigation',
  'Cryptanalyst',
  'Security Incident Response',
  'Risk Management',
  'Governance, Risk and Compliance',
  'Project Management',
  'Team Management',
  'Vulnerability Management',
]);

export const dataScienceWorkCategories = Object.freeze([]);

export const testingWorkCategories = Object.freeze([]);

export const UIUXWorkCategories = Object.freeze([]);

export const candidateTypeOptions = Object.freeze([
  {
    label: 'Software Engineer',
    value: 'software_engineer',
    available: true,
    categories: engineeringWorkCategories
  },
  {
    label: 'Cyber Security',
    value: 'cyber_sec',
    available: true,
    categories: cyberWorkCategories,
  },
  {
    label: 'Data Science',
    value: 'data_science',
    available: false,
    categories: dataScienceWorkCategories
  },
  {
    label: 'QA / Testing',
    value: 'qa_testing',
    available: false,
    categories: testingWorkCategories
  },
  {
    label: 'UI/UX',
    value: 'ui_ux',
    available: false,
    categories: UIUXWorkCategories
  },
]);

export const roleMapping = {};
candidateTypeOptions.forEach((option) => {
  option.categories.forEach((category) => {
    roleMapping[category] = option.label;
  });
});

