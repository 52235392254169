import React, { useContext, useEffect, useState } from 'react';
import {
  Col,
  Row
} from 'reactstrap';
import AppContext from '../../contexts/AppContext';
import {
  Autocomplete, Chip,
  FormControl, Input, InputAdornment,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import {
  languages,
  currencies,
  yearOptions,
  candidateTypeOptions, roleMapping
} from '../../constants';
import { OnboardingHeader } from '../onboarding/OnboardingHeader';
import { OnboardingFooter } from '../onboarding/OnboardingFooter';

export const CandidateExperienceStep = ({ back, next }) => {
  const { user: [usr], onBoarding: [obv, setObv] } = useContext(AppContext);
  const [selectedLanguages, setSelectedLanguages] = useState(obv.programmingLanguages);
  const [workCategories, setWorkCategories] = useState(obv.workCategories || []);
  const [currentCurrency, setCurrentCurrency] = useState(obv.salaryCurrency);
  const [currentSalary, setCurrentSalary] = useState(obv.salary || null);
  const [desiredCurrency, setDesiredCurrency] = useState(obv.desiredCurrency);
  const [desiredSalary, setDesiredSalary] = useState(obv.desiredSalary || null);

  const disableNext = () => {
    const hasDesiredSalary = !!desiredSalary && desiredSalary != '';
    const hasCategories = workCategories.length > 0;
    return !hasDesiredSalary || !hasCategories;
  }

  const saveRole = (val) => {
    setWorkCategories(val);
    obv.workCategories = val;
    setObv(obv);
  };

  const languageChoice = (val) => {
    setSelectedLanguages(val);
    obv.programmingLanguages = val;
    setObv(obv);
  }

  const salaryElected = (value) => {
    setCurrentSalary(value);
    obv.salary = parseInt(value);
    setObv(obv);
  }
  const currentCurrencyChange = (e) => {
    const value = e.target.value;
    setCurrentCurrency(value);
    obv.salaryCurrency = value;
    setObv(obv);
  }
  const desiredSalaryElected = (value) => {
    setDesiredSalary(value);
    obv.desiredSalary = parseInt(value);
    setObv(obv);
  }
  const desiredCurrencyChange = (e) => {
    const value = e.target.value;
    setDesiredCurrency(value);
    obv.desiredCurrency = value;
    setObv(obv);
  }
  const getCurrency = (abr) => {
    return currencies.find((currency) => currency.abr === abr);
  }
  const getWorkCategories = () => {
    return obv.candidateTypes
      .map((type) => candidateTypeOptions
          .find(({ value }) => type === value))
      .map(({ categories }) => categories).flat();
  }

  return (
      <>
        <div className="d-flex flex-column px-4 h-100">
          <OnboardingHeader
              header={`Welcome ${usr.name && usr.name}`}
              subHeader={`Let's get to know you`} />
          <div className="mt-5 py-5 border-top">
            <Row>
              <Col xs="12">
                <div className="mb-1">
                  <b>
                    How many (total) years of experience do you have?
                  </b>
                </div>
                <FormControl className="w-100">
                  <Select
                      variant="standard"
                      onChange={(e) => {
                        setObv({
                          ...obv,
                          yearsExperience: e.target.value,
                        })
                      }}
                      value={
                        obv.yearsExperience || ''
                      }
                  >
                    {yearOptions.map(({ value, text}, idx) => {
                      return (
                          <MenuItem value={value} key={value}>
                            {text}
                          </MenuItem>
                        )
                      })
                    }
                  </Select>
                </FormControl>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col xs="12">
                <div className="mb-2">
                  <b>
                    Select the languages you're familiar with
                  </b>
                  {' (opt.)'}
                </div>
                <Autocomplete
                    multiple={true}
                    filterSelectedOptions={true}
                    value={selectedLanguages || null}
                    onChange={(e, value) => languageChoice(value)}
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                            <Chip color="primary" label={option} {...getTagProps({ index })} />
                        ))
                    }
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            variant="standard"
                            placeholder="Languages"
                        />}
                    options={languages} />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col xs="12">
                <div className="mb-2">
                  <b>
                    Select your categories
                  </b>
                </div>
                <Autocomplete
                    multiple={true}
                    filterSelectedOptions={true}
                    value={workCategories || null}
                    onChange={(e, value) => saveRole(value)}
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                            <Chip color="primary" label={option} {...getTagProps({ index })} />
                        ))
                    }
                    groupBy={(option) => {
                      return roleMapping[option];
                    }}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            variant="standard"
                            placeholder="Categories"
                        />}
                    options={getWorkCategories()} />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col xs={12}>
                <div>
                  <b>
                    Salary
                  </b>
                  <div>
                    <span>
                      What is your annual income package
                      <b>{' '}(full annual package)</b> (opt.)
                    </span>
                  </div>
                </div>
                <div className="d-flex flex-row w-100">
                  <FormControl variant="standard" className="w-auto">
                    <Select
                        variant="standard"
                        value={getCurrency(currentCurrency).abr}
                        onChange={(e) => currentCurrencyChange(e)}
                    >
                    {currencies.map(({ abr, name}, idx) => {
                      return (
                          <MenuItem key={`${abr}-${idx}`} value={abr}>
                            {abr}
                          </MenuItem>
                      )
                    })}
                    </Select>
                  </FormControl>
                  <FormControl variant="standard" className="flex-grow-1">
                    <Input
                        id="input-currency"
                        value={currentSalary || ''}
                        placeholder="Current salary"
                        onChange={(e) => salaryElected(e.target.value)}
                        startAdornment={
                          <InputAdornment position="start">
                            {getCurrency(currentCurrency).symbol}
                          </InputAdornment>
                        }
                    />
                  </FormControl>
                </div>
              </Col>
              <Col xs={12}>
                <div className="mt-3">
                  <b>
                    Nominated Salary
                  </b>
                  <div>
                    <span>
                      How much would you like to earn <b>(full annual package)</b>
                    </span>
                  </div>
                </div>
                <div className="d-flex flex-row w-100">
                  <FormControl variant="standard" className="w-auto">
                    <Select
                        variant="standard"
                        value={getCurrency(desiredCurrency).abr}
                        onChange={(e) => desiredCurrencyChange(e)}
                    >
                      {currencies.map(({ abr, name}, idx) => {
                        return (
                            <MenuItem key={`${abr}-${name}`} value={abr}>
                              {abr}
                            </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                  <FormControl variant="standard" className="flex-grow-1">
                    <Input
                        id="input-currency-desired"
                        value={desiredSalary || ''}
                        placeholder="Desired salary"
                        onChange={(e) => desiredSalaryElected(e.target.value)}
                        startAdornment={
                          <InputAdornment position="start">
                            {getCurrency(desiredCurrency).symbol}
                          </InputAdornment>
                        }
                    />
                  </FormControl>
                </div>
              </Col>
            </Row>
          </div>
          <OnboardingFooter
            nextDisabled={disableNext}
            next={next}
            back={back}
          />
        </div>
      </>
  );
}
