import React from 'react';
import { Col, Row } from 'reactstrap';
import { getNoticeText, getYearText } from '../../utils/UserUtils';
import { Chip } from '@mui/material';
import formatter from 'currency-formatter';
import { getCurrency } from '../../constants';
import MDEditor from '@uiw/react-md-editor';

export const CandidatePreview = ({ user }) => {
  if (!user || !user.candidate) {
    return <></>;
  }
  const obv = { ...user.candidate };
  const currencyStr = formatter.format(obv.desiredSalary,
      {code: getCurrency(obv.desiredCurrency).abr});
  return (
      <Row className="pr-2">
        <Col xs={12}>
          <h4>
            {user.name}
          </h4>
          <h5>
            Candidate Details
          </h5>
          <h6>
            {getYearText(obv.yearsExperience)} experience
          </h6>
          <h6>
            Wants <b>{currencyStr}</b> (annually)
          </h6>
          <div className="py-2">
            <b>
              Notice period
            </b>
            <div>
              {getNoticeText(obv.availability)}
            </div>
          </div>
          <div className="py-2">
            {obv.countries.length &&
            <>
              <b>
                Countries
              </b>
              {obv.countries.map((country) => {
                return <div key={country}>
                  {country}
                </div>
              })
              }
            </>
            }
          </div>
          <div className="py-2">
            <div className="font-weight-bold">
              Cities
            </div>
            <Row>
              <Col xs={12}>
                {obv?.cities?.map((category, i) => (
                    <Chip key={`${category}-${i}`} className="mr-1 mt-1"
                          color="primary" label={category}/>
                ))
                }
              </Col>
            </Row>
          </div>
          <div className="py-2">
            <b>Employment Type(s)</b>
            {obv.workType.map((type) => {
              return <div key={type}>
                {type}
              </div>
            })
            }
          </div>
          <div className="py-2">
            {obv.openToRemote ?
                <Chip className="mr-1 mb-1"
                      color="success"
                      label={`Open to remote roles`}/>
                :
                <Chip className="mr-1 mb-1"
                      style={{backgroundColor: '#d32f2f', color: 'white'}}
                      label={`No remote roles`}/>
            }
          </div>
          <div className="py-2">
            <div className="font-weight-bold">
              Roles
            </div>
            <Row>
              <Col xs={12}>
                {obv?.workCategories.map((category, i) => (
                    <Chip key={`${category}-${i}`} className="mr-1 mt-1"
                          color="primary" label={category}/>
                ))
                }
              </Col>
            </Row>
          </div>
          {obv?.programmingLanguages.length > 0 &&
          <div className="py-2">
            <div className="font-weight-bold">
              Languages
            </div>
            <Row>
              <Col xs={12} className="pt-1">
                {obv?.programmingLanguages?.map((lang, i) => (
                    <Chip key={`${lang}-${i}`} className="mr-1 mt-1"
                          label={lang}/>
                ))
                }
              </Col>
            </Row>
          </div>
          }
          {user.description &&
              <>
                <div className="pt-2">
                  <b>Description</b>
                </div>
                <MDEditor.Markdown source={user.description} />
              </>
          }
        </Col>
      </Row>
  )
}
