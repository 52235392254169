import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
} from 'react-router-dom';
import { API, Auth, DataStore, Hub } from 'aws-amplify';
import { makeStyles } from '@mui/styles';

import * as mutations from './graphql/mutations';
import * as queries from './graphql/queries';
import Navbar from './components/nav/TopNavbar';
import AppContext from './contexts/AppContext';
import PrivateRoute from './components/route/PrivateRoute';
import { LandingPage } from './pages/LandingPage';
import { LoginPage } from './pages/LoginPage';
import { ResetPage } from './pages/ResetPage';
import { VerifyAccountPage } from './pages/VerifyAccountPage';
import { EmployerPage } from './pages/employer/EmployerPage';
import { PendingReview } from './pages/PendingReview';
import { EditPage } from './pages/EditPage';
import { PreviewPage } from './pages/PreviewPage';
import { ChatPage } from './pages/ChatPage';
import { OnboardingPage } from './pages/OnboardingPage';
import { SigningInPage } from './pages/SigningInPage';
import { bootstrapUser } from './utils/UserUtils';
import { TermsPage } from './pages/TermsPage';
import { PrivacyPage } from './pages/PrivacyPage';
import { CodeOfConductPage } from './pages/CodeOfConductPage';

import { BlogsPage } from './pages/BlogsPage';
import { NotFoundPage } from './pages/NotFoundPage';
import { BlogPage } from './pages/BlogPage';
import { OrbitSpinner } from './components/loader/Loaders';
import { AdminPage } from './pages/AdminPage';
import { HirePage } from './pages/HirePage';
import { DisablePage } from './pages/DisablePage';

const styles = makeStyles({
  navbarPadding: {
    paddingBottom: '6.5vh',
  },
});

const App = () => {
  const classes = styles();
  const auth = useState();
  const [authenticated, setAuthenticated] = auth;
  const user = useState();
  const [currUser, setCurrentUser] = user;
  const history = useHistory();
  const saving = useState(false);
  const loading = useState();
  const loadingMessage = useState('');
  const [isLoading, setIsLoading] = loading;
  const msgContext = useState();
  const onBoarding = useState();
  const previewUsr = useState();
  const oauthErr = useState();
  const [, setOAuthErr] = oauthErr;
  const navTab = useState(0);
  let appCtx = {
    auth,
    user,
    saving,
    onBoarding,
    loading,
    loadingMessage,
    msgContext,
    previewUsr,
    navTab,
    oauthErr,
  };

  useEffect(() => {
    const authHook = async () => {
      try {
        const authedUser = await Auth.currentAuthenticatedUser();
        const userEmail = authedUser.signInUserSession.idToken.payload.email;
        const owner = authedUser.signInUserSession.idToken.payload['cognito:username'];
        const groups = authedUser.signInUserSession.accessToken.payload['cognito:groups'];
        const { data: { userByOwner: { items }}} = await API.graphql(
            { query: queries.userByOwner,
              variables: {
                owner: owner,
                limit: 1,
              }
            }

        );
        const user = items.pop();
        if (!authenticated) {
          setAuthenticated({
            ...authedUser,
            groups,
            email: userEmail
          });
        }
        if (!currUser || (currUser?.id !== user?.id)) {
          setCurrentUser(user);
        }
      } catch (err) {
        console.error(err);
        await Auth.signOut();
        if (authenticated) {
          setAuthenticated();
        }
      }
    };
    const hubListener = Object.freeze(async(event) => {
      switch (event.payload.event) {
        case 'signIn':
          await DataStore.stop();
          await DataStore.start();
          const { data } = event.payload;
          const userEmail = data.signInUserSession.idToken.payload.email;
          const groups = data.signInUserSession.accessToken.payload['cognito:groups'];
          const { data: { userByEmail: { items }}} = await API.graphql(
              { query: queries.userByEmail,
                variables: {
                email: userEmail,
              }}
          );
          const current = items.filter((item) => !item._deleted);
          setAuthenticated({
            email: userEmail,
            groups,
            ...data,
          });
          let user = current.find(({ email }) => email === userEmail);
          if (!user) {
            const newUsr = bootstrapUser(userEmail, data.username);
            const { data: { createUser }} = await API.graphql(
                { query: mutations.createUser, variables: { input: newUsr }}
            );
            const { data: { getUser }} = await API.graphql(
                { query: queries.getUser, variables: { id: createUser.id }}
            );
            user = getUser;
          }
          setCurrentUser(user);
          if (isLoading) {
            setIsLoading(false);
          }
          setOAuthErr();
          break;
        case 'signUp':
          break;
        case 'signOut':
          if (authenticated) {
            setAuthenticated();
          }
          if (currUser) {
            setCurrentUser();
          }
          DataStore.clear();
          break;
        case 'signIn_failure':
          if (authenticated) {
            setAuthenticated();
          }
          break;
        case 'tokenRefresh':
          break;
        case 'tokenRefresh_failure':
          // await DataStore.clear();
          break;
        case 'configured':
          break;
        default:
          return;
      }
    });
    Hub.listen('auth', hubListener);
    authHook();
    return async () => {
      Hub.remove('auth', hubListener);
    };
  }, [authenticated, currUser, setCurrentUser, history]);

  return (
      <AppContext.Provider value={appCtx}>
        <Router>
          <div className={authenticated && classes.navbarPadding}>
            <Navbar />
          </div>
          {isLoading ?
              <div className="d-flex flex-column vh-100 justify-content-center align-items-center">
                <OrbitSpinner />
                <h2 className="pt-5">{loadingMessage[0]}</h2>
              </div>
              :
              <Switch>
                <Route exact path="/" >
                  <LandingPage />
                </Route>
                <Route exact path="/hire" >
                  <HirePage />
                </Route>
                <Route path="/blog/:blogName">
                  <BlogPage />
                </Route>
                <Route path="/blog">
                  <BlogsPage />
                </Route>
                <Route exact path="/signin" >
                  <SigningInPage />
                </Route>
                <Route path="/login">
                  <LoginPage />
                </Route>
                <Route path="/code_of_conduct">
                  <CodeOfConductPage />
                </Route>
                <Route path="/reset">
                  <ResetPage />
                </Route>
                <Route path="/privacy">
                  <PrivacyPage />
                </Route>
                <Route path="/terms">
                  <TermsPage />
                </Route>
                <Route path="/verify">
                  <VerifyAccountPage />
                </Route>
                <PrivateRoute path="/admin" roles={['administrators']}>
                  <AdminPage />
                </PrivateRoute>
                <PrivateRoute path='/disable'>
                  <DisablePage />
                </PrivateRoute>
                <PrivateRoute path="/chat">
                  <ChatPage />
                </PrivateRoute>
                <PrivateRoute path="/pending">
                  <PendingReview />
                </PrivateRoute>
                <PrivateRoute path="/employer">
                  <EmployerPage />
                </PrivateRoute>
                <PrivateRoute path="/preview">
                  <PreviewPage />
                </PrivateRoute>
                <PrivateRoute path="/onboarding">
                  <OnboardingPage />
                </PrivateRoute>
                <PrivateRoute path="/you/edit">
                  <EditPage />
                </PrivateRoute>
                <PrivateRoute path="/you">
                  <PreviewPage />
                </PrivateRoute>
                <Route path="*">
                  <NotFoundPage />
                </Route>
              </Switch>
          }
        </Router>
      </AppContext.Provider>
  );
}

export default App;
