import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import AppContext from '../../contexts/AppContext';

export const PrivateRoute = ({ children, path, roles = [] }) => {
  const { auth: [authenticated] } = useContext(AppContext);

  if (!authenticated) {
    return (
        <Redirect to="/"/>
    )
  }
  if (roles.length > 0 && !roles.some((role) => authenticated.groups.find((grp) => grp === role))) {
    return (
        <Redirect to="/"/>
    )
  }

  return (
      <Route path={path}>
        {children}
      </Route>
  );
}

export default PrivateRoute;
