import React, { useEffect, useState } from 'react';

import {
  Container,
  Col,
  Card,
  CardHeader,
  CardImg,
  CardTitle,
  CardBody,
  FormGroup,
  InputGroup,
  InputGroupText,
} from 'reactstrap';
import clsx from 'clsx';
import resetImg from '../assets/img/ill/bg5-1.svg';
import bgImg from '../assets/img/ill/reset.svg';
import { Auth } from 'aws-amplify';
import { getSuccessAlert, getVerifiedAlert } from '../utils/AlertUtils';
import RedirectButton from '../components/buttons/RedirectButton';
import { useFormik } from 'formik';
import { resetPassSchema, updatePassSchema } from '../utils/SchemaUtils';
import {
  Box,
  Button,
  Checkbox, CircularProgress,
  FormControlLabel,
  TextField
} from '@mui/material';
import { useHistory } from 'react-router-dom';

export const ResetPage = () => {
  const history = useHistory();
  const [sending, setSending] = useState(false);
  const [reset, setReset] = useState(false);
  const [loginAfterReset, setLoginAfterReset] = useState(false);
  const [err, setErr] = useState();
  const [showReset, setShowReset] = useState(false);
  const [passwordUpdated, setPasswordUpdated] = useState(false);

  const formikReset = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: resetPassSchema,
    onSubmit: async (values) => {
      setSending(true);
      setReset(false);
      try {
        await Auth.forgotPassword(values.email);
        await formikVerify.setFieldValue('email', values.email, false);
      } catch (err) {
        console.error(err);
        setErr(err?.message);
      } finally {
        setReset(true);
        setSending(false);
      }
    }
  });

  const formikVerify = useFormik({
    initialValues: {
      email: '',
      verificationCode: '',
      password: '',
    },
    validationSchema: updatePassSchema,
    onSubmit: async (values) => {
      setPasswordUpdated(false);
      setSending(true);
      const { email, password, verificationCode } = values;
      try {
        await Auth.forgotPasswordSubmit(
            email,
            verificationCode,
            password,
        );
        if (loginAfterReset) {
          setShowReset(true);
          await Auth.signIn({
            username: email,
            password,
          });
          history.push('/');
        } else {
          setPasswordUpdated(true);
        }
      } catch (err) {
        setErr(err?.message);
      } finally {
        setSending(false);
      }
    }
  });

  const updatePassword = async () => formikVerify.handleSubmit();
  const resetPassword = async () => formikReset.handleSubmit();

  useEffect(() => {
    document.body.classList.add('reset-page');
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return () => {
      document.body.classList.remove('reset-page');
    }
  }, []);

  return (
    <>
      <div className="wrapper">
        <div className="page-header bg-default">
          <div
            className="page-header-image"
            style={{
              backgroundImage:
                `url("${bgImg}")`,
            }}
          ></div>
          <Container>
            <Col className="mx-auto" lg="5" md="8">
              <Card className="bg-secondary shadow border-0">
                <CardHeader>
                  <CardImg
                      alt="..."
                      src={resetImg}
                  ></CardImg>
                  <CardTitle className="text-center" tag="h4">
                    Reset Password
                  </CardTitle>
                </CardHeader>
                <CardBody className="px-lg-5 py-lg-5">
                  <div className="text-center text-muted mb-4">
                    <small>Enter email address to reset password</small>
                  </div>
                  <Box component="form">
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <TextField
                            variant="standard"
                            placeholder="Email"
                            type="email"
                            name="email"
                            id="verify-email"
                            fullWidth
                            value={formikReset.values.email}
                            onChange={formikReset.handleChange}
                            error={formikReset.touched.email && Boolean(formikReset.errors.email)}
                            helperText={formikReset.touched.email && formikReset.errors.email}
                            InputProps={{
                              startAdornment:
                                  <InputGroupText>
                                    <i className={clsx("ni ni-email-83", Boolean(formikReset.touched.email && formikReset.errors.email) && "text-red")}></i>
                                  </InputGroupText>,
                            }}
                        ></TextField>
                      </InputGroup>
                      {reset &&
                        <>
                          <InputGroup className="pt-2 input-group-alternative">
                            <TextField
                                variant="standard"
                                placeholder="Verification code"
                                type="text"
                                name="verificationCode"
                                id="reset-authcode"
                                fullWidth
                                value={formikVerify.values.verificationCode}
                                onChange={formikVerify.handleChange}
                                error={formikVerify.touched.verificationCode && Boolean(formikVerify.errors.verificationCode)}
                                helperText={formikVerify.touched.verificationCode && formikVerify.errors.verificationCode}
                                InputProps={{
                                  startAdornment:
                                      <InputGroupText>
                                        <i className={clsx("ni ni-key-25", Boolean(formikVerify.touched.verificationCode && formikVerify.errors.verificationCode) && "text-red")}></i>
                                      </InputGroupText>,
                                }}
                            ></TextField>
                          </InputGroup>
                          <InputGroup className="pt-2 input-group-alternative">
                            <TextField
                                variant="standard"
                                placeholder="New password"
                                type="password"
                                name="password"
                                id="signup-password"
                                fullWidth
                                value={formikVerify.values.password}
                                onChange={formikVerify.handleChange}
                                error={formikVerify.touched.password && Boolean(formikVerify.errors.password)}
                                helperText={formikVerify.touched.password && formikVerify.errors.password}
                                InputProps={{
                                  startAdornment:
                                    <InputGroupText>
                                      <i className={clsx("ni ni-lock-circle-open", Boolean(formikVerify.touched.password && formikVerify.errors.password) && "text-red")}></i>
                                    </InputGroupText>,
                                }}
                            ></TextField>
                          </InputGroup>
                          <FormControlLabel
                              label="Login after reset"
                              control={
                                <Checkbox
                                    checked={loginAfterReset}
                                    onChange={() => setLoginAfterReset(!loginAfterReset)}
                                />
                              }
                          />
                        </>
                      }
                    </FormGroup>
                    <div className="text-center">
                      {showReset && getVerifiedAlert('Successfully verified')}
                      {(reset && !passwordUpdated && !showReset) && getSuccessAlert(
                          'If that account exists, a recovery code will been sent to'
                          + ' reset your password'
                      )}
                      {passwordUpdated && getSuccessAlert(
                          'Your password has been reset successfully'
                      )}
                      {sending ?
                          <CircularProgress className="mt-3" />
                          :
                          reset ?
                              passwordUpdated ?
                                      <RedirectButton
                                        redirectTo="/login"
                                        buttonText="Go to login"
                                      />
                                  :
                                  <div className="d-flex flex-row my-4 justify-content-center">
                                    <RedirectButton
                                        redirectTo="/login"
                                        buttonText="Cancel"
                                        variant="outlined"
                                    />
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={updatePassword}
                                        className="ml-2">
                                      Reset
                                    </Button>
                                  </div>
                              :
                              <div className="d-flex flex-row my-4 justify-content-center">
                                <RedirectButton
                                    redirectTo="/login"
                                    buttonText="Cancel"
                                    variant="outlined"
                                />
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={resetPassword}
                                    className="ml-2"
                                    type="button">
                                  Send
                                </Button>
                              </div>
                      }
                    </div>
                  </Box>
                </CardBody>
              </Card>
            </Col>
          </Container>
        </div>
      </div>
    </>
  );
}
