import React, { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import AppContext from '../../contexts/AppContext';
import { Button, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { OnboardingHeader } from '../onboarding/OnboardingHeader';
import { candidateTypeOptions } from '../../constants';
import { OnboardingFooter } from '../onboarding/OnboardingFooter';

export const CandidateCategory = ({ next }) => {
  const {
    onBoarding: [obv, setObv]
  } = useContext(AppContext);

  const disableNext = () => obv.candidateTypes.length === 0;

  const toggleSelection = (e) => {
    const category = e.target.value;
    const checked = e.target.checked;
    if (checked) {
      setObv({
        ...obv,
        candidateTypes: [
            ...new Set([
              ...obv.candidateTypes,
              category,
            ]),
        ]
      })
    } else {
      setObv({
        ...obv,
        candidateTypes: obv.candidateTypes.filter((type) => type != category)
      })
    }
  };

  return (
      <>
          <div className="px-4">
            <OnboardingHeader
                header={``}
                subHeader="Which of the following industries would you be
                           interested in working in?" />
            <div className="mt-5 py-5 border-top no-select">
              {candidateTypeOptions
                .map(({ label, value, available }, idx) => {
                return (
                    <Row key={`${value}_${idx}`}>
                      <Col xs={12}>
                        <FormGroup>
                          <FormControlLabel
                              control={
                                <Checkbox
                                    value={value}
                                    disabled={!available}
                                    checked={!!obv.candidateTypes.find(
                                        (candType) => candType === value)}
                                    onChange={(e) => toggleSelection(e)} />
                              }
                              label={label} />
                        </FormGroup>
                      </Col>
                    </Row>
                )
              })}
              <Row>
                <Col className="mx-auto" lg="8" md="12">
                  <p className="description mb-1 text-center">
                    This information allows us to best match you with
                    potential employers.
                  </p>
                  <p className="description mb-1 text-center">
                    More isn't necessarily better in this case
                  </p>
                </Col>
              </Row>
            </div>
            <OnboardingFooter
                nextDisabled={disableNext}
                next={next}
            />
          </div>
        </>
      )
}
