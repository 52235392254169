import React, { useEffect } from "react";

// reactstrap components
import { Row, Col } from "reactstrap";
import bgImg from '../assets/img/ill/404.svg';
import { Container, Button } from '@mui/material';
import { useHistory } from 'react-router-dom';

export const NotFoundPage = () => {
  const history = useHistory();

  useEffect(() => {
    document.body.classList.add("error-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("error-page");
    };
  }, []);

  return (
    <>
      <div className="wrapper">
        <div className="page-header error-page">
          <div
            className="page-header-image"
            style={{
              backgroundImage: `url(${bgImg})`,
            }}
          ></div>
          <Container>
            <Row>
              <Col className="text-center" md="12">
                <h1 className="title">404</h1>
                <p className="lead">Page not found :(</p>
                <h4 className="description text-default">
                  Oooops! Looks like you got lost.
                </h4>
                <div className="pt-4 w-100 d-flex flex-row justify-content-center">
                  <Button variant="contained" onClick={() => history.push('/')}>
                    Head home
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}
