import React, { useContext } from 'react';
import AppContext from '../contexts/AppContext';
import { AccountType } from '../models';
import { Redirect } from 'react-router-dom';
import { CandidateView } from '../containers/candidate/CandidateView';

export const PreviewPage = () => {
  const { user: [usr] } = useContext(AppContext);

  if (usr.accountType === AccountType.CANDIDATE) {
    return (
        <CandidateView />
    )
  } else if (usr.accountType === AccountType.EMPLOYER) {
    return (
        <CandidateView />
    )
  } else {
    return (
        <Redirect to="/" />
    )
  }
}
