import React, { useContext, useState } from 'react';
import {
  Col,
  Row,
} from 'reactstrap';

import AppContext from '../../contexts/AppContext';
import {
  FormControl,
  Input,
  InputAdornment,
  MenuItem,
  Select,
} from '@mui/material';
import { OnboardingHeader } from '../onboarding/OnboardingHeader';
import { OnboardingFooter } from '../onboarding/OnboardingFooter';
import { companySizes } from '../../constants';

export const EmployerDetails = ({ next }) => {
  const {
    user: [usr, setUsr],
    onBoarding: [obv, setObv]
  } = useContext(AppContext);
  const [phone, setPhone] = useState(usr.phone);
  const [email] = useState(usr.email);
  const [name, setName] = useState(usr.name);
  const [companyName, setCompanyName] = useState(obv.companyName);
  const [companySize, setCompanySize] = useState(obv.companySize);

  const setUsersName = (value) => {
    setName(value);
    usr.name = value;
    setUsr(usr);
  }
  const setUserPhone = (value) => {
    setPhone(value);
    usr.phone = value;
    setUsr(usr);
  }
  const setCompany = (value) => {
    setCompanyName(value)
    obv.companyName = value;
    setObv(obv);
  }
  const companySizeSelected = ({ target: { value }}) => {
    setCompanySize(value);
    obv.companySize = value;
    setObv(obv);
  }

  return (
      <>
        <div className="px-4">
          <OnboardingHeader
              header="Personal Details"
              subHeader="We need to know how to get in contact with you!" />
          <div className="mt-5 py-5 border-top">
            <Row>
              <Col xs={12} md={6}>
                <b>
                  Preferred Name
                </b>
                <FormControl variant="standard" className="w-100">
                  <Input
                      id="input-name"
                      value={name}
                      placeholder="Name"
                      onChange={(e) => setUsersName(e.target.value)}
                  />
                </FormControl>
              </Col>
            </Row>
            <Row className="pt-2">
              <Col xs={12} md={6}>
                <b>
                  Contact number
                </b>
                <FormControl variant="standard" className="w-100">
                  <Input
                      id="input-phonenr"
                      value={phone}
                      type="tel"
                      placeholder="Contact number"
                      onChange={(e) => setUserPhone(e.target.value)}
                      startAdornment={
                        <InputAdornment position="start">
                          📱
                        </InputAdornment>
                      }
                  />
                </FormControl>
              </Col>
            </Row>
            <Row className="pt-2">
              <Col xs={12} md={6}>
                <b>
                  Email address
                </b>
                <FormControl variant="standard" className="w-100">
                  <Input
                      id="input-email"
                      disabled
                      value={email}
                      placeholder="Email Address"
                      type="email"
                      startAdornment={
                        <InputAdornment position="start">
                          ✉
                        </InputAdornment>
                      }
                  />
                </FormControl>
              </Col>
            </Row>
            <Row className="pt-2">
              <Col xs={12} md={6}>
                <b>
                  Company name
                </b>
                <FormControl variant="standard" className="w-100">
                  <Input
                      id="input-companyname"
                      value={companyName}
                      type="tel"
                      placeholder="Company name"
                      onChange={(e) => setCompany(e.target.value)}
                  />
                </FormControl>
              </Col>
            </Row>
            <Row className="pt-2">
              <Col xs={12} md={6}>
                <b>
                  Number of employees
                </b>
                <FormControl className="w-100">
                  <Select
                      variant="standard"
                      value={companySize || null}
                      onChange={(e) => companySizeSelected(e)}
                  >
                    {companySizes.map(({text, value}, idx) => {
                      return (
                          <MenuItem key={idx} value={value}>
                            {text}
                          </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Col>
            </Row>
          </div>
          <OnboardingFooter
              next={() => {
                next()
              }}
          />
        </div>
      </>
  );
}
