import React from 'react';
import { Col, Row } from 'reactstrap';

export const ErrorLoadingLanding = () => {
  return (
      <Row>
        <Col className="mx-auto text-center my-5" md="8">
          <h1>
            <i className="fas fa-exclamation-circle text-red"></i>
          </h1>
          <h4>
            Oh no, something went wrong
          </h4>
          <span className="text-default">
            Please try again or contact support
          </span>
        </Col>
      </Row>
  )
}
