import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom';
import { Card } from 'reactstrap';
import clsx from 'clsx';

import bgImg from '../assets/img/ill/bg_contactus3.svg';
import AppContext from '../contexts/AppContext';
import { AccountType } from '../models';
import { FindWorkPage } from './FindWorkPage';
import { Container } from '@mui/material';
import { ChooseRoleLanding } from '../containers/landing/ChooseRoleLanding';
import { ErrorLoadingLanding } from '../containers/landing/ErrorLoading';
import { LoadingProfileLanding } from '../containers/landing/LoadingProfileLanding';

// @TODO Pull base styles up to scss level coz of reuse
const styles = makeStyles({
  container: {
    minHeight: '85vh',
    minWidth: '95vw',
    position: 'relative',
    '&::after': {
      content: '""',
      maxHeight: '100vh',
      maxWidth: '100vw',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      position: 'absolute',
      backgroundImage: `url(${bgImg})`,
      backgroundSize: 'cover',
      backgroundPosition: 'top center',
      opacity: 0.4,
      zIndex: -1,
    },
  },
  main: {
    display: 'grid',
  },
});

export const LandingPage = () => {
  const {
    auth: [authenticated],
    user: [usr],
  } = useContext(AppContext);
  const history = useHistory();
  const classes = styles();
  const [loading, setLoading] = useState(true);
  const [errLoading, setErrLoading] = useState(false);

  useEffect(() => {
    const checkUser = async () => {
      if (errLoading) {
        setErrLoading(false);
      }
      if (!loading) {
        if (!authenticated || !usr || !usr.accountType) {
          // Don't redirect if user hasn't selected an account type yet
          return;
        }
        if (usr &&
            ((usr.accountType === AccountType.CANDIDATE && !usr.candidate)
            || (usr.accountType === AccountType.EMPLOYER && !usr.employer))) {
          // havent finished onboarding
          return;
        }
        if (authenticated.groups && authenticated.groups.find((grp) => grp === 'administrators')) {
          return history.push('/admin');
        } else if (usr && !usr.reviewed) {
          return history.push('/pending');
        } else if (usr.accountType === 'CANDIDATE') {
          return history.push('/you');
        } else if (usr.accountType === 'EMPLOYER') {
          return history.push('/employer');
        }
      }
    }
    checkUser()
      .catch((err) => {
        console.error(err);
        setErrLoading(true);
      })
      .finally(() => {
        if (loading) {
          setLoading(false);
        }
    });
  }, [authenticated, loading, history, usr, errLoading]);

  if (!authenticated) {
    return (<FindWorkPage />)
  }

  return (
    <div className={classes.container}>
      <div className={classes.main}>
        <Container>
          <Card className={clsx('card-profile shadow mt-lg-3')}>
            <div className="d-flex flex-column px-4 h-100">
              <div className="text-center mt-5">
                {loading ?
                    <LoadingProfileLanding /> :
                    (errLoading) ?
                      <ErrorLoadingLanding /> :
                      <ChooseRoleLanding />
                }
              </div>
            </div>
          </Card>
        </Container>
      </div>
    </div>
  )
};
