// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const AccountType = {
  "ADMIN": "ADMIN",
  "CANDIDATE": "CANDIDATE",
  "EMPLOYER": "EMPLOYER",
  "RECRUITER": "RECRUITER"
};

const { Notification, ConversationMsg, UserDisableReason, Conversation, Message, User, BlogPost, WorkEligibility, Candidate, Employer, ModelUserConnection } = initSchema(schema);

export {
  Notification,
  ConversationMsg,
  UserDisableReason,
  Conversation,
  Message,
  User,
  BlogPost,
  AccountType,
  WorkEligibility,
  Candidate,
  Employer,
  ModelUserConnection
};