import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper
} from '@mui/material';
import { Book, Login, Search, PersonAddAlt } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';

export const NavOptionsMobile = ({ callback }) => {
  const history = useHistory();
  const goToOption = (path) => {
    history.push(path);
    callback();
  }

  return (
    <Paper>
      <MenuList>
        <MenuItem
            onClick={() => goToOption('/')}
        >
          <ListItemIcon>
            <Search fontSize="small" />
          </ListItemIcon>
          <ListItemText>Find work</ListItemText>
        </MenuItem>
        <MenuItem
            onClick={() => goToOption('/hire')}
        >
          <ListItemIcon>
            <PersonAddAlt fontSize="small" />
          </ListItemIcon>
          <ListItemText>Hire</ListItemText>
        </MenuItem>
        <MenuItem
            onClick={() => goToOption('/blog')}
        >
          <ListItemIcon>
            <Book fontSize="small" />
          </ListItemIcon>
          <ListItemText>Blogs</ListItemText>
        </MenuItem>
        <MenuItem
            onClick={() => goToOption('/login')}
        >
          <ListItemIcon>
            <Login fontSize="small" />
          </ListItemIcon>
          <ListItemText>Login</ListItemText>
        </MenuItem>
      </MenuList>
    </Paper>
  )

}
