import React, { useState } from 'react';

import {
  Tab,
  Tabs
} from '@mui/material';
import { TabPanel } from '../../components/TabPanel';
import { GroupsAdmin } from './users/GroupsAdmin';
import { UserListAdmin } from './users/UserListAdmin';

export const UsersAdmin = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const tabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <>
      <Tabs value={tabIndex} onChange={tabChange} aria-label="admin tabs">
        <Tab label="User management" />
        <Tab label="Cognito Groups" />
      </Tabs>
      <TabPanel tabIndex={tabIndex} index={0}>
        <UserListAdmin />
      </TabPanel>
      <TabPanel tabIndex={tabIndex} index={1}>
        <GroupsAdmin />
      </TabPanel>
    </>
  )
}
