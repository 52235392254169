import React, { useContext } from 'react';
import AppContext from '../contexts/AppContext';
import { AccountType } from '../models';
import { Redirect } from 'react-router-dom';
import { CandidateOnboarding } from '../containers/candidate/CandidateOnboarding';
import { EmployerOnboarding } from './employer/EmployerOnboarding';

export const OnboardingPage = () => {
  const { user: [usr] } = useContext(AppContext);

  if (usr.accountType === AccountType.CANDIDATE) {
    return (
        <CandidateOnboarding />
    )
  } else if (usr.accountType === AccountType.EMPLOYER) {
    return (
        <EmployerOnboarding />
    )
  } else {
    return (
        <Redirect to="/" />
    )
  }
}
