import React, { useContext, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Button as MaterialButton,
  Container,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'reactstrap';


import lights from '../assets/videos/Lights.mp4';
import logo from '../assets/img/logo-white.svg';
import { CommonFooter } from '../components/CommonFooter';
import { FindWorkFeature } from '../containers/index/findwork/FindWorkFeature';
import { PersonAddAlt } from '@mui/icons-material';
import { CommonDetails } from '../components/CommonDetails';
import AppContext from '../contexts/AppContext';

const styles = makeStyles({
  container: {
    minHeight: '85vh',
    minWidth: '95vw',
    position: 'relative',
  },
  main: {
    display: 'grid',
  }
});

export const FindWorkPage = () => {
  const {  navTab: [, setActiveTab] } = useContext(AppContext);
  const classes = styles();

  const history = useHistory();

  useEffect(() => {
    // console.log(Analytics)
    // Analytics.record({ name: 'landingVisit'}).then((e) => console.log(e))
  }, []);

  return (
      <>
        <header className="header-4 skew-separator">
          <div className="header-wrapper">
            <div className={classes.container}>
              <div className="page-header header-video">
                <div className="overlay"></div>
                <video
                    autoPlay="autoPlay"
                    loop="loop"
                    muted="muted"
                    playsInline="playsInline"
                >
                  <source
                      src={lights}
                      type="video/mp4"
                  ></source>
                </video>
                <Container className="no-select mt--5">
                  <Row>
                    <Col xs={12} md={6} className="on-top d-flex flex-column justify-content-center align-items-center">
                      <div className="video-sub-text">
                        Make the best move of your career.
                      </div>
                      <div className="video-smol-text">
                        Join a community that puts you first. Upfront salary information, career advice from professionals and great career outcomes.
                      </div>
                    </Col>
                    <Col xs={12} md={6} className="hidden-tiny on-top d-flex justify-content-center align-items-center">
                      <img aria-label="upward spiral logo" style={{width: '10em', height: '10em'}} src={logo}/>
                    </Col>
                    <Col xs={12}>
                      <div className="w-100 pt-4 d-flex flex-row justify-content-start">
                        <MaterialButton
                            style={{ zIndex: 5, color: 'white' }}
                            onClick={() => {
                              setActiveTab(3);
                              history.push('/login?create')
                            }}
                            variant="contained"
                            startIcon={<PersonAddAlt />}
                        >
                          Create profile
                        </MaterialButton>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </div>
        </header>
        <FindWorkFeature />
        <CommonDetails />
        <CommonFooter />
      </>
  )
}
