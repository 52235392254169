export const languages = [
  'C',
  'C++',
  'GO',
  'HTML',
  'Javascript',
  'PowerShell',
  'Python',
  'Ruby',
  'Rust',
  'Shell',
  'SQL',
  'Ada',
  'ALGOL',
  'ARL',
  'AWK',
  'Assembly',
  'Bash',
  'BASIC',
  'BCPL',
  'C#',
  'CLU',
  'COBOL',
  'Common Lisp',
  'Communicating Sequence Processes',
  'DBASE',
  'Dylan',
  'Eiffel',
  'Forth',
  'Fortran',
  'Haskell',
  'ISWIM',
  'Java',
  'JOSS',
  'Lisp',
  'Logo',
  'Lua',
  'ML',
  'Oberon',
  'Objective C',
  'Objective Pascal',
  'Pascal',
  'Perl',
  'PHP',
  'Prolog',
  'QuickBASIC',
  'Scheme',
  'Self',
  'Simula',
  'Smalltalk',
  'Speedcoding',
  'Visual Basic'
];
