import React from 'react';
import {
  Card,
  Col,
  Row,
} from 'reactstrap';
import { Button, Container } from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import bgImg from '../assets/img/ill/bg_contactus3.svg';

const styles = makeStyles({
  container: {
    minHeight: '85vh',
    minWidth: '95vw',
    position: 'relative',
    '&::after': {
      content: '""',
      maxHeight: '100vh',
      maxWidth: '100vw',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      position: 'absolute',
      backgroundImage: `url(${bgImg})`,
      backgroundSize: 'cover',
      backgroundPosition: 'top center',
      opacity: 0.4,
      zIndex: -1,
    },
  },
  main: {
    display: 'grid',
  },
});

export const PendingReview = () => {
  const classes = styles();

  return (
      <div className={classes.container}>
        <div className={classes.main}>
          <Container>
            <Card className={clsx('card-profile shadow mt-lg-3')}>
              <div className="px-4">
                <div className="mt-5 pb-5">
                  <Row id="done-ski">
                    <Col className="mx-auto text-center mt-5" md="8">
                      <h1>
                        <i className="fas fa-hourglass-half"></i>
                      </h1>
                      <h2 className="title">
                        <span className="text-primary">
                          We're reviewing your profile
                        </span>
                      </h2>
                    </Col>
                    <Col className="mx-auto" lg="8" md="12">
                      <p className="description mb-5 text-center">
                        Please be patient while our team reviews your profile
                        <br/>
                        We will let you know as soon as your
                        account has been activated.
                        <br/>
                        You can speed up this process by clicking below to book
                        a session with an adviser
                        <br/>
                        <Button
                            className="mt-1"
                            href="https://calendly.com/jack-us/adviser-sessions"
                            target="_blank"
                            variant="contained"
                        >
                          book a session
                        </Button>
                      </p>
                    </Col>
                  </Row>
                </div>
              </div>
            </Card>
          </Container>
        </div>
      </div>
  );
}
