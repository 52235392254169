import { Button, Col, Row, UncontrolledTooltip } from 'reactstrap';
import { Container } from '@mui/material';
import React from 'react';

export const CommonDetails = () => {

  return (
      <Container>
        <Row className="row-grid align-items-center mb-5">
          <Col lg="12">
            <h3 className="text-primary font-weight-light mb-2">
              Thank you for visiting
            </h3>
            <h4 className="mb-0 font-weight-light">
              Follow our socials if you would like updates
            </h4>
            <div className="d-flex flex-row">
              <Button
                  className="btn-icon-only rounded-circle mt-2"
                  color="linkedin"
                  aria-label="visit-our-linkedin"
                  id="linkedin"
                  onClick={() => window.open("https://www.linkedin.com/company/upwardspiral-io/", )}
              >
                <a style={{ color: 'white' }}
                   onClick={(e) => e.preventDefault()}
                   href="https://www.linkedin.com/company/upwardspiral-io/">
                  <span className="btn-inner--icon" href="https://www.linkedin.com/company/upwardspiral-io/">
                    <i className="fab fa-linkedin"></i>
                  </span>
                </a>
              </Button>
              <UncontrolledTooltip delay={0} target="linkedin">
                Follow us on LinkedIn
              </UncontrolledTooltip>
            </div>
          </Col>
        </Row>
      </Container>
  )
}
