import React, { useContext } from 'react';
import AppContext from '../contexts/AppContext';
import { AccountType } from '../models';
import { CandidateEdit } from '../containers/candidate/CandidateEdit';
import { EmployerEdit } from '../containers/employer/EmployerEdit';
import { Redirect } from 'react-router-dom';

export const EditPage = () => {
  const { user: [usr] } = useContext(AppContext);

  if (usr.accountType === AccountType.CANDIDATE) {
    return (
        <CandidateEdit />
    )
  } else if (usr.accountType === AccountType.EMPLOYER) {
    return (
        <EmployerEdit />
    )
  } else {
    return (
        <Redirect to="/" />
    )
  }
}
