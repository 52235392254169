import React, { useState } from 'react';
import { Tab, Tabs } from '@mui/material';
import { TabPanel } from '../../components/TabPanel';

export const MessagesAdmin = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const tabChange = (event, newValue) => {
    setTabIndex(newValue);
  };
  return (
    <>
      <Tabs value={tabIndex} onChange={tabChange} aria-label="basic tabs example">
        <Tab label="List"  />
        <Tab label="Create" />
      </Tabs>
      <TabPanel tabIndex={tabIndex} index={0} sx={{ p: 3 }}>
        Messages!
      </TabPanel>
    </>
  )
}
