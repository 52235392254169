import React, { useContext } from 'react';
import placeholder from '../../assets/img/placeholder.jpg';
import { Col, Row } from 'reactstrap';
import RedirectButton from '../../components/buttons/RedirectButton';
import { AccountType } from '../../models';
import { bootstrapCandidate, bootstrapEmployer } from '../../utils/UserUtils';
import AppContext from '../../contexts/AppContext';
import { Analytics } from 'aws-amplify';

export const ChooseRoleLanding = () => {
  const {
    auth: [authenticated],
    user: [usr, setUsr],
    onBoarding: [, setObv]
  } = useContext(AppContext);

  const provisionEmployer = () => {
    setUsr({
      ...usr,
      active: true,
      accountType: AccountType.EMPLOYER,
    })
    setObv(bootstrapEmployer());
    Analytics.record({
      name: 'chooseRole',
      attributes: {
        user: authenticated.username,
        choice: 'employer',
      }
    })
  }

  const provisionCanidate = () => {
    setUsr({
      ...usr,
      accountType: AccountType.CANDIDATE,
    })
    setObv(bootstrapCandidate());
    Analytics.record({
      name: 'chooseRole',
      attributes: {
        user: authenticated.username,
        choice: 'candidate',
      }
    })
  }

  return (
      <>
        <img
            alt="..."
            className="img-fluid rounded-circle shadow-lg"
            src={placeholder}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = placeholder;
            }}
            style={{width: "75px"}}
        />
        <h3 className="mt-3">
          Welcome
        </h3>
        <h4 className="mt-2">
          What would you like to achieve with us?
        </h4>
        <div className="mt-3 pb-5">
          <Row>
            <Col xs={12} md={6} className={'mt-2'}>
              <RedirectButton
                  onClick={provisionCanidate}
                  className={'w-100'}
                  buttonText={'Looking for work'}
                  color="primary"
                  variant="contained"
                  redirectTo={'/onboarding'}/>
            </Col>
            <Col xs={12} md={6} className={'mt-2'}>
              <RedirectButton
                  className={'w-100'}
                  buttonText={'Finding talent'}
                  color="primary"
                  variant="contained"
                  onClick={provisionEmployer}
                  redirectTo={'/onboarding'}/>
            </Col>
          </Row>
        </div>
        <Row>
          <Col className="mx-auto" lg="8" md="12">
            <p className="description mb-5 text-center">
              This selection will choose the kind of account we
              provision for you.
              <br/>
            </p>
          </Col>
        </Row>
      </>
      )
}
