import React, { useEffect, useState } from 'react';

// reactstrap components
import {
  Container,
  Col,
  Card,
  CardHeader,
  CardImg,
  CardTitle,
  CardBody,
  FormGroup,
  InputGroup,
  InputGroupText,
  Alert
} from 'reactstrap';
import resetImg from '../assets/img/ill/bg5-1.svg';
import bgImg from '../assets/img/ill/reset.svg';
import { useFormik } from 'formik';
import { verifySchema } from '../utils/SchemaUtils';
import { Auth } from 'aws-amplify';
import clsx from 'clsx';
import { Box, Button, CircularProgress, TextField } from '@mui/material';
import { getLoginErrorAlert } from '../utils/AlertUtils';
import { useHistory } from 'react-router-dom';

export const VerifyAccountPage = () => {
  const history = useHistory();
  const [emailFocus, setEmailFocus] = useState("");
  const [verifyCodeFocus, setVerifyCodeFocus] = useState("");
  const [verifyingAcc, setVerifyingAcc] = useState(false);
  const [verified, setVerified] = useState(false);
  const [errVerifying, setErrVerifying] = useState(false);

  const verifyAccount = () => formikVerify.handleSubmit();

  const formikVerify = useFormik({
    initialValues: {
      email: '',
      verificationCode: '',
    },
    validationSchema: verifySchema,
    onSubmit: async (values) => {
      const { email, verificationCode } = values;
      setVerifyingAcc(true);
      try {
        await Auth.confirmSignUp(email, verificationCode);
        setVerified(true);
        setErrVerifying(false);
      } catch (err) {
        setErrVerifying(err);
        setVerified(false);
      } finally {
        setVerifyingAcc(false);
      }
    },
  });

  useEffect(() => {
    document.body.classList.add('reset-page');
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove('reset-page');
    };
  }, []);

  return (
    <>
      <div className="wrapper">
        <div className="page-header bg-default">
          <div
            className="page-header-image"
            style={{
              backgroundImage:
                `url("${bgImg}")`,
            }}
          ></div>
          <Container>
            <Col className="mx-auto" lg="5" md="8">
              <Card className="bg-secondary shadow border-0">
                <CardHeader>
                  <CardImg
                      alt="..."
                      src={resetImg}
                  ></CardImg>
                  <CardTitle className="text-center" tag="h4">
                    Verify your account
                  </CardTitle>
                </CardHeader>
                <CardBody className="px-lg-5 py-lg-5">
                  <Box component="form">
                    <FormGroup id={'email-group'} className={"mb-3 " + emailFocus}>
                      <InputGroup className="input-group-alternative">
                        <TextField
                            style={{backgroundColor: 'white'}}
                            variant="standard"
                            placeholder="Email"
                            type="email"
                            name="email"
                            id="verify-email"
                            fullWidth
                            value={formikVerify.values.email}
                            onChange={formikVerify.handleChange}
                            error={formikVerify.touched.email && Boolean(formikVerify.errors.email)}
                            helperText={formikVerify.touched.email && formikVerify.errors.email}
                            onFocus={() => setEmailFocus("focused")}
                            onBlur={() => setEmailFocus("")}
                            InputProps={{
                              startAdornment:
                                <InputGroupText>
                                  <i className={clsx("ni ni-email-83", Boolean(formikVerify.errors.email) && "text-red")}></i>
                                </InputGroupText>,
                            }}
                        ></TextField>
                      </InputGroup>
                    </FormGroup>
                    <FormGroup id={'verification-group'} className={"mb-3 " + verifyCodeFocus}>
                      <InputGroup className="input-group-alternative">
                        <TextField
                            style={{backgroundColor: 'white'}}
                            variant="standard"
                            placeholder="Verification code"
                            type="text"
                            name="verificationCode"
                            id="verify-authcode"
                            fullWidth
                            value={formikVerify.values.verificationCode}
                            onChange={formikVerify.handleChange}
                            error={formikVerify.touched.verificationCode && Boolean(formikVerify.errors.verificationCode)}
                            helperText={formikVerify.touched.verificationCode && formikVerify.errors.verificationCode}
                            onFocus={() => setVerifyCodeFocus("focused")}
                            onBlur={() => setVerifyCodeFocus("")}
                            InputProps={{
                              startAdornment:
                                <InputGroupText>
                                  <i className={clsx("ni ni-key-25", Boolean(formikVerify.errors.verificationCode) && "text-red")}></i>
                                </InputGroupText>,
                            }}
                        ></TextField>
                      </InputGroup>
                    </FormGroup>
                    <div className="text-center">
                      {verified &&
                        <Alert color="info" fade={true}>
                          <span className="alert-inner--icon">
                            <i className="fa fa-exclamation-circle"></i>
                          </span>
                          <span className="alert-inner--text">
                            Your account has been verified
                          </span>
                        </Alert>
                      }
                      {errVerifying && getLoginErrorAlert(errVerifying)}
                      {verifyingAcc ?
                          <CircularProgress className="mt-3" />
                          :
                          <div className="d-flex flex-row justify-content-center mt-3">
                            {verified &&
                              <Button
                                      variant="outlined"
                                      onClick={() => history.push('/login')}>
                                go to login
                              </Button>
                            }
                            <Button
                                variant="contained"
                                className="ml-2"
                                disabled={verifyingAcc}
                                onClick={verifyAccount}
                                color="primary">
                              Submit
                            </Button>
                          </div>
                      }
                    </div>
                  </Box>
                </CardBody>
              </Card>
            </Col>
          </Container>
        </div>
      </div>
    </>
  );
}
