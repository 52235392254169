import React, { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom';
import bgImg from '../../assets/img/ill/bg_contactus3.svg';
import clsx from 'clsx';
import { Analytics, API } from 'aws-amplify';

import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  TextField,
  Pagination,
  Slider,
  Typography,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import {
  availabilityText,
  getCurrency,
  getYearOptionsText,
  getYearsValueFromText,
  candidateTypeOptions,
  languages,
  roleMapping,
  yearOptions
} from '../../constants';
import { AccountType } from '../../models';
import { LoaderInRow } from '../../components/loader/Loaders';
import { listConversationsNoMsg } from '../../api/customQueries';
import AppContext from '../../contexts/AppContext';
import * as queries from '../../graphql/queries';
import formatter from 'currency-formatter';
import { stripBlock } from '../../utils/GQLUtils';
import { isMobile } from 'react-device-detect';
import { availableWorkTypes } from '../../constants/Availability';
import { createConversation } from '../../graphql/mutations';

const styles = makeStyles({
  container: {
    minHeight: '85vh',
    minWidth: '95vw',
    position: 'relative',
    '&::after': {
      content: '""',
      maxHeight: '100vh',
      maxWidth: '100vw',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      position: 'absolute',
      backgroundImage: `url(${bgImg})`,
      backgroundSize: 'cover',
      backgroundPosition: 'top center',
      opacity: 0.4,
      zIndex: -1,
    },
  },
  main: {
    display: 'grid',
  },
});

const initialSearch = {
  candidateTypes: [],
  categories: [],
  languages: [],
  experience: [],
  workTypes: [],
  remote: true,
  notRemote: true,
  salaryRange: {
    min: 0,
    max: 200000,
    showOverMax: true,
  }
}

export const EmployerPage = () => {
  const classes = styles();
  const history = useHistory();
  const {
    user: [usrVal],
    msgContext: [, setMsgContext],
    previewUsr: [, setPreviewUsr],
  } = useContext(AppContext);
  const [goingToMessage, setGoingToMessage] = useState(false);
  const [nextToken, setNextToken] = useState(null);
  const [loadingCandidates, setLoadingCandidates] = useState(false);
  const [candidateList, setCandidateList] = useState([]);
  const [filteredCandidateList, setFilteredCandidateList] = useState([]);
  const [searchCriteria, setSearchCriteria] = useState(initialSearch);
  const [, setErrFetching] = useState();
  const [itemsPerPage] = useState(10);
  const [currPage, setCurrPage] = useState(1);
  const [nrOfPages, setNrOfPages] = useState(5);
  const [salRange, setSalRange] = useState(
      [searchCriteria.salaryRange.min, searchCriteria.salaryRange.max]);

  const getCurrencyString = (symbol, value) => formatter.format(value,
      {code: symbol});

  useEffect(() => {
    fetchNext()
    .then((items) => {
      setCandidateList(items);
    })
    .catch((err) => {
      console.log(err);
      setErrFetching(err);
    }).finally(() => {
      setLoadingCandidates(false);
    });
  }, []);

  const resetFilters = () => {
    setSearchCriteria(initialSearch);
    setSalRange([initialSearch.salaryRange.min, initialSearch.salaryRange.max]);
    setFilteredCandidateList(getFilteredCandidates(initialSearch, 1));
    setCurrPage(1);
  }

  const handlePageChange = (event, value) => {
    const filteredList = getFilteredCandidates(null, value);
    setCurrPage(value);
    setFilteredCandidateList(filteredList);
  };

  const fetchNext = async () => {
    if (!loadingCandidates) {
      setLoadingCandidates(true);
    }
    const filteredQuery = stripBlock(queries.byAccountType, 'employer')
      .replace('email', '')
      .replace('phone', '');
    const next = await API.graphql({
      query: filteredQuery,
      variables: {
        accountType: AccountType.CANDIDATE,
        limit: 9999,
        filter: {
          and: [
            { reviewed: {'eq': true} },
            { active: {'eq': true} },
          ]
        },
        nextToken,
      }
    });
    const { data: { byAccountType }} = next;
    const token = byAccountType.nextToken;
    setFilteredCandidateList(byAccountType?.items?.slice(0, itemsPerPage));
    setNrOfPages(
        Math.ceil(byAccountType.scannedCount / itemsPerPage))
    setNextToken(token);
    setLoadingCandidates(false);
    return byAccountType?.items;
  }
  const toggleRemote = () => {
    const newCriteria = {
      ...searchCriteria,
      remote: !searchCriteria.remote,
    };
    setSearchCriteria(newCriteria);
    setFilteredCandidateList(getFilteredCandidates(newCriteria, currPage));
  }
  const toggleNotRemote = () => {
    const newCriteria = {
      ...searchCriteria,
      notRemote: !searchCriteria.notRemote,
    };
    setSearchCriteria(newCriteria);
    setFilteredCandidateList(getFilteredCandidates(newCriteria, currPage));
  }

  const saveCandidateType = (val) => {
    const newCriteria = {
      ...searchCriteria,
      candidateTypes: val,
    };
    setSearchCriteria(newCriteria);
    setFilteredCandidateList(getFilteredCandidates(newCriteria, currPage));
  }
  const saveRole = (val) => {
    const newCriteria = {
      ...searchCriteria,
      categories: val,
    };
    setSearchCriteria(newCriteria);
    setFilteredCandidateList(getFilteredCandidates(newCriteria, currPage));
  }
  const languageChoice = (val) => {
    const newCriteria = {
      ...searchCriteria,
      languages: val,
    };
    setSearchCriteria(newCriteria);
    setFilteredCandidateList(getFilteredCandidates(newCriteria, currPage));
  }
  const workTypeChoice = (val) => {
    const newCriteria = {
      ...searchCriteria,
      workTypes: val,
    };
    setSearchCriteria(newCriteria);
    setFilteredCandidateList(getFilteredCandidates(newCriteria, currPage));
  }
  const salaryRangeChange = (e, val) => {
    const [min, max] = val;
    setSalRange([min, max]);
    const showOverMax = max >= initialSearch.salaryRange.max;
    const newCriteria = {
      ...searchCriteria,
      salaryRange: {
        min,
        max,
        showOverMax,
      },
    };
    setSearchCriteria(newCriteria);
    setFilteredCandidateList(getFilteredCandidates(newCriteria, currPage));
  }
  const yearRangeChange = (val) => {
    const newCriteria = {
      ...searchCriteria,
      experience: val,
    };
    setSearchCriteria(newCriteria);
    setFilteredCandidateList(getFilteredCandidates(newCriteria, currPage));
  }

  const getPaginatedCandidates = (candidates, startIdx) => {
    return candidates.slice(startIdx, startIdx + itemsPerPage)
  }

  const getWorkCategories = () => {
    if (searchCriteria.candidateTypes.length > 0) {
      return candidateTypeOptions
        .filter(({ available }) => available)
        .filter(({ label } ) => searchCriteria.candidateTypes.indexOf(label) >= 0)
        .map(({ categories }) => categories).flat();
    } else {
      return candidateTypeOptions
        .filter(({ available }) => available)
        .map(({ categories }) => categories).flat();
    }
  }

  const getFilteredCandidates = (criteria, currPage) => {
    const {
      candidateTypes,
      categories,
      languages,
      salaryRange,
      experience,
      workTypes,
      remote,
      notRemote
    }
        = criteria || searchCriteria;
    const filtered =
        candidateList
        .filter((user) => {
          if (candidateTypes.length === 0) {
            return true;
          }
          return candidateTypes.some((type) => {
            const match = candidateTypeOptions.find(({ label }) => label === type);
            return user.candidate?.candidateTypes?.indexOf(match.value) >= 0;
          })
        })
        .filter((user) => {
          if (categories.length === 0) {
            return true;
          }
          return categories.some((category) =>
              user.candidate?.workCategories.indexOf(category) >= 0)
        }).filter((user) => {
          if (!remote && !notRemote) {
            return false;
          }
          if (remote && notRemote) {
            return true;
          }
          if (remote && !notRemote) {
            return remote === user.candidate?.openToRemote;
          }
          return notRemote !== user.candidate?.openToRemote;
        }).filter((user) => {
          if (workTypes.length === 0) {
            return true;
          }
          return workTypes.some(
              (t) => user.candidate?.workType.indexOf(t) >= 0);
        }).filter((user) => {
          if (languages.length === 0) {
            return true;
          }
          return languages.some((lang) =>
              user.candidate?.programmingLanguages.indexOf(lang) >= 0)
        }).filter((user) => {
          const overMin = user.candidate?.desiredSalary >= salaryRange.min;
          const underMax = salaryRange.showOverMax
              || user.candidate?.desiredSalary <= salaryRange.max;
          return overMin && underMax;
        }).filter((user) => {
          if (experience.length === 0) {
            return true;
          }
          const exp = experience?.map(val => getYearsValueFromText(val));
          return exp?.some(
              (range) => range.value === user.candidate?.yearsExperience);
          // return true;
        });
    setNrOfPages(Math.round(Math.ceil(filtered.length / itemsPerPage)));
    const startIndex = (currPage - 1) * itemsPerPage;
    const paginated = getPaginatedCandidates(filtered, startIndex);
    return paginated;
  }
  const viewCandidate = async (user) => {
    setPreviewUsr(user);
    Analytics.record({
      name: 'previewUser',
      attributes: {
        viewer: usrVal.owner,
        beingViewed: user.owner,
      }
    });
    history.push('/preview')
  }
  const messageUser = async (user) => {
    setMsgContext(user.id);
    if (!goingToMessage) {
      setGoingToMessage(true);
    }
    const {data: {listConversations: {items}}} = await API.graphql({
      query: listConversationsNoMsg,
      variables: {
        filter: {
          or: [
            {
              and: [
                {user1: {'eq': usrVal.id}},
                {user2: {'eq': user.id}},
              ]
            },
            {
              and: [
                {user1: {'eq': user.id}},
                {user2: {'eq': usrVal.id}},
              ]
            },
          ]
        }
      }
    });
    if (!items.length) {
      await API.graphql({
        query: createConversation,
        variables: {
          condition: null,
          input: {
            user1: usrVal.id,
            user2: user.id,
            participants: [user.owner, usrVal.owner],
          }
        }
      });
    }
    Analytics.record({
      name: 'messageUser',
      attributes: {
        sender: usrVal.owner,
        recipient: user.owner,
      }
    });
    history.push(`/chat`);
  }

  return (
      <div className={classes.container}>
        <div className={classes.main}>
          <Container maxWidth={'lg'}>
            <Card className={clsx('p-4 shadow mt-lg-3 p-2')}>
              <div className="px-2">
                <Row>
                  <Col xs={12} className="d-flex flex-row">
                    <h2 className="pt-xs-2 text-default">
                      Search
                    </h2>
                    <Button className="ml-auto" variant="outlined"
                            onClick={resetFilters}>
                      Reset filters
                    </Button>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={6} xs={12} className="mt-md-2 mt-sm-2">
                    <Autocomplete
                        multiple={true}
                        filterSelectedOptions={true}
                        value={searchCriteria.candidateTypes || []}
                        onChange={(e, value) => saveCandidateType(value)}
                        renderTags={(value, getTagProps) =>
                            value
                            .map((option, index) => (
                                <Chip color="primary" label={option}
                                      {...getTagProps({index})} />
                            ))
                        }
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                variant="standard"
                                placeholder="Categories"
                            />}
                        options={candidateTypeOptions
                          .filter(({ available }) => available)
                          .map(({ label }) => label)
                        } />
                  </Col>
                  <Col md={6} xs={12} className="mt-md-2 mt-sm-2">
                    <Autocomplete
                        multiple={true}
                        filterSelectedOptions={true}
                        value={searchCriteria.categories || []}
                        onChange={(e, value) => saveRole(value)}
                        renderTags={(value, getTagProps) =>
                            value
                            .map((option, index) => (
                                <Chip color="primary" label={option}
                                      {...getTagProps({index})} />
                            ))
                        }
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                variant="standard"
                                placeholder="Work roles"
                            />}
                        groupBy={(option) => {
                          return roleMapping[option];
                        }}
                        options={getWorkCategories()}/>
                  </Col>
                  <Col md={6} xs={12} className="mt-md-2 mt-sm-2">
                    <Autocomplete
                        multiple={true}
                        filterSelectedOptions={true}
                        value={searchCriteria.languages || []}
                        onChange={(e, value) => languageChoice(value)}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip label={option} {...getTagProps(
                                    {index})} />
                            ))
                        }
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                variant="standard"
                                placeholder="Languages"
                            />}
                        options={languages}/>
                  </Col>
                  <Col md={6} xs={12} className="mt-md-2 mt-sm-2">
                    <Autocomplete
                        multiple={true}
                        filterSelectedOptions={true}
                        value={searchCriteria.experience || []}
                        onChange={(e, value) => yearRangeChange(value)}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip label={option} {...getTagProps(
                                    {index})} />
                            ))
                        }
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                variant="standard"
                                placeholder="Experience"
                            />}
                        options={yearOptions.map((option) => option.text)}/>

                  </Col>
                  <Col md={6} xs={12} className="mt-md-2 mt-sm-2">
                    <Autocomplete
                        multiple={true}
                        filterSelectedOptions={true}
                        value={searchCriteria.workTypes || []}
                        onChange={(e, value) => workTypeChoice(value)}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip label={option}
                                      color="info" {...getTagProps({index})} />
                            ))
                        }
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                variant="standard"
                                placeholder="Work Types"
                            />}
                        options={availableWorkTypes.map(
                            (option) => option.type)}/>
                  </Col>
                  <Col md={6} xs={12} className="mt-md-2 mt-sm-2">
                    <div>
                      <FormControlLabel
                          control={
                            <Checkbox
                                value={searchCriteria.remote}
                                checked={searchCriteria.remote}
                                onChange={toggleRemote}/>
                          }
                          label={'Open to remote roles'}
                      />
                      <FormControlLabel
                          control={
                            <Checkbox
                                value={searchCriteria.notRemote}
                                checked={searchCriteria.notRemote}
                                onChange={toggleNotRemote}/>
                          }
                          label={'Not open to remote roles'}/>
                    </div>
                  </Col>
                  <Col md={6} xs={12}>
                    <Typography variant="caption">
                      Salary range (${searchCriteria.salaryRange.min} -
                      ${searchCriteria.salaryRange.max}{searchCriteria.salaryRange.showOverMax
                    && `+`})
                    </Typography>
                    <Slider
                        getAriaLabel={() => 'Salary range'}
                        value={salRange}
                        valueLabelDisplay="auto"
                        valueLabelFormat={(v) => `$${v}`}
                        onChange={salaryRangeChange}
                        getAriaValueText={(val) => `Slider at ${val}`}
                        min={initialSearch.salaryRange.min}
                        max={initialSearch.salaryRange.max}
                        step={10000}
                    />
                  </Col>
                </Row>
                {loadingCandidates && <LoaderInRow message="Loading"/>}
                {!loadingCandidates &&
                <>
                  <Row className="mt-2">
                    <Col md={12} className="mt-2">
                      <div className="w-100 d-flex flex-row">
                        <Pagination
                            className="ml-auto"
                            page={currPage}
                            showFirstButton showLastButton
                            count={nrOfPages || 0}
                            onChange={handlePageChange}/>
                      </div>
                      {filteredCandidateList.length === 0 &&
                      <>
                        <h4>It's very quiet in here...</h4>
                        <h6>Let someone know you can't find a match</h6>
                      </>
                      }
                      {filteredCandidateList.map((user, i) => {
                        return (
                            <Card className="mt-2 w-100" key={`can-${user.id}`}
                                  sx={{
                                    transform: 'translateZ(0px)',
                                    flexGrow: 1
                                  }}>
                              <CardContent>
                                <Row>
                                  <Col md={6} xs={12} className="d-flex flex-row">
                                    <div>
                                      <div className="d-flex flex-row align-items-center">
                                        {/*<Tooltip title="Names are obfuscated on this page" placement="top">*/}
                                        {/*  <IconButton>*/}
                                        {/*    <Info />*/}
                                        {/*  </IconButton>*/}
                                        {/*</Tooltip>*/}
                                        {/*<b>{uniqueNamesGenerator(nameConfig)}</b>*/}
                                        <b>{user.name}</b>
                                      </div>
                                      {getYearOptionsText(
                                          user.candidate?.yearsExperience)} experience
                                      <div>
                                        {availabilityText(
                                            user.candidate?.availability)}
                                      </div>
                                      <div>
                                        Wants <b>{getCurrencyString(getCurrency(
                                              user.candidate?.desiredCurrency)?.abr,
                                          user.candidate?.desiredSalary)}</b> ({getCurrency(
                                          user.candidate?.desiredCurrency)?.abr})
                                      </div>
                                    </div>
                                  </Col>
                                  <Col md={6} xs={12} className={clsx('d-flex flex-row', isMobile ? 'mb-2' : 'flex-row-reverse')}>
                                    <Button
                                        onClick={() => messageUser(user)}
                                        variant="contained"
                                        color="primary"
                                        disabled={goingToMessage}
                                        className="mr-3 mt-2"
                                    >
                                      {goingToMessage ? 'Loading' : 'Message'}
                                    </Button>
                                    <Button
                                        onClick={() => viewCandidate(user)}
                                        variant="outlined"
                                        color="primary"
                                        disabled={goingToMessage}
                                        className="mr-3 mt-2"
                                    >
                                      View Candidate
                                    </Button>
                                  </Col>
                                </Row>
                                <Row className="pt-2">
                                  <Col>
                                    <div>
                                      {user.candidate?.openToRemote ?
                                          <Chip className="mr-1"
                                                color="success"
                                                label={`Open to remote roles`}/>
                                          :
                                          <Chip className="mr-1"
                                                style={{backgroundColor: '#d32f2f', color: 'white' }}
                                                label={`No remote roles`}/>
                                      }
                                    </div>
                                  </Col>
                                  {/*{user.description &&*/}
                                  {/*  <Col xs={12} className="ml-2 mb-3 mt-2">*/}
                                  {/*    <div>*/}
                                  {/*      <MDEditor.Markdown*/}
                                  {/*          source={purify(user.description)}/>*/}
                                  {/*    </div>*/}
                                  {/*  </Col>*/}
                                  {/*}*/}
                                  <Col xs={12} className="mt-2">
                                    {user.candidate?.workType?.map(
                                        (category, i) => (
                                            <Chip key={`${category}-${i}`}
                                                  className="mr-1 mb-1"
                                                  color="info"
                                                  label={category}/>
                                        ))
                                    }
                                  </Col>
                                  <Col xs={12} className="pt-1">
                                    {user.candidate?.workCategories?.sort().map(
                                        (category, i) => (
                                            <Chip key={`${category}-${i}`}
                                                  className="mr-1 mb-1"
                                                  color="primary"
                                                  label={category}/>
                                        ))
                                    }
                                  </Col>
                                  <Col xs={12} className="pt-1">
                                    {user.candidate?.programmingLanguages?.sort().map(
                                        (lang, i) => (
                                            <Chip key={`${lang}-${i}`}
                                                  className="mr-1 mb-1"
                                                  label={lang}/>
                                        ))
                                    }
                                  </Col>
                                </Row>
                              </CardContent>
                              {/*<SpeedDial*/}
                              {/*    ariaLabel="SpeedDial basic example"*/}
                              {/*    sx={{*/}
                              {/*      position: 'absolute',*/}
                              {/*      bottom: 15,*/}
                              {/*      right: 15*/}
                              {/*    }}*/}
                              {/*    icon={<SpeedDialIcon/>}*/}
                              {/*>*/}
                              {/*  {actions.map((action) => (*/}
                              {/*      <SpeedDialAction*/}
                              {/*          key={action.name}*/}
                              {/*          icon={action.icon}*/}
                              {/*          tooltipTitle={action.name}*/}
                              {/*      />*/}
                              {/*  ))}*/}
                              {/*</SpeedDial>*/}
                            </Card>
                        )
                      })}
                      <div className="w-100 d-flex flex-row pt-4">
                        <Pagination
                            className="ml-auto"
                            page={currPage}
                            showFirstButton showLastButton
                            count={nrOfPages}
                            onChange={handlePageChange}/>
                      </div>
                    </Col>
                  </Row>
                </>}
              </div>
            </Card>
          </Container>
        </div>
      </div>
  )
}
