import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import Stepper from 'react-stepper-horizontal';
import { Card } from 'reactstrap';

import bgImg from '../../assets/img/ill/bg_contactus3.svg';
import clsx from 'clsx';
import { Container } from '@mui/material';
import { EmployerDetails } from '../../containers/employer/EmployerDetails';
import { EmployerSummaryView } from '../../containers/employer/EmployerSummaryView';

const styles = makeStyles({
  container: {
    minHeight: '85vh',
    minWidth: '95vw',
    position: 'relative',
    '&::after': {
      content: '""',
      maxHeight: '100vh',
      maxWidth: '100vw',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      position: 'absolute',
      backgroundImage: `url(${bgImg})`,
      backgroundSize: 'cover',
      backgroundPosition: 'top center',
      opacity: 0.4,
      zIndex: -1,
    },
  },
  main: {
    display: 'grid',
  },
});
const steps = [
  {title: 'Personal details'},
  {title: 'Done'},
];

export const EmployerOnboarding = () => {
  const classes = styles();
  const [activeStep, setActiveStep] = useState(0);

  const nextStep = () => {
    const next = activeStep + 1;
    if (next < steps.length) {
      setActiveStep(next);
    }
  }
  const stepComps = [
    <EmployerDetails next={nextStep}/>,
    <EmployerSummaryView />
  ];

  return (
      <>
        <div className={classes.container}>
          <div className={classes.main}>
            <Container>
              <Card className={clsx('card-profile shadow mt-lg-3')}>
                <Stepper
                  steps={steps}
                  activeColor={'#5e72e4'}
                  completeColor={'#172b4d'}
                  circleFontSize={10}
                  activeStep={ activeStep } />
                {stepComps[activeStep]}
              </Card>
            </Container>
          </div>
        </div>
      </>
  )
};
