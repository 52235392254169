import React from 'react';
import { makeStyles } from '@mui/styles';

import bgImg from '../assets/img/ill/404.svg';
import {
  Button,
  Card,
  CardContent,
  Container,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import MDEditor from '@uiw/react-md-editor';
import { codeOfConduct } from '../assets/markdown/markdown';
import clsx from 'clsx';
import { CommonFooter } from '../components/CommonFooter';

const styles = makeStyles({
  container: {
    minHeight: '85vh',
    minWidth: '95vw',
    position: 'relative',
    '&::after': {
      content: '""',
      maxHeight: '100vh',
      maxWidth: '100vw',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      position: 'absolute',
      backgroundImage: `url(${bgImg})`,
      backgroundSize: 'cover',
      backgroundPosition: 'top center',
      opacity: 0.8,
      zIndex: -1,
    },
  },
  main: {
    display: 'grid',
    paddingTop: '10vh'
  },
  cardBg: {
    backgroundColor: '#adb5bd',
  }
});

export const CodeOfConductPage = () => {
  const classes = styles();
  const history = useHistory();

  return (
      <div className={classes.container}>
        <div className={classes.main}>
          <Container>
            <Card className={clsx("p-2", classes.cardBg)}>
            <CardContent>
                <MDEditor.Markdown source={codeOfConduct} />
                <div className="pt-4 w-100 d-flex flex-row justify-content-center">
                  <Button variant="contained" onClick={() => history.push('/')}>
                    Home
                  </Button>
                </div>
              </CardContent>
            </Card>
          </Container>
        </div>
        <CommonFooter />
      </div>
  )
}
